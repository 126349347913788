import React, {Ref, useEffect, useRef, useState} from "react";
import "./_message.sass";
import {useDispatch, useSelector} from "react-redux";

import {RootState} from "../../../redux/store";
import {clearSystemMessage, setDataLoading} from "../../../redux/reducers/system/system_reducers";

const MessageWindow: React.FC = () => {
    const dispatch = useDispatch();
    let {message, data_loading} = useSelector((state: RootState) => state.systemReducer);


    let [show_close, setVisibleButtonClose] = useState(false);
    const ref  = useRef({timeout: false}).current;
    const waitSeconds = 30;

    useEffect(() => {
        let isMounted = true;
        if (!data_loading) {
            setVisibleButtonClose(true);
        }
        else {
            if (waitSeconds > 0 && !ref.timeout) {
                ref.timeout = true;
                setTimeout(() => isMounted ? setVisibleButtonClose(true) : null, waitSeconds * 1000);
            }
        }
        return () => { isMounted = false };
    }, []);


    let closeForm = () =>{
       dispatch(setDataLoading(false));
       dispatch(clearSystemMessage())
    };

    return (
            <div className='background-container'>
                <div className="form-container">
                    <label className="message-text">{data_loading ? 'Пожалуйста подождите, идет загрузка данных' : message.message}</label>
                    {show_close &&
                        <div className="button-container">
                            <div className="button button_inverse active" onClick={closeForm}>Закрыть</div>
                        </div>
                    }
                    {!show_close &&
                        <div className="button-container">
                            <div className="lds-ellipsis">
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </div>
                    }
            </div>
        </div>

    );
};

export default MessageWindow;
