import {createSlice} from "@reduxjs/toolkit";
import {
    CPackInfo,
    CreateOrderState,
    EnumAddressMode,
    EnumOrderMode, EnumShowMapMode, IMapShowMode, CTariffData,

    RouteAddress,
    SendOrderInfo, CContactInformation, EnumPaymentType, CargoInfo, ITariffInfo, TypeLonLat
} from "./CreateOrderType";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {RootState} from "../../store";
import {EnumMarkerType, mapMarker} from "./hereMapTypes";
import {serverAPI} from "../../../api/server";
import {IFullCategory} from "../system/system_types";
import {DaDataAddress, DaDataAddressSuggestion, DaDataSuggestion} from "react-dadata";
import {daDataAPI} from "../../../api/dadata-api";

export const getStreetValue = (dadata: any) =>{
    if (!dadata || !dadata.data) return '';
    let val = dadata.data.country;
    if (dadata.data.city) val +=', ' + dadata.data.city_with_type;
    else if (dadata.data.settlement) val +=', ' + dadata.data.settlement;
    if (dadata.data.street) val +=', ' +dadata.data.street_with_type;
    return val;
}
export const currentOrderData = (state: RootState) => state.createOrderReducer.order_mode === EnumOrderMode.Manual ?  state.createOrderReducer.order_manual :  state.createOrderReducer.order_auto;
export const getShowMapMode = (state: RootState) => state.createOrderReducer.map_mode;
export const getTariffs = (state: RootState) => state.createOrderReducer.tariffs;
export const getOrderMode = (state: RootState) => state.createOrderReducer.order_mode;
export const getPackInfo = (state: RootState) => state.createOrderReducer.pack_info;
export const getTariffLoading = (state: RootState) => state.createOrderReducer.is_tariff_loading;
export const getOrderContacts = (state: RootState) => state.createOrderReducer.order_manual.contacts;
export const getOrderIsChanged = (state: RootState) => state.createOrderReducer.is_changed;
export const createOrderSelector = (state: RootState) => state.createOrderReducer;



export const getRoutePointAsMarkers = ( order: any, markerType = EnumMarkerType.MainDraggable ) => {
    return order.routes.length ? order.routes.map( (x:RouteAddress ) => {
        let m = new mapMarker();
        m.index = x.id;
        m.lat = x.adress_latitude;
        m.lon = x.adress_longitude;
        m.type = markerType;
        m.is_show_text = true;
        m.text = x.adress ;
        m.title = x.id == 0 ?  'Откуда' : 'Куда';
        m.bg_color = x.id == 0 ? 'orange' : '#5364ff';
        return m;
    }) : []
};

export const getCost  = (tariffs: CTariffData[], tariff_id='') => {
    if (!tariffs.length) return 0;
    let t = tariff_id == '' ? tariffs.find( x=> x.cost > 0) : tariffs.find( x=> x.tariff_type_id == tariff_id);
    return t ? t.cost : 0;
};

var dl = 0;
var stack : number[] = [];
var counter = 0;
export function wait_before_update(n:number){
    // if (dl > 0) {
    //     dl = n;
    //     return new Promise(resolve => resolve(false));
    // }
    let my_counter = counter++;
    dl = n;
    stack.push(my_counter);
    return new Promise( resolve => {
        let m = setInterval(()=>{
            // console.log('getTariffInfo  stack is', stack.length, 'my_counter', my_counter, stack);
            if (stack.length > 1 && stack[0] == my_counter) {
                stack.shift();
                clearInterval(m);
                resolve(false);
                return;
            }

            dl-=100;
            if (dl <=0 ) {
                dl = 0;
                clearInterval(m);
                stack = [];
                resolve (true)
            }
        }, 100);
    })
}

export const getTariffInfo = async (order:SendOrderInfo, dispatch: any, categories: IFullCategory[], tariffs: ITariffInfo[]) => {
    dispatch(setTariffLoading(true));
    if (order.routes.length < 2) return;

    let res = await wait_before_update(3000);

    if (res) {

        let data : SendOrderInfo = {...order};
        console.log('getTariffInfo data.car_type_id = ', data.car_type_id);
        if (!data.date)
            data.date='2022-01-24T15:24:00T15:39:00';

        if (!data.car_type_id)
            data.car_type_id = categories.length ? categories[0].id : '';
        console.log('getTariffInfo data.car_type_id = ', data.car_type_id);
        let res = await serverAPI.calc(data);
        dispatch(setTariffLoading(false));
        if (Array.isArray(res))
           dispatch(setTariffsData({data: res, names: tariffs}));
        // setTariffLoading(false)
    }
};

export const getFullTariffArray = (data:CTariffData[], names:{id:string, name:string}[]) =>{
    let tariffs :CTariffData[] = [];
    if (!Array.isArray(data)) data = [];
    for (let i=0; i< data.length; i++){
        let newTariff = new CTariffData();
        let tar =  data[i];
        for (var key in tar) {
            //@ts-ignore
            newTariff[key] = tar[key];
        }

        let t = names.find(z => z.id === tar.tariff_type_id);
        if (t) newTariff.tariff_name = t.name;
        tariffs.push(newTariff)
    }
    return tariffs
}

export const setAddressFromDaData = async ( order_routes: RouteAddress[], point: TypeLonLat, point_index:number, dispatch:any) =>{
    const result : any = await daDataAPI.getAddressByCoords(point) ;
    if ( !result || !result.data  || !Array.isArray(result.data.suggestions) || !result.data.suggestions.length)
        return false;
    console.log('setAddressFromDaData result=', result)
    let daDataAddress :DaDataSuggestion<DaDataAddress>  = result.data.suggestions[0];

    let addr :RouteAddress = new RouteAddress(point_index);
    if (point_index < order_routes.length)
        addr = {...order_routes[point_index]};
    addr.adress = daDataAddress.value ;
    addr.adress_latitude = daDataAddress  && daDataAddress.data && daDataAddress.data.geo_lat ? +daDataAddress.data.geo_lat : 0;
    addr.adress_longitude =  daDataAddress  && daDataAddress.data && daDataAddress.data.geo_lon ? +daDataAddress.data.geo_lon : 0;
    addr.house = daDataAddress  && daDataAddress.data &&  daDataAddress.data.house ? daDataAddress.data.house : '';
    let tmp = [...order_routes];
    if (point_index < order_routes.length)
        tmp[point_index] = addr;
    else
        tmp.push(addr);
    dispatch(setOrderRoutes(tmp));
    return true;
}

let getInitState = () : CreateOrderState=> ({
    order_manual: {...new SendOrderInfo()},
    order_auto: {...new SendOrderInfo()},
    order_mode: EnumOrderMode.None,
    tariffs: [],
    selected_address_index: -1,
    address_mode: EnumAddressMode.None,
    map_mode: {mode: EnumShowMapMode.None, coordinate:{lon:0, lat:0}},
    pack_info: {...new CPackInfo()},
    is_tariff_loading: false,
    is_changed: false,
    is_agreement: false,
    is_phone_checked: false,
    way_points_from_1c: [],
});

const initialState : CreateOrderState = getInitState();

export const createOrderSlicer = createSlice({
    name: 'createOrderSlicer',
    initialState,
    reducers: {
        setOrderMode(state, actions:PayloadAction<EnumOrderMode>) {
            state.order_mode = actions.payload;
            return state
        },
        setOrderData(state, actions:PayloadAction<SendOrderInfo>) {
            if (state.order_mode === EnumOrderMode.Auto)
                state.order_auto = {...actions.payload};
            if (state.order_mode === EnumOrderMode.Manual)
                state.order_manual = {...actions.payload};
            state.is_changed = true;
            return state;
        },
        setOrderDataAuto(state, actions:PayloadAction<SendOrderInfo>) {
            state.order_auto = {...actions.payload};
            state.order_manual = {...actions.payload};
            state.order_manual.cargo = {...new CargoInfo()};
            return state;
        },
        setOrderDataManual(state, actions:PayloadAction<SendOrderInfo>) {
            state.order_auto = {...actions.payload};
            state.order_manual = {...actions.payload};
            return state;
        },
        setOrderRoutes(state, actions:PayloadAction<RouteAddress[]>) {
            state.order_auto.routes = [...actions.payload];
            state.order_manual.routes = [...actions.payload];
            state.is_changed = true;
            return state
        },
        setSelectedAddress(state, actions:PayloadAction<number>) {
            state.selected_address_index = actions.payload;
            state.selected_address_index = actions.payload;
            return state
        },
        setTariffsData(state, actions:PayloadAction<{data:CTariffData[], names:{id:string, name:string}[]} | null>) {
            if (actions.payload && Array.isArray(actions.payload.data)) {
                state.tariffs = getFullTariffArray(actions.payload.data, actions.payload.names );
                let tariff = actions.payload.data[0];
                if (!tariff.points) return;
                tariff.points.filter(point=> point.originalIndex != -1)
                    .forEach((point, index) => {
                        state.order_auto.routes[index].arrivalDate = point.arrivalDate;
                        state.order_auto.routes[index].departureDate = point.departureDate;
                        state.order_manual.routes[index].arrivalDate = point.arrivalDate;
                        state.order_manual.routes[index].departureDate = point.departureDate;
                    });
                state.way_points_from_1c =  tariff.points.filter(point=> point.originalIndex == -1 &&  point.lat).map(p=> [p.lat, p.lon]);
            } else {
                state.tariffs = [];
                state.way_points_from_1c = []
            }

            return state
        },
        setAddressMode(state, actions:PayloadAction<EnumAddressMode>) {
            state.address_mode = actions.payload;
        },
        setShowMapMode(state, actions:PayloadAction<IMapShowMode>) {
            console.log('setShowMapMode', actions.payload);
            state.map_mode = {mode: actions.payload.mode, coordinate : actions.payload.coordinate ? actions.payload.coordinate : {lon:0,lat:0}};

            return  state
        },
        setOrderDate(state, actions:PayloadAction<string>) {
            state.order_auto.date = actions.payload ;
            state.order_manual.date = actions.payload ;
            state.is_changed = true;
            return state
        },
        setOrderAdditional(state, actions:PayloadAction<{id:string, value:boolean|string|number}[]>) {
            state.order_auto.additional_requirements = actions.payload ;
            state.order_manual.additional_requirements = actions.payload ;
            state.is_changed = true;
            return state
        },
        setOrderTariff(state, actions:PayloadAction<CTariffData>) {
            state.order_auto.tariff_type_id = actions.payload.tariff_type_id ;
            state.order_manual.tariff_type_id = actions.payload.tariff_type_id ;
            state.is_changed = true;
            return state
        },
        setPackInfo(state, actions:PayloadAction<CPackInfo>) {
            state.pack_info  = {...actions.payload} ;
            state.is_changed = true;
            return state
        },
        setTariffLoading(state, actions:PayloadAction<boolean>) {
            state.is_tariff_loading  = actions.payload ;
            return state
        },
        setContactInformation(state, actions:PayloadAction<CContactInformation>) {
            state.order_auto.contacts = {...actions.payload};
            state.order_manual.contacts = {...actions.payload};
            state.is_changed = true;
            return state
        },
        setOrderINN(state, actions:PayloadAction<string>) {
            state.order_auto.TIN = actions.payload ;
            state.order_manual.TIN = actions.payload ;
            state.is_changed = true;
            return state
        },
        setOrderPaymentType(state, actions:PayloadAction<EnumPaymentType>) {
            state.order_auto.payment_type = actions.payload ;
            state.order_manual.payment_type = actions.payload ;
            state.is_changed = true;
            return state
        },
        setClearData(state, actions:PayloadAction<boolean>) {
            // console.log('initialState', initialState);
            state = getInitState();
            return state
        },
        setOrderChanged(state, actions:PayloadAction<boolean>) {
            // console.log('initialState', initialState);
            state.is_changed = actions.payload ;
            return state
        },
        setOrderID(state, actions:PayloadAction<string>) {
            state.order_auto.id = actions.payload ;
            state.order_manual.id = actions.payload ;
            return state
        },
        setAgreement(state, actions:PayloadAction<boolean>) {

            state.is_agreement = actions.payload ;
            return state
        },
        setPhoneChecked(state, actions:PayloadAction<boolean>) {

            state.is_phone_checked = actions.payload ;
            return state
        },
        setClearOrderData(state) {
            state.order_auto  = new SendOrderInfo();
            state.order_manual  = new SendOrderInfo() ;
            state.is_changed = false;
            state.order_mode = EnumOrderMode.None;
            state.way_points_from_1c = []
        }

    }
});

export const {
    setOrderMode,
    setOrderData,
    setOrderDataAuto,
    setOrderDataManual,
    setOrderRoutes,
    setSelectedAddress,
    setTariffsData,
    setAddressMode,
    setShowMapMode,
    setOrderDate,
    setOrderAdditional,
    setOrderTariff,
    setPackInfo,
    setTariffLoading,
    setContactInformation,
    setOrderINN,
    setOrderPaymentType,
    setClearData,
    setOrderChanged,
    setOrderID,
    setAgreement,
    setPhoneChecked,
    setClearOrderData,
} = createOrderSlicer.actions;

export default createOrderSlicer.reducer;
