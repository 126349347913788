import React, {FC, useState} from "react";
import "./JournalOrdersForm.css"
import '../../Elements/SelectCarManual/AutoCarSelectCard/AutoCarSelectCard.css'

import {EnumOrderListFilterType, IErorrResult, IOrder, IOrderFull} from "./Order.types";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {groupBy, showErrorMessage} from "../../../redux/reducers/system/system_functions";
import {serverAPI} from "../../../api/server";
import moment from "moment";
import {JournalOrderItem} from "./JournalOrderItem/JournalOrderItem";
import {IGetOrderInfo, OrderInfo} from "./OrderInfo/OrderInfo";
import {systemSelector} from "../../../redux/reducers/system/system_reducers";




interface IProps {
}

export const JournalOrdersForm:FC<IProps> = ({})=>{
    const dispatch = useAppDispatch();
    const [filter, setFilter] = useState(EnumOrderListFilterType.Today);
    const [orderList, setOrderList] = useState<any>([]);
    const { clientId } = useAppSelector(userProfileSelector);
    const { categories, tariff_types, additional } = useAppSelector(systemSelector)

    const countOffset = 10;
    const [count, setCount] = useState(countOffset);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [loadingOrderIndex, setLoadingOrderIndex] = useState<number>(-1);
    const [selectedOrder, setSelectedOrder] = useState<IGetOrderInfo|undefined>(undefined);

    const onClickFilter = (type: EnumOrderListFilterType) => {
        setOrderList([]);
        setFilter(type) ;
        loadOrderList(type);
    };

    const getParams = (type: EnumOrderListFilterType | undefined) => {
        const today = moment(new Date()).format('Y.MM.DD');
        const tomorrow = moment((new Date()).setDate((new Date()).getDate() + 1)).format('Y.MM.DD');


        return {
            client_id: clientId || "",
            start_from: type ===  EnumOrderListFilterType.Today ? today: type === EnumOrderListFilterType.Future ? tomorrow : '',
            start_to: type === EnumOrderListFilterType.Today ? tomorrow: type === EnumOrderListFilterType.Past ? today : '',
            count: count,
        };

    };


    const loadOrderList = async (type: EnumOrderListFilterType | undefined, isScrollDown?: boolean ) => {
        if (!clientId) return showErrorMessage("Для получения списка заказов нужно авторизоваться !", dispatch);
        let countOrder = isScrollDown ? count + countOffset : countOffset;
        if (isScrollDown) setCount(countOrder);
        setLoading(true);

        let res : any = await serverAPI.getOrderList(getParams(type));
        setLoading(false);
        if (!res || res.status !== 200) return showErrorMessage('Ошибка загрузки списка заказов!', dispatch);
        const errorResult = res.data as IErorrResult;
        const orderList = res.data as IOrder[];
        if (errorResult && errorResult.status == 'error') return showErrorMessage(errorResult.error_message, dispatch);

        setOrderList(orderList);
    };

    const getOrdersByType = ()=>{
        if (!orderList || !orderList.length) return [];
        const today =  moment(new Date());
        return orderList
            .filter( (item: IOrder)=> {
                let dt = (item.date_from).split('T');
                // let date_order =  moment(dt[0]+'T'+dt[1]);
                let date_order =  moment(dt[0]);
                let today_order =  moment(today.format('Y-M-D'));
                console.log(today.format('D-M-Y'), date_order.format('D-M-Y'), today_order.diff(date_order, 'day'));
                return  ((filter === EnumOrderListFilterType.Today && today_order.diff(date_order, 'day') === 0) ||
                         (filter === EnumOrderListFilterType.Future && today_order.diff(date_order, 'days') < 0) ||
                         (filter === EnumOrderListFilterType.Past && today_order.diff(date_order, 'days') > 0) );
            })
    };
    const onOrderClick = async (order:IOrder, index: number) => {
        if (loadingOrderIndex>=0) return;
        setLoadingOrderIndex(index);
        let data = await serverAPI.getOrderById(order.id);
        setLoadingOrderIndex(-1);
        if (!data) return showErrorMessage('Ошибка получения данных о заказе', dispatch)
        setSelectedOrder(data)

    }

    if (selectedOrder) return (
        <OrderInfo
            order={selectedOrder}
            tariff={tariff_types}
            onButtonClick={""}
            onChatClick={""}
            onCallClick={""}
            onCloseClick={()=> setSelectedOrder(undefined)}

        />
    );

    const getOrderCards =() => {
        let lastDate = '';
        return getOrdersByType().map((item:IOrder, i:number)=> {
            let order_date = item.date_from.split('T')[0] ;
            if (order_date !=lastDate) {
                lastDate = order_date;
                return <div key={'card-' + item.id + i}>
                        <div className="journal-window-date-separator">{lastDate}</div>
                        <JournalOrderItem  order={item} isLoading={loadingOrderIndex === i}
                                          filterType={filter} onClick={() => onOrderClick(item, i)}/>
                    </div>
            }
            return <JournalOrderItem key={'card-' + item.id + i} order={item} isLoading={loadingOrderIndex === i}
                                     filterType={filter} onClick={() => onOrderClick(item, i)}/>
        })
    }
    return (
        <div className="journal-window">
            <div className="journal-window-title">Журнал заказов</div>
            <div className="journal-window-menu">
                {Object.values(EnumOrderListFilterType).map((item, index) => (
                    <div
                        className={"journal-window-menu-item" + ( filter === item ? ' selected':'')}
                        key={index}
                        onClick={(e) => onClickFilter(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className="journal-window-content">
                {orderList.length ===0 && <div className="journal-order-list__empty">Список заказов пуст</div> }
                {
                    getOrderCards()
                    // getOrdersByType().map((item:IOrder, i:number)=> {
                    //     return <JournalOrderItem key={'card-' + item.id + i} order={item} isLoading={loadingOrderIndex === i}
                    //                           filterType={filter} onClick={() => onOrderClick(item, i)}/>
                    // })
                }
            </div>
        </div>
    )
}
