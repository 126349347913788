import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./SelectCarAuto.css";
import InputCargo from "./InputCargo/InputCargo";
import ListCargo from "./ListCargo/ListCargo";

import {RootState} from "../../../redux/store";
import {IBOCharacteristics, IBodyOptions, MessageType} from "../../../redux/reducers/system/system_types";
import {CargoInfo, CargoSize, CargoTypes, IPackInfo, SelectedCarData} from "../../../redux/reducers/slices/CreateOrderType";
import {AutoDropDownGreyList} from "../SelectCarManual/AutoDropDownGreyList/AutoDropDownGreyList";
import {getCargoObject, getPackInfo} from "../../../redux/reducers/slices/CreateOrderFunctions";
import {setSystemMessage} from "../../../redux/reducers/system/system_reducers";
import PackCargo from "./PackCargo/PackCargo";
import {useAppSelector} from "../../../redux/hooks";
import {currentOrderData} from "../../../redux/reducers/slices/CreateOrderSlicer";

interface IProps{
  data:SelectedCarData;
  cargoes: CargoTypes[];
  onChange: (data:SelectedCarData, cargo: CargoTypes[]) => void;
}

enum EnumTypeCargo{ SIZE, PALLET, PACKAGE}

const SelectCarAuto: React.FC<IProps> = ({data,cargoes, onChange}) => {
    const dispatch = useDispatch();

    let [selectedCargoType, setSelectedCargoType] = useState<EnumTypeCargo>(EnumTypeCargo.SIZE);
    let [initCargo, setInitCargo] = useState<CargoTypes | null>({size: new CargoSize()});
    let [cargoList, setCargoList] = useState<CargoTypes[]>(cargoes);
    let [packInfo, setPackInfo] = useState<IPackInfo | null>(null);
    let {categories, pallet_types, package_types} = useSelector((state: RootState) => state.systemReducer);

    let [selectedBodyID, setSelectedBodyID] = useState<string>(data.body_option_id);

    let [activeCharacteristics, setActiveCharacteristics] = useState<IBOCharacteristics[]>(data.active_characteristic);
    let [isMaxDimensions, setMaxDimensions] = useState<boolean>(data.max_dimensions);

    let order = useAppSelector(currentOrderData)

    const onBodySelect = (id: string) => {
        setSelectedBodyID(id === selectedBodyID ? '' : id);
    };


    const onSelectType = (state: EnumTypeCargo) => {
        setSelectedCargoType(state);
        if (state === EnumTypeCargo.SIZE) setInitCargo({size: new CargoSize()});
        if (state === EnumTypeCargo.PALLET) setInitCargo({pallet_type_id: "", quantity: 0, size: new CargoSize()});
        if (state === EnumTypeCargo.PACKAGE) setInitCargo({package_type_id: "", quantity: 0, size: new CargoSize()});
    };

    const appendCargo = (cargo: CargoTypes) => {
        setCargoList([...cargoList, cargo]);
    };

    const changed = () => {
        let data = new SelectedCarData();
        data.active_characteristic = activeCharacteristics;
        data.max_dimensions = isMaxDimensions;
        if (selectedBodyID)
            data.body_option_id = selectedBodyID;
        else {
            let cat = categories.find(x=> x.id ===  packInfo?.car_types_id[0]);
            data.body_option_id = cat ? cat.body_options[0].id : categories[0].body_options[0].id;
        }
        data.category_id = packInfo?.car_types_id.length ? packInfo?.car_types_id[0] : categories[0].id;

        onChange(data, cargoList);
    };

    useEffect(() => {

            let lst : any[] = [];
            if (!order ||  !order.cargo) return;
            if (order.cargo.places.length)  lst = lst.concat( [...order.cargo.places]);
            if (order.cargo.pallets.length)  lst = lst.concat( [...order.cargo.pallets]);
            if (order.cargo.packages.length)  lst = lst.concat( [...order.cargo.packages]);
            console.log('lst', lst);
            setCargoList(lst);

    }, [order.cargo.places, order.cargo.packages, order.cargo.pallets])
    useEffect(() => {

        getPackInfo(cargoList, selectedBodyID, activeCharacteristics, categories, isMaxDimensions).then((response: IPackInfo) => {
            if (response.error || response.status === 'error')
                dispatch(setSystemMessage({msg_type: MessageType.Error, message: response.error_description}));
            else {
                setPackInfo(response);

            }
        }).catch(error => {
            dispatch(setSystemMessage({
                msg_type: MessageType.Error,
                message: 'Ошибка упаковки, проверьте габариты груза!'
            }))
        });
        changed();
    }, [cargoList, selectedBodyID, activeCharacteristics, isMaxDimensions]);


  return (
    <div className="select-car-auto">
      <div className={"select-car-auto__types"}>
        <AutoDropDownGreyList  type="transparent-green" list={[]}
                               key={"chm_01"} title={"По габаритам"}
                               isSelected={selectedCargoType === EnumTypeCargo.SIZE}
                               onClick={() => onSelectType(EnumTypeCargo.SIZE)}
                               onSelect={() => { }}
                               showTitleWhenSelect={false}/>
        <AutoDropDownGreyList  type="transparent-green" list={pallet_types.map(x=> ({id:x.id, text:x.name}))}
                               key={"chm_02"} title={"На паллетах"}
                               isSelected={selectedCargoType === EnumTypeCargo.PALLET}
                               onClick={() => onSelectType(EnumTypeCargo.PALLET)}
                               onSelect={(item) => {
                                 setInitCargo(item.id ?  {pallet_type_id: item.id, quantity: 0, size: new CargoSize()} : null)}
                               }
                               showTitleWhenSelect={false}/>
        <AutoDropDownGreyList  type="transparent-green" list={package_types.map(x=> ({id:x.id, text:x.name}))}
                               key={"chm_03"} title={"Упаковки"}
                               isSelected={selectedCargoType === EnumTypeCargo.PACKAGE}
                               onClick={() => onSelectType(EnumTypeCargo.PACKAGE)}
                               onSelect={(item) => {
                                 setInitCargo(item.id ? {package_type_id: item.id, quantity: 0, size: new CargoSize()} : null)
                               }}
                               showTitleWhenSelect={false}/>
      </div>

      <div className="select-car-auto__info">
        { selectedCargoType === EnumTypeCargo.SIZE && "Укладка груза в несколько ярусов (рядов вверх) НЕ предусмотрена"}
        { selectedCargoType !== EnumTypeCargo.SIZE && "Предусмотрена укладка груза в несколько ярусов"}
      </div>
      <div className="select-car-auto__input-cargo">
        {initCargo && selectedCargoType === EnumTypeCargo.SIZE && <InputCargo value={initCargo} onAppend={appendCargo }/>}
        {initCargo && selectedCargoType === EnumTypeCargo.PALLET && <InputCargo value={initCargo} onAppend={appendCargo}/>}
        {initCargo && selectedCargoType === EnumTypeCargo.PACKAGE && <InputCargo value={initCargo} onAppend={appendCargo}/>}
      </div>
      <div className="select-car-auto__list-cargo">
        <ListCargo list={cargoList}
                   onDelete={(item, )=> { setCargoList(cargoList.filter(x=> x!==item))}}
                   total={packInfo}
        />
      </div>
      {packInfo && packInfo.car_types_id.length > 0 &&
            <PackCargo pack={packInfo} onBodySelect={onBodySelect} selectedBodyID={selectedBodyID}
                       activeCharacteristics={activeCharacteristics} setActiveCharacteristics={setActiveCharacteristics}
                       isMaxDimensions={isMaxDimensions} setMaxDimensions={setMaxDimensions}
            />
      }
    </div>
  );
};
export default SelectCarAuto;
