import React from "react";
import { ReactSVG } from "react-svg";
import {useSelector} from "react-redux";


import {RootState} from "../../../../../redux/store";
import {CargoTypes, IPackage, IPallet} from "../../../../../redux/reducers/slices/CreateOrderType";
import DeleteSmall from "../../../../App/svg/delete-small.svg";

import "./CargoItem.css";

interface IProps {
    value: CargoTypes;
    onDelete?:() => void
    onEdit?:() => void
}

const CargoItem: React.FC<IProps> = ({value, onDelete, onEdit}) =>{

    let {pallet_types, package_types} = useSelector((state: RootState) => state.systemReducer);

    const getTitle = () => {
       if(value.hasOwnProperty('pallet_type_id')) return "Паллет";
       if(value.hasOwnProperty('package_type_id')) return "Упаковка";
       return "";
    };

    const getTitleValue = () => {
       if(value.hasOwnProperty('pallet_type_id')) {
           let v = pallet_types.find( x=> x.id === (value as IPallet).pallet_type_id);
           if (v) return  v.name;
           return "";
       }
       if(value.hasOwnProperty('package_type_id')) {
           let v = package_types.find( x=> x.id === (value as IPackage).package_type_id);
           if (v) return  v.name;
           return "";
       }
       return "Груз";
    };

    return (
        <div className="cargo-item">
            <div className="cargo-item__quantity">{value.quantity ? value.quantity : ''}</div>
            <div className="cargo-item__size" onClick={() => {if (onEdit) onEdit()}}>
                <div>
                    <div className="cargo-item__size-title left">{getTitle()}</div>
                    <div className="cargo-item__size-title-value">{getTitleValue()}</div>
                </div>
                <div>
                    <div className="cargo-item__size-title">ДШВ</div>
                    <div className="cargo-item__size-title-value">{value.size.length} х {value.size.width}  х {value.size.height} м</div>
                </div>
                <div>
                    <div className="cargo-item__size-title right">Вес</div>
                    <div className="cargo-item__size-title-value">{value.size.weight} кг</div>
                </div>

            </div>
            <div className="cargo-item__delete">
                <ReactSVG src={DeleteSmall} onClick={()=> {if (onDelete) onDelete() }}/>
            </div>
        </div>
    )
};

export default CargoItem;
