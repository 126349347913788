import { createSlice } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../store'
import {CCompany, CUser, CUserProfile, userProfileState} from "./userProfile.type";
import {apiOrderUrl, cookiesAccountId, cookiesClientId} from "../../../deployment";

const initialState: userProfileState = {
  profile: new CUserProfile(),
  selected_company_id: '',
  selected_user_id: '',
  is_visible: false,
  data_login: undefined,
  phone_entered:'',
  isAuth:false,
  clientId:''
};

export const userProfileSelector = (state: RootState) => state.userProfileReducer;

export const userProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserProfile (state, action) {
      state.profile = {...action.payload}
    },
    setSelectedCompanyId (state, action) {
      state.selected_company_id = action.payload
    },
    clearUserProfile (state) {
      state.profile = new CUserProfile();
      state.selected_company_id = '';
      state.is_visible = false;
      state.data_login = undefined;
      state.isAuth = false;
    },
    setVisibleUserProfileForm(state, action){
      state.is_visible = action.payload
    },
    setSelectedUserId(state, action){
      state.selected_user_id = action.payload
    },
    setDataLogin(state, action){
      state.data_login = action.payload
    },
    setIsAuth(state, action){
      state.isAuth = action.payload
    },
    setClientId(state, action){
      state.clientId = action.payload
    },
    setEnteredPhone(state, action){
      state.phone_entered = action.payload.replaceAll('+','').replaceAll(' ','')
    }
  }
});
export const {
  setUserProfile,
  setSelectedCompanyId,
  clearUserProfile,
  setVisibleUserProfileForm,
  setSelectedUserId,
  setDataLogin,
  setIsAuth,
  setClientId,
  setEnteredPhone
} = userProfileSlice.actions;

export const loadProfileByIdThunk = async ( clientId: string, dispatch: AppDispatch, selected_account_id:string|undefined,  client_phone: string = "" ) => {
  console.log(`loadProfileByIdThunk clientId=${clientId} selectedId=${selected_account_id} client_phone=${client_phone}`);
  if (!clientId) return;
  dispatch(clearUserProfile());
  dispatch(setEnteredPhone(client_phone));

  const response = await fetch( `${apiOrderUrl}/clients?client_id=${clientId}`).then(res => res.json());
  if (!response || response.status == 'error') console.log('ошибка загрузки данных клиента');
  else {
    response.client_id = clientId;
    dispatch(setUserProfile(response));
    if (!selected_account_id && client_phone) {
      let tmp = response.users.find((x:CUser) => x.phone === client_phone);
      if (tmp) selected_account_id = tmp.id;
      else
        tmp = response.companies.find((x:CCompany) => x.phone === client_phone);
      if (tmp)  selected_account_id = tmp.id;
      console.log('selectedId = ', selected_account_id)
    }
    if (!selected_account_id) selected_account_id = response.users.length >0 ? response.users[0].id:  response.companies.length > 0 ?  response.companies[0].id : '';
    console.log('selectedId = ', selected_account_id)
    if (!selected_account_id) return '';

    if (response.companies.some((x:any) => x.id== selected_account_id)) {
      let c = response.companies.find((x:any) => x.id == selected_account_id);
      dispatch(setSelectedCompanyId(selected_account_id))
      dispatch(setDataLogin({id:selected_account_id, is_user: false}));

    } else
    if (response.users.some((x:any) =>x.id== selected_account_id)) {
      dispatch(setSelectedUserId(selected_account_id))
      dispatch(setDataLogin({id:selected_account_id, is_user: true}));

    }
  }
  return selected_account_id;
};

export default userProfileSlice.reducer
