import './UserProfile.css'

import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {setVisibleUserProfileForm, userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {ArrowLeftIcon} from "../../Elements/SvgIcons/ArrowLeftIcon";
import ProfileUserInfo from './ProfileUserInfo';
import ProfileCompanyInfo from './ProfileCompanyInfo';
import {BackRoundIcon} from "../../Elements/SvgIcons/BackRoundIcon";
interface IProps {
    onClose:()=>void
}

const UserProfile : React.FC<IProps> = ({onClose}) =>{

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(userProfileSelector);
    const   isAuth   = userProfile.isAuth;
    const [isCompanyDefault, setCompanyDefault] = useState<number>(0);
    const [defaultUserIndex, setDefaultUserIndex] = useState<number>(0);
    //@ts-ignore
    window.profile = userProfile

    useEffect(()=>{

       if (userProfile.data_login?.is_user){
           let user_index = userProfile.profile.users.findIndex(x=> x.id == userProfile.data_login?.id);
           setDefaultUserIndex(user_index);
       }

        if (userProfile.data_login?.is_user === false){
            let user_index = userProfile.profile.companies.findIndex(x=> x.id == userProfile.data_login?.id);
            setCompanyDefault(user_index);
        }

    }, [userProfile.data_login, userProfile.profile.companies, userProfile.profile.users])

    return (
        <div className='user_profile'>
            <BackRoundIcon className="menu-form-close profile-form-back" onClick={onClose} />

            {!isAuth && <div className='attention'>Для просмотра профиля необходимо авторизоваться</div>}
            {isAuth && !userProfile.selected_company_id && <ProfileUserInfo userIndex={defaultUserIndex}/>}
            {isAuth && userProfile.selected_company_id  && <ProfileCompanyInfo userIndex={defaultUserIndex} companyId={userProfile.selected_company_id}/>}
        </div>
    )
}

export default UserProfile;

