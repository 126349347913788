import { useEffect, useState } from "react";
import {
  validateEmail,
  validateName,
  validateNumber,
  validatePhone,
} from "../../../../api/validate";
import cn from "classnames";
import InputMask from 'react-input-mask'
import validIcon from "../../../App/svg/valid.svg";
import { ReactSVG } from "react-svg";
import { DaDataParty, DaDataSuggestion, PartySuggestions } from "react-dadata";

import "./ValidateInput.css";
import {daDataToken} from "../../../../deployment";

interface ValidateInputProps {
  inputClass?: string;
  value?: string;
  type: "inn" | "fio" | "phone" | "email" | "code";
  errorMessage: string;
  onError: (arg0: string) => void;
  onChangeInput: (arg0: string | number) => void;
  placeholder?: string;
  inputTabIndex: number;
  isValid?: boolean | null;
  isRequired?: boolean | null;
}

export const ValidateInput: React.FC<ValidateInputProps> = ({
  inputClass = "",
  value = "",
  type,
  errorMessage,
  onError,
  onChangeInput,
  placeholder = "",
  inputTabIndex,
  isValid = false,
  isRequired = true
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showValidIcon, setShowValidIcon] = useState(false);


  const validateInputValue = (inputValue: string | number) => {
      if ( (type === "fio" && validateName(inputValue as string)) || ((type === "phone")  && validatePhone(inputValue as string)) ||
        (type === "email" && validateEmail(inputValue as string)) || (type === "code" && validateNumber(inputValue as string, 4))) {
        setShowValidIcon(true);
        onChangeInput(inputValue);
      }
  };

  const checkErrors = (inputValue: string | number) => {
      if (!isRequired && inputValue === "") return;
      if ( (type === "fio" && !validateName(inputValue as string)) || ((type === "phone")  && !validatePhone(inputValue as string)) ||
          (type === "email" && !validateEmail(inputValue as string)) || (type === "code" && !validateNumber(inputValue as string, 4))) {

          setShowErrorMessage(true);
          onError(errorMessage);
      }
  }


  let inputType = "text";
  if (type === "phone") inputType = "phone";
  if (type === "email") inputType = "email";
  // if (type === "code") inputType = "number";
  // if (type === "inn") inputType = "number";

  const [valueOrg, setValueOrg] = useState<
    DaDataSuggestion<DaDataParty> | undefined
  >();
  const onChangeCompany = (value?: DaDataSuggestion<DaDataParty>) =>{
      console.log('onChangeCompany', value);
      setValueOrg(value);
      if (value)
        onChangeInput(value.data.inn + ":" + value.value);
      else
        onChangeInput('');
  }
  useEffect(() => {
    if (type === "code" && isValid) {
      setShowValidIcon(true);
    }
  }, [isValid, type]);

  return (
    <div className={cn("validate-input", { [`${inputClass}`]: inputClass })}>
      {type === "inn" && (
        <div className="validate-input__input-wrapper">
          <PartySuggestions
            token={daDataToken}
            value={valueOrg}
            onChange={onChangeCompany}
            count={5}
            //@ts-ignore
            selectOnBlur={true}
            autoload={true}
            inputProps={{
              className: "validate-input__input",
              placeholder: placeholder,
              tabIndex: inputTabIndex,
            }}
          />
          {valueOrg?.data.inn && (
            <div className="validate-input__inn">{valueOrg.data.inn}</div>
          )}
        </div>
      )}
      {type === "phone" &&
        <div className="validate-input__input-wrapper">
          <InputMask
              className={"validate-input__input"}
              mask='+7 999 999 9999'
              value={inputValue}
              onChange= { (e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
                setShowErrorMessage(false);
                setShowValidIcon(false);
                validateInputValue(e.target.value);
              }}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>)=>checkErrors(e.target.value)}
              placeholder={placeholder || '+7 999 999 9999'}
              name='phone'
              tabIndex={inputTabIndex}
          />
          <ReactSVG
              src={validIcon}
              className={cn("validate-input__icon", {
                "show-icon": showValidIcon,
              })}
          />
        </div>
      }
      {type !== "phone" && type !== "inn" && (
        <div className="validate-input__input-wrapper">
          <input
            className="validate-input__input"
            type={inputType}
            value={inputValue}
            placeholder={placeholder}
            onChange={(e) => {
              setInputValue(e.target.value);
              setShowErrorMessage(false);
              setShowValidIcon(false);
              validateInputValue(e.target.value);
            }}
            onBlur={(e )=>checkErrors(e.target.value)}
            tabIndex={inputTabIndex}
          />
          <ReactSVG
            src={validIcon}
            className={cn("validate-input__icon", {
              "show-icon": showValidIcon,
            })}
          />
        </div>
      )}
      <div
        className={cn("validate-input__error-message", {
          "show-err": showErrorMessage,
        })}
      >
        {errorMessage}
      </div>
    </div>
  );
};
