import React, {FC, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import "./BalanceForm.css"


export enum IBalanceListFilterType{
    orders ="Заказы",
    bills = "Счета",
    acts = "Акты"
}

interface IProps{

}
export const BalanceForm :FC<IProps> = ({ }) => {
    const { clientId } = useAppSelector(userProfileSelector);

    const [filter, setFilter] = useState(IBalanceListFilterType.orders);

    const onClickFilter = (type: IBalanceListFilterType) => {
        setFilter(type) ;
    };

    return (
        <div className="balance-window">
            <div className="balance-window-title">Баланс</div>
            <div className="balance-window-menu">
                {Object.values(IBalanceListFilterType).map((item, index) => (
                    <div
                        className={"balance-window-menu-item" + ( filter === item ? ' selected':'')}
                        key={index}
                        onClick={(e) => onClickFilter(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className="balance-window-content">


            </div>
        </div>
    );
};
