import React, {useEffect, useState} from "react";
import {
    AddressPoints,  SelectOrderMode,
    SelectTariff,
} from "../../Elements/elements";

import "./CreateOrderForm.css";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
    currentOrderData,
    getOrderIsChanged,
    getRoutePointAsMarkers,
    setAgreement,
    setClearOrderData,
    setOrderChanged,
    setOrderDataManual,
    setOrderID,
    setOrderMode, setPhoneChecked,
    setTariffLoading,
    setTariffsData
} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {setClearMarkers, setClearRoutes, setMarkers, setRoutes} from "../../../redux/reducers/slices/hereMapSlice";
import {mapRoute} from "../../../redux/reducers/slices/hereMapTypes";
import OrderSelectDate from "../../Elements/OrderSelectDate/OrderSelectDate";
import { OrderAdditionalRequirements } from "../../Elements/OrderAdditionalRequirements/OrderAdditionalRequirements";
import {OrderPaymentMethod} from "../../Elements/OrderPaymentMethod/OrderPaymentMethod";
import { OrderUserRegistration } from "../../Elements/OrderUserRegistration/OrderUserRegistration";
import OrderCreateButton from "../../Elements/OrderCreateButton/OrderCreateButton";
import {EnumOrderMode, SendOrderInfo} from "../../../redux/reducers/slices/CreateOrderType";
import {cookiesOrderId} from "../../../deployment";
import {useCookies} from "react-cookie";
import {userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {serverAPI} from "../../../api/server";
import {showErrorMessage} from "../../../redux/reducers/system/system_functions";
interface IProps {


}
const CreateOrderForm: React.FC<IProps> = ( ) => {
    const dispatch = useAppDispatch();





    const is_changed = useAppSelector(getOrderIsChanged);
    const currentOrder= useAppSelector(currentOrderData);
    const { isAuth, clientId } = useAppSelector(userProfileSelector);
    const [cookies, setCookie] = useCookies([cookiesOrderId])

    useEffect(() => {
        if (is_changed) {
            dispatch(setOrderChanged(false));
            console.log('was changed');
            let data : SendOrderInfo = {...currentOrder};
            data.draft = true;
            if (isAuth && clientId) data.client_id = clientId;
            serverAPI.postOrder(data)
                     .then((res : any) => {
                        console.log('postOrder', res);
                        if (!res || res.status !== 200 || !res.data || res.data.status ==="error") {
                            showErrorMessage(res.data && res.data.error_message ? res.data.error_message : 'Ошибка сохранения заказа ', dispatch)
                        } else if (res.data.id)  {
                            dispatch(setOrderID(res.data.id));
                            setCookie(cookiesOrderId, res.data.id, { path: '/' });
                        }
                })
        }
    }, [is_changed]);

    const onOrderComplete = () => {
        setCookie(cookiesOrderId, '', { path: '/' });
        dispatch(setTariffsData({data:[], names:[]}));
        dispatch(setTariffLoading(false));
        dispatch(setAgreement(false));
        dispatch(setClearRoutes());
        dispatch(setClearMarkers());
        dispatch(setClearOrderData());
    };

    return (
        <div className="create-order__form">
          <OrderSelectDate/>
          <AddressPoints  />
          <SelectOrderMode/> 
          <OrderAdditionalRequirements/>
          <SelectTariff />

          <OrderUserRegistration   />
          <OrderPaymentMethod   />
          <OrderCreateButton  onCreateOrder={()=>onOrderComplete()} />

        </div>
    );
};

export default CreateOrderForm;
