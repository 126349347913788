import {FC} from "react";

interface IProps {
    onClick?: (e?:any) => void;
    className?:string
}

export const CloseIcon:FC<IProps> = ({onClick,className})=>{
    return (
        <div style={{   padding: "9px",
                        width:"40px",
                        height: "40px",
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
                        borderRadius: "29px"}
                    }
             className={className}
             onClick={onClick}
        >
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="6" y1="15.8993" x2="15.8995" y2="5.99977" stroke="#3B3F49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.8994 16L5.99992 6.10051" stroke="#3B3F49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

    )
}
