import React, {useEffect, useState} from "react";
import {ReactSVG} from "react-svg";


import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

import {SelectCarAuto, SelectCarManual} from "../elements";

import carGray from "../../App/svg/car_gray.svg"
import selectCarClose from "../../App/svg/car_close.svg"

import "./SelectOrderMode.css";
import {getCargoObject, getPackInfo} from "../../../redux/reducers/slices/CreateOrderFunctions";
import {
    CargoInfo,
    CargoTypes, CPackInfo,
    EnumOrderMode, IBOCharacteristics,
    IPackInfo,
    SelectedCarData
} from "../../../redux/reducers/slices/CreateOrderType";
import {
    currentOrderData,
    getTariffInfo,
    setOrderData,
    setOrderMode,
    setPackInfo
} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {useAppSelector} from "../../../redux/hooks";
import CargoIcon from '../../../images/cargo.svg';
import {serverAPI} from "../../../api/server";
import {showErrorMessage} from "../../../redux/reducers/system/system_functions";
import {systemSelector} from "../../../redux/reducers/system/system_reducers";



const SelectOrderMode: React.FC = () => {
    const dispatch = useDispatch();
    let { order_auto, order_mode, order_manual, pack_info} = useSelector((state: RootState) => state.createOrderReducer);
    let { categories} = useSelector((state: RootState) => state.systemReducer);
    let   order  = useAppSelector(currentOrderData);

    let [isShowForm, setShowForm] = useState<boolean>(false);
    let [localDataAuto, setLocalDataAuto] = useState<{data:SelectedCarData, list:CargoTypes[]}>({data:new SelectedCarData(), list: []});
    let [backupDataAuto, setBackupDataAuto] = useState<{data:SelectedCarData, list:CargoTypes[]}>({data:new SelectedCarData(), list: []});

    let [localDataManual, setLocalDataManual] = useState<SelectedCarData>(new SelectedCarData());
    let [backupData, setBackupData] = useState<SelectedCarData>(new SelectedCarData());
    let [total, setTotal] = useState<{total_volume:number, total_area: number, total_weight:number} | undefined>(undefined);
    const { tariff_types } = useAppSelector(systemSelector)

    let disabled = false;
    useEffect(()=>{
        if (pack_info.total_volume) {
            setTotal(pack_info)
        } else
            setTotal(undefined)
    },[pack_info]);

    useEffect(()=>{
            if (order_mode == EnumOrderMode.Manual) {

            }  else
            if (order.cargo.pallets.length || order.cargo.packages.length || order.cargo.places.length ) {
                if (pack_info.total_volume === 0) {
                    serverAPI.getPackInfo({cargo: order.cargo, body_option_id: order.body_option_id, body_option_characteristics: order.body_option_characteristics as IBOCharacteristics[]})
                        .then(resp => {
                            if (resp.state !== 'error')
                                dispatch( setPackInfo(resp));
                        })

                    }
                } else
                dispatch( setPackInfo(new CPackInfo()));

    } ,[order]);



    const formToggle = (mode:EnumOrderMode) =>{
        dispatch(setOrderMode(mode));
        setShowForm(true);
        if (mode === EnumOrderMode.Auto)
            setBackupDataAuto(JSON.parse(JSON.stringify(localDataAuto)));
         else
            setBackupData(JSON.parse(JSON.stringify(localDataManual)));
    };

    const onCancel = () =>{
        if (order_mode === EnumOrderMode.Auto)
            setLocalDataAuto(backupDataAuto);
        else
            setLocalDataManual(backupData);
        setShowForm(false);
    };

    const onSave = () => {
        let data  =  order_mode === EnumOrderMode.Auto ? localDataAuto.data : localDataManual;
        if (!data.body_option_id || !data.category_id)
            return showErrorMessage('Нобходимо выбрать категорию и тип кузова!', dispatch);

        if (order_mode === EnumOrderMode.Auto && localDataAuto.list.length===0)
            return showErrorMessage('Нобходимо добавить хотябы один вид груза!', dispatch);

        let newOrder = {...order};
        newOrder.body_option_id = data.body_option_id;
        newOrder.car_type_id = data.category_id;
        newOrder.body_option_characteristics = data.active_characteristic.map(x=> ({id:x.id, value: x.value}));
        newOrder.max_dimensions = data.max_dimensions;
        newOrder.cargo = order_mode === EnumOrderMode.Auto ?  getCargoObject(localDataAuto.list, data.max_dimensions) : new CargoInfo();
        dispatch(setOrderData(newOrder));
        setShowForm(false);
        getTariffInfo(newOrder, dispatch, categories, tariff_types )
    };

    const getCategoryName = (id:string) => categories.filter(x=>x.id === id).map(x=>x.name).join('');


    return (
        <>
            <div className="select-order-mode">
            <ReactSVG  src={carGray}  className="select-order-mode__car"/>
            {!isShowForm && <>
                        <button disabled={disabled}
                            className={"select-order-mode__button " + (order_mode===EnumOrderMode.Manual ? 'selected' :'')}
                            onClick={()=>formToggle(EnumOrderMode.Manual)}>Выбор ТС
                            {localDataManual.category_id  && order_mode === EnumOrderMode.Manual &&
                            <span className="select-order-mode__button__category">{getCategoryName(localDataManual.category_id)}</span> }
                        </button>
                        <button disabled={disabled}
                            className={"select-order-mode__button " + (order_mode===EnumOrderMode.Auto ? 'selected' :'')}
                            onClick={()=>formToggle(EnumOrderMode.Auto) }>Автоподбор ТС
                            {localDataAuto.data && localDataAuto.data.category_id && order_mode === EnumOrderMode.Auto &&
                             <span className="select-order-mode__button__category">{getCategoryName(localDataAuto.data.category_id)}</span> }
                        </button>

                    </>}
            { isShowForm && order_mode ===  EnumOrderMode.Auto &&
                <div className={"select-order-mode__close_and_save"}>
                    <button disabled={disabled}
                           className={"select-order-mode__button select-order-mode__button-save"}
                           onClick={()=>onSave() }>Сохранить</button>
                    <ReactSVG src={selectCarClose} onClick={onCancel}  className={"select-order-mode__cancel"}/>
                    <SelectCarAuto data={localDataAuto.data}  cargoes={localDataAuto.list}
                                   onChange={(data, list)=>{
                                       console.log('setLocalDataAuto({data, list})', data, list);
                                       setLocalDataAuto({data, list})
                                   }}/>
                </div>}
            { isShowForm && order_mode ===  EnumOrderMode.Manual &&
            <div className={"select-order-mode__close_and_save"}>
                <button disabled={disabled}
                       className={"select-order-mode__button select-order-mode__button-save"}
                       onClick={()=>onSave() }>Сохранить</button>
                <ReactSVG src={selectCarClose} onClick={onCancel}  className={"select-order-mode__cancel"}/>
                <SelectCarManual data={localDataManual} onChange={(data )=>{setLocalDataManual( data )}}/>
            </div>}
        </div>
            {!isShowForm && total  && order_mode === EnumOrderMode.Auto &&
            <div className="cargo-total-items">
                <div>
                    <ReactSVG src={CargoIcon} />
                </div>
                <div>
                    <div className="cargo-item__size-title left">Общий объем</div>
                    <div className="cargo-item__size-title-value">{total?.total_volume} м³</div>
                </div>
                <div>
                    <div className="cargo-item__size-title">Общая площадь</div>
                    <div className="cargo-item__size-title-value">{total?.total_area} м²</div>
                </div>
                <div>
                    <div className="cargo-item__size-title right">Общий вес</div>
                    <div className="cargo-item__size-title-value">{total?.total_weight} кг</div>
                </div>
            </div>
            }
        </>

    )
};

export default  SelectOrderMode;
