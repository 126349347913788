import cn from "classnames";
import {useEffect, useState} from "react";
import "./ChooseTime.css";

interface ChooseTimeProps {
  label: string;
  timeFromValue?: string;
  timeToValue?: string;
  onChange:(value:{from: string, to: string}) => void
}
export const ChooseTime: React.FC<ChooseTimeProps> = ({
  label,
  timeFromValue = "00:00",
  timeToValue = "00:00",
  onChange
}) => {
  const [activateInputs, setActivateInputs] = useState(false);
  const [valueFrom, setValueFrom] = useState(timeFromValue);
  const [valueTo, setValueTo] = useState(timeToValue);
  const showInputs = () => {
    setActivateInputs(true);
  };
  useEffect(()=>{
    onChange({from: valueFrom, to: valueTo})
  }, [valueFrom, valueTo]);

  return (
    <div
      className={cn("choose-time-wrapper", { "show-inputs": activateInputs })}
    >
      <label onClick={showInputs}>{label}</label>
      <div className="choose-time-inputs">
        <span>c</span>
        <input
          type="time"
          value={valueFrom}
          onChange={(e) => setValueFrom(e.target.value)}
        />
        <span>до</span>
        <input
          type="time"
          value={valueTo}
          onChange={(e) => setValueTo(e.target.value)}
        />
      </div>
    </div>
  );
};
