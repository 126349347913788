import ProfileMenuItem from "./ProfileMenuItem";
import './UserProfile.css'
import {useEffect, useState} from "react";
import ProfileEditOneRecord from "./ProfileEditOneRecord";
import {serverAPI} from "../../../api/server";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {setSelectedCompanyId, setUserProfile, userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {
    CCompany,
    CEditOneRecordData,
    CUser,
    CUserProfile,
    EnumEditOneRecordType
} from "../../../redux/reducers/slices/userProfile.type";
import {BackRoundIcon} from "../../Elements/SvgIcons/BackRoundIcon";

interface IProps {
    userIndex:number;
}
const ProfileUserInfo : React.FC<IProps> = ({userIndex}) =>{

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(userProfileSelector);

    const user : CUser = userProfile.profile.users.length ? userProfile.profile.users[userIndex] :  new CUser();

    const [isEditItem, setEditItem] = useState<CEditOneRecordData|undefined>(undefined);
    const [isLoadingItem, setLoadingItem] = useState<EnumEditOneRecordType>(EnumEditOneRecordType.none);
    const [isErrorText, setErrorText] = useState<{text:string, type: EnumEditOneRecordType} | undefined>(undefined);

    useEffect(()=>{  }, [userProfile.profile]);

    const updateData = async (data : CUserProfile, loadingType: EnumEditOneRecordType, company_id="") => {
        setEditItem(undefined);
        setLoadingItem(loadingType);
        setLoadingItem(loadingType);
        let res = await serverAPI.postProfileInfo(data);
        setLoadingItem(EnumEditOneRecordType.none);
        if (!res || !res.data || !res.data.status || res.data.status === "error")
            return setErrorText({text: 'Ошибка сохранения данных <br>' +res?.data?.error_message, type:loadingType});
        dispatch( setUserProfile( data) );
        if (loadingType == EnumEditOneRecordType.add_company) {
            dispatch( setSelectedCompanyId( company_id) );
        }
    };

    const getProfileNewUser = (new_user_data: CUser) => {
        let profile : CUserProfile = {...userProfile.profile, users:[...userProfile.profile.users], companies:[...userProfile.profile.companies]};
        profile.users = [...profile.users.filter((x:any,index:number)=>index !== userIndex), new_user_data];
        return profile;
    };

    const onSetData = async (value:any,type:EnumEditOneRecordType) =>{
        if (value !== undefined) {
            switch (type) {
                case EnumEditOneRecordType.email: await updateData(getProfileNewUser({...user, email: value}), type ); break;
                case EnumEditOneRecordType.phone: await  updateData( getProfileNewUser({...user, phone:value}) , type); break;
                case EnumEditOneRecordType.name: await updateData( getProfileNewUser({...user, full_name:value}) , type); break;
            }
        }
        setEditItem(undefined);
    };


    const onSetINN = (value:any,type:EnumEditOneRecordType) =>{
        console.log('onSetINN', value);
        if (value !== undefined) {
            let company = new CCompany(value.inn, value.name);
            let profile : CUserProfile = {...userProfile.profile, users:[...userProfile.profile.users], companies:[...userProfile.profile.companies, company]};
            updateData(profile, EnumEditOneRecordType.add_company, value.id);
        }
        setEditItem(undefined);
    };
    if ( isEditItem) return <ProfileEditOneRecord value={isEditItem} />;
    return (
        <>

            <div className="user_and_company_info user_info">
                <div className='name'>{user.full_name}</div>
                <div className='menu-items'>
                    <ProfileMenuItem name={'ФИО пользователя'} value={user.full_name} is_arrow={true} append_class={'no-top-border'}
                                     is_loading={isLoadingItem === EnumEditOneRecordType.name}
                                     onClick={()=>{
                                         setEditItem(new CEditOneRecordData('ФИО пользователя аккаунта', 'Иванов Иван Иванович', user.full_name, onSetData, EnumEditOneRecordType.name ))}
                                     } />
                    <ProfileMenuItem name={'Моб. телефон'} value={user.phone} is_arrow={true}
                                     is_loading={isLoadingItem === EnumEditOneRecordType.phone}
                                     onClick={()=>{
                                         setEditItem(new CEditOneRecordData('Мобильный телефон пользователя', '+79006498996', user.phone, onSetData, EnumEditOneRecordType.phone))}

                                     } />
                    <ProfileMenuItem name={'Email'} value={user.email} is_arrow={true}
                                     is_loading={isLoadingItem === EnumEditOneRecordType.email}
                                     onClick={()=>{
                                            setEditItem(new CEditOneRecordData('Email пользователя', 'Введите свой Email', user.email, onSetData, EnumEditOneRecordType.email))}
                                     } />
                    {
                        userProfile.profile.companies.map((x:CCompany)=>  <ProfileMenuItem key={x.id} name={'ИНН: ' + x.tin} value={x.name} is_arrow={true} append_class={'no-bottom-border'}
                                                                                onClick={()=>{
                                                                                    console.log('x', x)
                                                                                    dispatch(setSelectedCompanyId(x.id))
                                                                                }}/>
                                                         )
                    }
                    <ProfileMenuItem name={''} value={'Создать профиль компании'} is_arrow={false} append_class={'no-bottom-border'}
                                     is_loading={isLoadingItem === EnumEditOneRecordType.add_company}
                                     onClick={()=>{
                                         setEditItem(new CEditOneRecordData('Создание профиля компании', 'ИНН компании', '', onSetINN, EnumEditOneRecordType.add_company))}
                                     }/>
                </div>
            </div>
            { isEditItem && <ProfileEditOneRecord value={isEditItem} />}
        </>
    )
}

export default ProfileUserInfo;
