import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {  systemReducer, hereMapReducer, createOrderReducer, userProfileReducer} from "./reducers";



const reducers = combineReducers({

    systemReducer,
    hereMapReducer,
    createOrderReducer,
    userProfileReducer,
});


const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const store = createStore(reducers, applyMiddleware(thunkMiddleware));
export type AppDispatch = typeof store.dispatch

//@ts-ignore
window.__store__ = store;

export default store;

export type RootState = ReturnType<typeof reducers>;
