import React, {useEffect, useState} from "react";

import { HereMap, SelectCarAuto } from "../../Elements/elements";
import { CreateOrderForm } from "../../components";


import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {hereMapSelector, setMarkers, setRoutes} from "../../../redux/reducers/slices/hereMapSlice";
import {
    createOrderSelector,

    currentOrderData,
    getRoutePointAsMarkers, getStreetValue, getTariffInfo, setAddressFromDaData,

    setOrderRoutes,
    setShowMapMode
} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {
    EnumAddressMode,
    EnumShowMapMode,
    RouteAddress,
    TypeLonLat
} from "../../../redux/reducers/slices/CreateOrderType";

import {daDataAPI} from "../../../api/dadata-api";
import {mapRoute} from "../../../redux/reducers/slices/hereMapTypes";
import {setShowMenu, systemSelector} from "../../../redux/reducers/system/system_reducers";
import {BurgerMenu} from "../../Elements/BurgerMenu/BurgerMenu";
import {MenuForm} from "../MenuForm/MenuForm";


const MainScreen: React.FC = () => {
    const dispatch = useAppDispatch();
    const { markers, routes } = useAppSelector(hereMapSelector);
    const orderReducer = useAppSelector(createOrderSelector);
    const order = useAppSelector(currentOrderData);
    const {categories, tariff_types, is_show_menu} = useAppSelector(systemSelector);
    const [newPointOnMap, setNewPointOnMap] = useState<{lon:number, lat:number}|undefined>(undefined);

    const [isRegistrationFormShow, setRegistrationFormShow] = useState<boolean>(false);
    const [onNewCoordinatePoint, setNewCoordinatePoint] = useState<{index:number,point:TypeLonLat} | undefined>(undefined);


    //console.log('orderReducer.order_auto', orderReducer.order_auto)
    //console.log('order', order)
    useEffect(()=>{
        if (!onNewCoordinatePoint) return;
        setAddressFromDaData(order.routes, onNewCoordinatePoint.point, onNewCoordinatePoint.index, dispatch);
    },[onNewCoordinatePoint])

    const onMarkerMove = async (index:number, point: TypeLonLat ) => {
        console.log('order.routes', order.routes)
        setNewCoordinatePoint({index,point})
        // setAddressFromDaData(order.routes, point, index, dispatch);
    }
    const onMapClick = async ( ) => {
        console.clear();
        console.log('orderReducer.order_auto', orderReducer.order_auto)
        console.log('order', order)
        if (orderReducer.selected_address_index < 0 || !newPointOnMap) return;
        setAddressFromDaData(order.routes, newPointOnMap, orderReducer.selected_address_index, dispatch);
        // const result : any = await daDataAPI.getAddressByCoords(  newPointOnMap ) ;
        // console.log('result', result, orderReducer.selected_address_index)
        // if ( result && result.data  && result.data.suggestions && result.data.suggestions.length) {
        //     let data  = result.data.suggestions[0];
        //     let addr :RouteAddress = new RouteAddress(orderReducer.selected_address_index);
        //     addr = {...order.routes[orderReducer.selected_address_index]};
        //     console.log('addr', addr, order)
        //     addr.adress = data.value ;
        //     addr.adress_latitude = data.data.geo_lat;
        //     addr.adress_longitude = data.data.geo_lon;
        //     addr.house = data.data.house;
        //     let rt = [...order.routes];
        //     rt[orderReducer.selected_address_index] = addr;
        //     dispatch(setOrderRoutes(rt))
        //
        // }
    };

    useEffect(() => { onMapClick();},[newPointOnMap])

    const setDetectedLocationAsCurrent = async () => {

        await navigator.permissions
            .query({ name: "geolocation" })
            .then(data => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(  async (pos: any) => {
                        let coord = {lat: pos.coords.latitude, lon: pos.coords.longitude};
                        const result : any = await  daDataAPI.getAddressByCoords(coord) ;
                        if (result.isSuccess && result.data  && result.data.suggestions && result.data.suggestions.length) {
                            let route = new RouteAddress(0);
                            let data  = result.data.suggestions[0];
                            route.adress = getStreetValue(data);
                            route.adress_latitude = data.data.geo_lat;
                            route.adress_longitude = data.data.geo_lon;
                            route.house = data.data.house;
                            dispatch(setOrderRoutes([route]));
                        }
                    });
                } else {
                    alert("Geolocation is not supported by this browser.")
                }
            })
    }

    useEffect(()=> {

        dispatch(setMarkers(getRoutePointAsMarkers(order)));
        if (order.routes.filter(x=> x.adress!="").length < 2){
            dispatch(setRoutes([]));
        } else {
            let route = new mapRoute();
            route.coordinate = order.routes.filter(x=>x.adress).map(x=> ({ lon: x.adress_longitude, lat: x.adress_latitude  }) );
            route.type = 0;
            if (route.coordinate.length == order.routes.length) {
                dispatch( setRoutes([route]))
                getTariffInfo(order, dispatch, categories, tariff_types )
            }
        }

    },[order.routes]);



    return (
    <>
        <HereMap
            markers={markers}
            onClick={(lon: number, lat: number)=> setNewPointOnMap({lon, lat})}
            onMarkerClick={(index => console.log('click on marker ' + index))}
            onMarkerMove={onMarkerMove}
            routs={routes}
        />
        {!is_show_menu && !isRegistrationFormShow && <CreateOrderForm />}
        {!is_show_menu && !isRegistrationFormShow && <BurgerMenu onClick={()=>dispatch(setShowMenu(true))} onRegistrationClick={()=>setRegistrationFormShow(true)} />}
        {is_show_menu && <MenuForm onClose={()=>dispatch(setShowMenu(false))} />}
        {isRegistrationFormShow && <MenuForm showRegistrationForm={true} onClose={()=>{setRegistrationFormShow(false)}} />}
    </>
    );
};

export default MainScreen;
