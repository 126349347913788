import {IAdditionalRequirements, IPackageType, IPalletType, ITariffInfo} from "../slices/CreateOrderType";

export const DATA_LOADING = 'DATA-LOADING';
export const SYSTEM_MESSAGE = 'SYSTEM_MESSAGE';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_TARIFF_TYPES = 'SET_TARIFF_TYPES';
export const SET_PALLET_TYPES = 'SET_PALLET_TYPES';
export const SET_PACKAGE_TYPES = 'SET_PACKAGE_TYPES';
export const SET_ADDITIONAL_REQUIREMENTS = 'SET_ADDITIONAL_REQUIREMENTS';
export const SET_SHOW_MENU = 'SET_SHOW_MENU';


export enum MessageType{ None, Error, Info, Warning}
export interface MessageStruct {
 msg_type: MessageType, message: string
}

export interface SetDataLoadingAction { type: typeof DATA_LOADING;  value: boolean;}
export interface SetSystemMessageAction { type:typeof SYSTEM_MESSAGE, msg: MessageStruct}
export interface SetCategoriesAction { type:typeof SET_CATEGORIES, categories: IFullCategory[]}
export interface SetBaseTariffTypesAction { type: typeof SET_TARIFF_TYPES;  tariff_types: ITariffInfo[];}
export interface SetPalletTypesAction { type: typeof SET_PALLET_TYPES;  pallet_types: IPalletType[];}
export interface SetPackageTypesAction { type: typeof SET_PACKAGE_TYPES;  package_types: IPackageType[];}
export interface SetAdditionalRequirementsAction { type: typeof SET_ADDITIONAL_REQUIREMENTS;  additional: IAdditionalRequirements[];}
export interface SetShowMenuAction { type: typeof SET_SHOW_MENU;  is_show_menu: boolean;}

export type SystemState = {
    data_loading:boolean;
    message: MessageStruct;
    categories: IFullCategory[];
    tariff_types: ITariffInfo[];
    pallet_types: IPalletType[];
    package_types: IPackageType[];
    additional: IAdditionalRequirements[];
    is_show_menu:boolean;
}


export type SystemActions =  SetDataLoadingAction | SetSystemMessageAction | SetCategoriesAction | SetBaseTariffTypesAction |
                             SetPalletTypesAction | SetPackageTypesAction | SetAdditionalRequirementsAction | SetShowMenuAction;

export interface IFullCategory {
    name: string,
    length_from: number,
    length_to: number,
    width_from: number,
    width_to: number,
    height_from: number,
    height_to: number,
    weight_from: number,
    weight_to: number,
    area_from: number,
    area_to: number,
    volume_from: number,
    volume_to: number,
    pallets_from: number,
    pallets_to: number,
    pass_sk: boolean,
    pass_ttk: boolean,
    pass_mkad: boolean,
    ramp: boolean,
    tail_lift: boolean,
    board: boolean,
    refrigerator: boolean,
    id: string,
    body_options:IBodyOptions[]
}

export interface IBodyOptions {
    id: string,
    name: string,
    body_type_id: number,
    body_option_characteristics: IBOCharacteristics[]
}

export enum CharacteristicTypeEnum {
    Boolean = "Boolean", Ref = "ref", Number = "number"
}
export interface IBOCharacteristics {
    id: string,
    name: string,
    type: CharacteristicTypeEnum,
    selected:boolean,
    value:any,
    body_option_characteristics_values: IBOCharacteristicsValue[]
}

export interface DropDownListItem {
    id: string;
    text: string;
}

export interface IBOCharacteristicsValue {
    id: string,
    name: string,
    selected:boolean,
    body_option_characteristics_id: string
}

export interface IPointOnMap {lon:number, lat:number}


export const checkIsValidGuid = (guid: string) => {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        guid
    )
}

export interface BodyOptionCharacteristicsResponse {
    id: string,
    name: string,
    type: CharacteristicTypeEnum,
    selected:boolean,
    value:any,
    body_option_characteristics_values: IBOCharacteristicsValue[]
}

export enum EnumOrderStatusID {
    DriverSearching = 0, DriverFound = 1,Executing = 2, Completed = 3, Canceled
}
