import s from './hereMap.module.scss'
import CarsIcon from '../../App/img/car0.png'
import {EnumMarkerType} from "../../../redux/reducers/slices/hereMapTypes";
// выбор шаблонов SVG маркеров

const markerType = (markerType: EnumMarkerType, color: string = 'orange') => {
    console.log('markerType' , markerType)
    switch (markerType) {
      case EnumMarkerType.MainDraggable:
      case EnumMarkerType.MainNotDraggable:
        return `<svg id="SVG_ID" width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <path d="M10.3997 16.5994C10.1497 14.6237 10.9248 12.6695 12.7186 11.8046C16.0415 10.2025 22.6699 8 34 8C45.3301 8 51.9585 10.2025 55.2814 11.8046C57.0752 12.6695 57.8503 14.6237 57.6003 16.5994L56.8657 22.4068C55.5931 32.4663 51.0325 41.8234 43.893 49.0234L34 59L24.107 49.0234C16.9675 41.8234 12.4069 32.4663 11.1343 22.4068L10.3997 16.5994Z" fill="${color}"/>
        <ellipse cx="34" cy="33.5" rx="8" ry="6.5" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0.353943" y="0" width="67.2921" height="71" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>`
      case EnumMarkerType.OrderNotDraggable:
        return `<svg id="SVG_ID" width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <path d="M10.3997 16.5994C10.1497 14.6237 10.9248 12.6695 12.7186 11.8046C16.0415 10.2025 22.6699 8 34 8C45.3301 8 51.9585 10.2025 55.2814 11.8046C57.0752 12.6695 57.8503 14.6237 57.6003 16.5994L56.8657 22.4068C55.5931 32.4663 51.0325 41.8234 43.893 49.0234L34 59L24.107 49.0234C16.9675 41.8234 12.4069 32.4663 11.1343 22.4068L10.3997 16.5994Z" fill="${color}"/>
        <ellipse cx="34" cy="33.5" rx="8" ry="6.5" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0.354004" y="0" width="67.2921" height="71" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
        `

      default:
        return `<svg id="SVG_ID" width="68" height="71" viewBox="0 0 68 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
        <path d="M10.3997 16.5994C10.1497 14.6237 10.9248 12.6695 12.7186 11.8046C16.0415 10.2025 22.6699 8 34 8C45.3301 8 51.9585 10.2025 55.2814 11.8046C57.0752 12.6695 57.8503 14.6237 57.6003 16.5994L56.8657 22.4068C55.5931 32.4663 51.0325 41.8234 43.893 49.0234L34 59L24.107 49.0234C16.9675 41.8234 12.4069 32.4663 11.1343 22.4068L10.3997 16.5994Z" fill="${color}"/>
        <ellipse cx="34" cy="33.5" rx="8" ry="6.5" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0.353943" y="0" width="67.2921" height="71" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>`
    }
}

// возвращает готовую иконку

const hereMarkerIcon = (type: EnumMarkerType, text: string, color: string | undefined, is_show_text: boolean) => {
    return (`
    <div>
        <div class="${s.iconContainer}">
            ${is_show_text ? `<div class="${s.markerNumber}">${text}</div>` : ''}            
            ${markerType(type, color)}
        </div>
    </div>
`)
}

export const HereMarkerIconCar = (bearing:number) => `
    <div> 
       <div style="width: 44px; height: 44px; overflow: hidden; margin-left: -22px; margin-top: -22px; z-index: 33"> 
         <img src=${CarsIcon} style=" transform: rotate(${bearing}deg);" >
      </div>
    </div>
`;
export default hereMarkerIcon
