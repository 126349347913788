import React, {useEffect} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";

import "./App.css";
import "./Registration.css";
import store, {RootState} from "../../redux/store";
import {MainScreen} from "../components";
import {loadInitDataThunk} from "../../redux/reducers/system/system_functions";
import MessageWindow from "../Elements/MessageWindow/MessageWindow";
import {
  MessageType
} from "../../redux/reducers/system/system_types";
import {useCookies} from "react-cookie";
import {cookiesAccountId, cookiesClientId, cookiesClientPhone, cookiesOrderId} from "../../deployment";


const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  let state = useSelector((state: RootState) => (state));
  let {message, data_loading} = useSelector((state: RootState) => state.systemReducer);
  const [cookies, setCookie] = useCookies([ cookiesClientId,cookiesOrderId,cookiesAccountId, cookiesClientPhone ]);

  //@ts-ignore
  window.state = state;
  useEffect(() => {
    loadInitDataThunk(dispatch, cookies ,setCookie);
  }, []);

  return (
    <>
        <MainScreen  key="wnd_MainScreen"/>
       { (message.msg_type !== MessageType.None || data_loading) && <MessageWindow/> }
    </>
  )
};

const Container: React.FunctionComponent = (props) => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
export default Container;

