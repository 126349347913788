import React from "react";
import CargoItem from "./CargoItem/CargoItem";

import "./ListCargo.css"
import {CargoTypes, IPackInfo} from "../../../../redux/reducers/slices/CreateOrderType";

interface IProps {
    list: CargoTypes[];
    total: IPackInfo|null;
    onDelete?:(item : CargoTypes,index:number) => void
    onEdit?:(item : CargoTypes,index:number) => void
}
const ListCargo: React.FC<IProps> = ({list,total, onDelete, onEdit}) =>{
    console.log('total', total, typeof total);
    const  deleteItem = (item : CargoTypes,index:number)=>{
        if (onDelete) onDelete(item,index);
    };
    const  editItem = (item : CargoTypes,index:number)=>{
        if (onEdit) onEdit(item,index);
    };

    return (
        <div className="list-cargo">
            {
                list.map( (x,index)=>
                    <CargoItem key={"ci_"+index} value={x}
                               onDelete={()=> deleteItem(x, index)}
                               onEdit={()=> editItem(x, index)}
                    />).reverse()
            }
            <div className="list-cargo__total">
                {total ?
                    <div className="list-cargo__total-items">
                        <div>
                            <div className="cargo-item__size-title left">Общий объем</div>
                            <div className="cargo-item__size-title-value">{total?.total_volume} м³</div>
                        </div>
                        <div>
                            <div className="cargo-item__size-title">Общая площадь</div>
                            <div className="cargo-item__size-title-value">{total?.total_area} м²</div>
                        </div>
                        <div>
                            <div className="cargo-item__size-title right">Общий вес</div>
                            <div className="cargo-item__size-title-value">{total?.total_weight} кг</div>
                        </div>
                    </div> : ''
                }
            </div>
        </div>

    )
};

export default ListCargo;
