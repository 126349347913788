import React, { useEffect, useState } from 'react'
import {
  AddressSuggestions,
  DaDataAddress,
  DaDataSuggestion
} from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import {daDataToken} from "../../../deployment";
import { ClearIcon } from '../../Elements/icons/ClearIcon';
interface IProps{
  placeholder:string,
  onChange:(value:any)=>void,
  defaultValue:string
}
export const ProfileAddress = ({  placeholder,  onChange, defaultValue }: IProps) => {
  const [value, setValue] = useState<
    DaDataSuggestion<DaDataAddress> | undefined
  >(undefined)
  let ref = React.createRef<AddressSuggestions>()
  
  useEffect(() => {
    ref.current?.setInputValue(value?.value || defaultValue)
  }, [defaultValue, value, ref])

  const onChangeHandler = (
    selected: DaDataSuggestion<DaDataAddress> | undefined
  ) => {
    setValue(selected)
    onChange(selected?.value)
    ref.current?.setInputValue(selected?.value)
  }
  const clear = () => {
    onChangeHandler(undefined)
  }
  return (
    <div className="styled-address">
      <AddressSuggestions
        ref = {ref}
        token={daDataToken}
        value={value}
        onChange={onChangeHandler}
        inputProps={{ placeholder: placeholder || 'Адрес' }}
        customInput={StyledInnInput}
        defaultQuery={defaultValue}
      />
      <ClearIcon onClick={() => clear()} />
    </div>
  )
}

export const StyledInnInput = () => <input />;
//
// `
//   width: 100%;
//   font-family: 'Fira Sans', sans-serif;
//   color: #000;
//   background: #fff;
//   font-size: 17px;
//   font-weight: normal;
//   border: none;
//   padding: 14px 16px;
//
//   &:active,
//   &:focus {
//     border: none;
//     outline: none;
//     box-shadow: none;
//   }
// `;
