import {IIconProps} from "./SvgIconsType";


export const ArrowRightIcon = ({ onClick,className }: IIconProps) => {
  return (
    <svg
      cursor="pointer"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick && onClick()}
      className={className}
    >
      <path
        d="M1 9L5 5L1 1"
        stroke="#888E99"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
