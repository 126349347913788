import axios, { AxiosResponse } from "axios";
import {hereMapApiKey} from "../deployment";

const host = window.location.host
  .replace("www.", "")
  .split(".")
  .reverse()
  .splice(0, 2)
  .reverse()
  .join(".");


const instance = axios.create({
  baseURL: "https://revgeocode.search.hereapi.com/v1",
});

export const hereComAPI = {
  getAddressByCoords(lat:number, lon:number, limit = 5) {
    return instance
      .get(
        `/revgeocode?xnlp=CL_JSMv3.1.21.3&apikey=${hereMapApiKey}&limit=${limit}&at=${lat},${lon}&lang=ru`
      )
      .then((response) => response)
      .catch((error) => error);
  },



  async getAddressByName(q:string, limit = 10) {
    return axios
      .get(
        `https://geocode.search.hereapi.com/v1/geocode?xnlp=CL_JSMv3.1.23.0&apikey=${hereMapApiKey}&lang=ru&q=${q}&limit=${limit}&at=55.75602%2C37.61882&in=countryCode%3ARUS`
      )
      .then((response) => response)
      .catch((error) => error);
  },

  getOneAddressByCoords(point :{lng:number,lat:number}) {
    return axios.get(`https://revgeocode.search.hereapi.com/v1/revgeocode?xnlp=CL_JSMv3.1.30.3&apikey=${hereMapApiKey}&at=${point.lng}%2C${point.lat}&limit=1`)
        .then(response => response)
        .catch(error => error);
  }
};

