import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { mapMarker, mapRoute } from "./hereMapTypes";
import {IRouteByOrderId} from "../../../components/Forms/JournalOrdersForm/Order.types";
import {TypeLonLat} from "./CreateOrderType";

export const hereMapSelector = (state: RootState) => state.hereMapReducer;

export interface HomeState {
  markers: mapMarker[]
  routes: mapRoute[]
  orderRouteWithTime: IRouteByOrderId | undefined
  car_position: TypeLonLat | undefined
}

const initialState: HomeState = {
  markers: [new mapMarker(), new mapMarker()],
  routes: [],
  orderRouteWithTime: undefined,
  car_position: undefined
};

export const hereMapSlice = createSlice({
  name: "hereMap",
  initialState,
  reducers: {
    setMarkers(state, action) {
      state.markers =  [...action.payload]
    },
    setClearMarkers(state) {
      state.markers = []
    },
    setRoutes(state, actions) {
      state.routes = [...actions.payload]
    },
    setClearRoutes(state) {
      state.routes = [];
      state.orderRouteWithTime = undefined;
    },
    setOrderRouteWithTime(state, action) {
      state.orderRouteWithTime = action.payload
    },
    setCarPosition(state, actions) {
      state.car_position =  actions.payload
    }
  },
});

export const {
  setMarkers,
  setClearMarkers,
  setRoutes,
  setClearRoutes,
  setOrderRouteWithTime,
  setCarPosition
} = hereMapSlice.actions;

export default hereMapSlice.reducer;
