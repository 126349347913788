
interface IPointProp {
  color?: string
  width?: string
  height?: string
  className?:string
}

export const PointIcon = ({ color, width, height,className }: IPointProp) => {
  return (
    <svg
      width={width || '10%'}
      height={height || '10%'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='8' cy='8' r='6' stroke={color || '#FF9900'} strokeWidth='4' />
    </svg>
  )
}
