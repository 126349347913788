import React, {FC} from "react";
import './JournalOrderItem.css'
import '../../../Elements/SelectCarManual/AutoCarSelectCard/AutoCarSelectCard.css'
import {EnumOrderListFilterType, IOrder} from "../Order.types";
import moment from "moment";
import {PointIcon} from "../../../Elements/SvgIcons/PointIcon";
import {getCategoryImage} from "../../../../redux/reducers/system/system_functions";
import { ReactSVG } from "react-svg";
import {Puff} from "react-loader-spinner";

interface IProps {
    filterType: EnumOrderListFilterType;
    order:IOrder;
    onClick:()=>void;
    isLoading?:boolean;
}

export const JournalOrderItem:FC<IProps> = ({order, onClick,filterType, isLoading=true}) => {

    // const category = getCategorySvg(categories, carTypeId)
    return (
        <div className="journal-order-item" onClick={onClick}>
            <div className="journal-order-item__header">
                <div className="journal-order-item__header-period">
                    {`c ${moment(order.date_from).format('HH:mm')} до ${moment(order.date_to).format( 'HH:mm')}`}
                </div>
                <PointIcon color={ filterType !== EnumOrderListFilterType.Today ? '#999'  : undefined } />
                <div className="journal-order-item__header-address">
                    {order.adress.length>0 ? order.adress[0] : 'Адрес не указан'}
                </div>
                <div className="journal-order-item__header-address">
                    {order.adress.length>0 ? order.adress[order.adress.length-1] : 'Адрес не указан'}
                </div>
            </div>
            <div className="journal-order-item__content">
                <div className="journal-order-item__content-img car-description__image">

                       <ReactSVG src={getCategoryImage(order.car_type_id)} />

                </div>
                <div className="journal-order-item__content-status">{order.status}</div>
                <div className="journal-order-item__content-amount">
                    {`${order.amount.toLocaleString('ru').replace(',', '.')} ₽`}
                </div>
            </div>
            {isLoading && <div className="journal-order-item__loading"><Puff color={ "#00bb61"} width={"5rem"} height={"5rem"} /></div>}
        </div>
    )
}
