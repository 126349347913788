import { ReactSVG } from "react-svg";
import cn from "classnames";
import chatIcon from "../../../App/svg/chat-btn-ico.svg";
import phoneIcon from "../../../App/svg/phone-btn-ico.svg";
import starIcon from "../../../App/svg/star.svg";
import cameraIcon from "../../../App/svg/camera.svg";
import packageIcon from "../../../App/svg/package.svg";
import optionIcon from "../../../App/svg/option.svg";
import "./OrderInfo.css";
import React, {useEffect, useState} from "react";

import {CTariffData, RouteAddress} from "../../../../redux/reducers/slices/CreateOrderType";
import {
  BodyOptionCharacteristicsResponse, EnumOrderStatusID,
  IBodyOptions,
  IFullCategory
} from "../../../../redux/reducers/system/system_types";
import {
  getActiveCharacteristics,
  getBodyOptionsList,
  getCategoryImage, showErrorMessage
} from "../../../../redux/reducers/system/system_functions";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setDataLoading, setShowMenu, systemSelector} from "../../../../redux/reducers/system/system_reducers";
import { CloseIcon } from "../../../Elements/CloseIcon/CloseIcon";
import { Address } from "../../../Elements/AddressPoints/Address/Address";
import { Tariff } from "../../../Elements/SelectTariff/Tariff/Tariff";
import Modal from "../../../Elements/Modal/Modal";
import {serverAPI} from "../../../../api/server";
import QuestionWindow from "../../QuestionWindow/QuestionWindow";
import {setClearMarkers, setClearRoutes, setMarkers, setOrderRouteWithTime, setRoutes } from "../../../../redux/reducers/slices/hereMapSlice";
import {getRoutePointAsMarkers, setOrderData, setOrderDataAuto, setOrderDataManual } from "../../../../redux/reducers/slices/CreateOrderSlicer";

import {IPointRoute, IRouteByOrderId} from "../Order.types";
import {EnumMarkerType, mapMarker, mapRoute} from "../../../../redux/reducers/slices/hereMapTypes";
import moment from "moment";

enum EnumActionType{Repeat, Remove, Change};

interface OrderInfoProps {
  type?: "disbled" | "active" | "notActive";
  order: IGetOrderInfo;
  tariff: any;
  onButtonClick: any;
  onChatClick: any;
  onCallClick: any;
  onCloseClick: any;
}

export interface IGetOrderInfo {
  id: string;
  draft: boolean;
  max_dimensions: boolean;
  date: string;
  contacts: orderContacts;
  comment: string;
  body_option_id: string;
  body_type_id: number;
  tariff_type_id: string;
  car_type_id: string;
  additional_requirements: orderAdditionalRequirements[];
  body_option_characteristics: orderBodyOptionCharacteristics[];
  routes: RouteAddress[];
  cargo: {
    places: any[];
    pallets: any[];
    packages: any[];
  };
  vehicle: orderVehicle;
  driver: {
    rating: any;
    full_name: string;
    phone: string;
    passport: {
      series: string;
      number: string;
      issued_by: string;
      issue_date: string;
      files: string[];
    };
    license: {
      series: string;
      number: string;
      issue_date: string;
      issued_by: string;
      files: string[];
    };
  };
  number: string;
  status: string;
  status_id: number;
  amount: number;
  distance: number;
}
interface orderContacts {
  full_name: string;
  phone: string;
  email: string;
}
interface orderVehicle {
  model: string;
  number: string;
  car_type_id: string;
  body_option_id: string;
  length: number;
  width: number;
  height: number;
  volume: number;
  max_weight: number;
}
interface orderAdditionalRequirements {
  id: string;
  value: string;
}
interface orderBodyOptionCharacteristics {
  id: string;
  value: string;
}


interface categoryBodyOptionCharacteristics {
  name: string;
  type: string;
  body_option_characteristics_values: any[];
  id: string;
  value?:string;
}

interface addition {
  name: string;
  type?: string;
  exclude?: any[];
  id: string;
}

export const OrderInfo: React.FC<OrderInfoProps> = ({ type,  order,   onButtonClick, onChatClick,  onCallClick, onCloseClick }) => {
    const dispatch = useAppDispatch();
    const { categories, tariff_types, additional } = useAppSelector(systemSelector);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isLoadingTariff, setLoadingTariff] = useState(false);
    const [tariffData, setTariffData] = useState<any>(undefined);
    const [isShowQuestion, setShowQuestion ] = useState<{title:string, action_type: EnumActionType} | undefined>(undefined);

    const formatTime = (value: string) => {
      return value[0] === "0" ? value.substring(1, 5) : value.substring(0, 5);
    };

    const orderDateTime = order.date.split("T");
    const orderDate = new Date(orderDateTime[0]).toLocaleDateString("ru-RU", {
      year: "2-digit",
      month: "long",
      day: "numeric",
    });
    const orderTimeFrom = formatTime(orderDateTime[1]);
    const orderTimeTo = formatTime(orderDateTime[2]);

    const [modalProps, setModalProps] = useState({
      title: "",
      images: [],
    });
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {     setShowModal(false); };

    // const constructMarkers = (points: IPointRoute[]) => {
    //   const markers: mapMarker[] = [];
    //
    //   points && Array.isArray(points) &&
    //   points.forEach((item, index) => {
    //     markers.push({
    //       lon: item.lng,
    //       lat: item.lat,
    //       type: index === 0 ? 4 : 3,
    //       index: index,
    //       title: moment(item.arrival_traffic_time).format("HH:mm"), //getTitleForMarker(index, order.date),
    //       text: "",
    //       is_show_text: false,
    //       bg_color: index === 0 ? "var(--orange)" : "blue",
    //     });
    //   });
    //   return markers;
    // };

    const loadOrderData = async () => {
      dispatch(setDataLoading(true));
      setLoadingTariff(true);
      let res : any = await serverAPI.calc(order as any);
      setLoadingTariff(false);
      if (!res || (res.status && res.status === 'error') || !Array.isArray(res)) {
          dispatch(setDataLoading(false));
          return showErrorMessage('Ошибка загрузки информации о тарифе', dispatch);
      }

      let data = res as CTariffData[];
      let t = data.find(x=>x.tariff_type_id === order.tariff_type_id);
      setTariffData(t);

      let rts  : any = await serverAPI.getRoutesByOrderId(order.id);
      dispatch(setDataLoading(false));
      if (!rts || !rts.data ) return showErrorMessage('Ошибка загрузки маршрута заказа', dispatch);
      if (rts.data.status === 'error' ) return showErrorMessage(rts.data.error_message, dispatch);
      let routeData = rts.data  ;
      dispatch(setOrderRouteWithTime(routeData));

      dispatch(setMarkers(getRoutePointAsMarkers(order, EnumMarkerType.MainNotDraggable)));
      let tmp_route = [];
      for (let i = 1; i < routeData.points.length; i++) {
        let route: mapRoute = new mapRoute();
        route.type = 1;
        route.coordinate = routeData.points[i].shape.map((x : string) => ({lon: +x.split(',')[1], lat: +x.split(',')[0]}));
        tmp_route.push(route);
      }
      dispatch(setRoutes(tmp_route));
    };

    const onCloseForm = () => {
        dispatch(setOrderRouteWithTime(undefined));
        dispatch(setClearRoutes());
        dispatch(setClearMarkers());
        onCloseClick();
    }

    useEffect(()=>{loadOrderData();},[]);

    let orderCategory : IFullCategory | undefined = categories.find( (item: any) => item.id === order.car_type_id  );
    if (!orderCategory) return (<div>Ошибка, не удалось загрузить список категорий!</div>);

    const orderBodyOption = orderCategory.body_options.filter((item: any) => item.id === order.body_option_id)[0];
    if (!orderBodyOption) return (<div>Ошибка, не удалось определить тип кузова!</div>);
    let  activeCharacteristics  = getActiveCharacteristics(order.body_option_characteristics, categories);

    let additionals: addition[] = additional.filter(x=>  order.additional_requirements.some(z=> z.id === x.id && z.value !== "false")  );

    const  isOrderCompleted = () => order && (order.status_id === EnumOrderStatusID.Completed ||order.status_id === EnumOrderStatusID.Canceled  );
    const  isOrderNotStarted = () => order && (order.status_id === EnumOrderStatusID.DriverSearching || order.status_id === EnumOrderStatusID.DriverFound);
    const getTitleRemove = ()  => {
      if (!order || order.status_id == EnumOrderStatusID.DriverSearching) return 'Вы уверены что хотите удалить выбранный заказ ?';
      return  'Вы уверены что хотите удалить выбранный заказ ? Стоимость отмены заказа составляет 500 р.';
    };
    const getTitleChange = ()  => {
      if (!order || order.status_id == EnumOrderStatusID.DriverSearching)
        return `Вы уверены что хотите изменить выбранный заказ ?<br/><br/> Заказ №${(order ? order.number : '')} будет отменен и на его основе будет создан новый заказ`;
      return  `Вы уверены что хотите изменить выбранный заказ ?<br/><br/>
                   Заказ №${(order ? order.number : '')} будет отменен и на его основе будет создан новый заказ<br/><br/>
                   Стоимость отмены заказа составляет 500 р.`;
    };

    const getCargoVolume = () => {
      let vol = 0;
      order.cargo.places.forEach((item ) => vol += item.size.length * item.size.width * item.size.height);
      order.cargo.pallets.forEach((item ) => vol += item.quantity * item.size.length * item.size.width * item.size.height);
      order.cargo.packages.forEach((item ) => vol += item.quantity * item.size.length * item.size.width * item.size.height);
      return vol.toFixed(2)
    };

    const getCargoArea = () => {
      let vol = 0;
      order.cargo.places.forEach((item ) => vol += item.size.length * item.size.width  );
      order.cargo.pallets.forEach((item ) => vol += item.quantity * item.size.length * item.size.length  );
      order.cargo.packages.forEach((item ) => vol += item.quantity * item.size.length * item.size.length  );
      return vol.toFixed(2)
    };

    const getCargoWeight= () => {
      let vol = 0;
      order.cargo.places.forEach((item ) => vol +=  item.size.weight  );
      order.cargo.pallets.forEach((item ) => vol += item.quantity * item.size.weight  );
      order.cargo.packages.forEach((item ) => vol += item.quantity * item.size.weight  );
      return vol.toFixed(2)
    };

    const onOrderAction = async () =>{

      dispatch(setDataLoading(true));
      setShowQuestion(undefined);


      if (order && (isShowQuestion?.action_type == EnumActionType.Change || isShowQuestion?.action_type == EnumActionType.Remove)) {
        let data : any = await  serverAPI.deleteOrder(order.id);
        if (!data) return showErrorMessage("Не удалось удалить заказ, АПИ еще не реализовано", dispatch);
        if (data.status === 'error') showErrorMessage(data.error_message, dispatch);
        dispatch(setClearRoutes());
        dispatch(setClearMarkers());
      }


      if (order && (isShowQuestion?.action_type == EnumActionType.Repeat || isShowQuestion?.action_type == EnumActionType.Change)) {

        let tmp: any = {...order, draft: true, id:''};
        if (order.cargo.places.length || order.cargo.pallets.length || order.cargo.packages.length)
          await dispatch(setOrderDataAuto(tmp));
        else
          await dispatch(setOrderDataManual(tmp));

        dispatch(setOrderData(tmp));
        dispatch(setMarkers(getRoutePointAsMarkers(tmp)));
        if (tmp.routes.length < 2){
          dispatch(setRoutes([]));
        } else {
          let route = new mapRoute();
          route.coordinate = tmp.routes.map( (x:any) => ({ lon: x.adress_longitude, lat: x.adress_latitude  }) );
          route.type = 0;
          dispatch( setRoutes([route]))
        }
      }
      dispatch(setDataLoading(false));
      dispatch(setShowMenu(false));
    };
    return (
      <>
        <CloseIcon  onClick={onCloseForm} className="menu-form-close"/>
        <div className="expanded-order-info">
          <div className="expanded-order-info__header order-info-header">
            <div className="order-info-header__status">
              Заказ {order.status.toLowerCase()}
            </div>
            <div className="order-info-header__time">
              {orderDate} с {orderTimeFrom} по {orderTimeTo}
            </div>
          </div>
          <div className="expanded-order-info__route route-info">
            {order &&
              order.routes.map((item, index) => (
                <div className="route-info__item">
                  <Address
                    key={'adrs_'+index}
                    type={"info"}
                    value={item}
                    isSelected={false}
                    onFocus={(value)=>{  }}
                    onExpand={(value)=>{  }}
                    disableinput={true}
                    onChange={()=>{}}
                  />

                </div>
              ))}
          </div>
          <div className="expanded-order-info__vehicle vehicle-info">
            <div className="vehicle-info__row car-description__image">
              <ReactSVG src={getCategoryImage(order.car_type_id)}  className="vehicle-info__image"/>
              {/*<ReactSVG src={category7} className="vehicle-info__image" />*/}
              <div className="vehicle-info__model">
                <p>{order.vehicle?.model}</p>
                {order.vehicle?.number}
              </div>
              <div  className={cn("vehicle-info__body-type", { "body-type-active": !showDropdown, })} >
                <div>
                  <p>{orderBodyOption.name}</p>
                  {orderCategory.name}
                </div>
                <ReactSVG  src={optionIcon} className="vehicle-info__body-type--toggler"  onClick={() => setShowDropdown((prevState) => !prevState)}/>
              </div>
            </div>
            <div  className={cn("vehicle-info__body-type--dropdown", {  "body-type--dropdown-show": showDropdown, })} >
              <div className="vehicle-info__body-prop">
                <p>Длина</p>
                {order.vehicle?.length} м
              </div>
              <div className="vehicle-info__body-prop">
                <p>Ширина</p>
                {order.vehicle?.width} м
              </div>
              <div className="vehicle-info__body-prop">
                <p>Высота</p>
                {order.vehicle?.height} м
              </div>
              <div className="vehicle-info__body-prop">
                <p>Объем</p>
                {order.vehicle?.volume} м³
              </div>
              <div className="vehicle-info__body-prop">
                <p>Макс. Масса</p>
                <b>{orderCategory.weight_to} кг</b>
              </div>
            </div>
            <div className="vehicle-info__body-options">
              {activeCharacteristics.filter((x:BodyOptionCharacteristicsResponse)=> x.value !== 'false').map((item:any) => (
                <div key={item.id} className="vehicle-info__body-option">
                  {item.name}
                  {(item as any).value}
                </div>
              ))}
            </div>
            <div className="vehicle-info__total">

              <ReactSVG
                src={packageIcon}
                className="vehicle-info__total--icon"
              />
              <div className="vehicle-info__total--item">
                <p>Общий объем</p>
                {getCargoVolume()}
                м³
              </div>
              <div className="vehicle-info__total--item">
                <p>Общая площадь</p>
                { getCargoArea()}
                м²
              </div>
              <div className="vehicle-info__total--item">
                <p>Общий вес</p>
                {getCargoWeight()} кг
              </div>
            </div>
          </div>
          <div className="expanded-order-info__driver driver-info">
            <div className="driver-info__row">
              <div className="driver-info__name">
                <p>Имя водителя</p>
                {order.driver?.full_name ? order.driver?.full_name : 'водитель не назначен'}
              </div>
              <div className="driver-info__rating">
                <p>Рейтинг</p>
                <div className="driver-info__rating--value">
                  <ReactSVG src={starIcon} />
                  <b>4.9</b>
                </div>
              </div>
            </div>
            <div className="driver-info__row">
              <div className="driver-info__passport driver-info__document">
                <ReactSVG
                  src={cameraIcon}
                  onClick={() => {
                    setModalProps({ title: "Паспорт", images: [] });
                    setShowModal(true);
                  }}
                />
                <div className="driver-info__document--txt">
                  <p>Паспорт</p>
                  {order.driver?.passport.number}
                </div>
              </div>
              <div className="driver-info__license driver-info__document">
                <ReactSVG
                  src={cameraIcon}
                  onClick={() => {
                    setModalProps({
                      title: "Водительское удостоврение",
                      images: [],
                    });
                    setShowModal(true);
                  }}
                />
                <div className="driver-info__document--txt">
                  <p>Вод. удостоврение</p>
                  {order.driver?.license.number}
                </div>
              </div>
            </div>
          </div>

          <div className="expanded-order-info__buttons">
            {order.status_id < EnumOrderStatusID.Completed &&
              <>
                <button  onClick={onChatClick} className="expanded-order-info__chat-button" >
                  <ReactSVG src={chatIcon} />
                  Открыть чат
                </button>
                <button  onClick={onCallClick} className="expanded-order-info__call-button" >
              <ReactSVG src={phoneIcon} />
              Позвонить
            </button>
              </>
            }

            {order.status_id > EnumOrderStatusID.Executing &&
                <>
                  <button  onClick={onChatClick} className="expanded-order-info__chat-button completed " >
                    <ReactSVG src={chatIcon} />
                    Архив чата
                  </button>
                </>
            }
          </div>
          <div className="expanded-order-info__additional">
            {additionals.map((item) => (
                <div key={item.id} className="expanded-order-info__additional-item">
                  {item.name}
                </div>
            ))}
          </div>
          <div className="expanded-order-info__tariff">
            <Tariff
              loading={isLoadingTariff}
              data={tariffData}
              onSelect={(tariff_type_id) => {
                console.log("tariff_type_id: ", tariff_type_id);
              }}
              selected={true}
            />
          </div>
          {isOrderCompleted() &&
            <div className="expanded-order-info__button-container">
              <button className={"expanded-order-info__button blue"}
                      onClick={() => setShowQuestion({title:'Вы дейстивтельно хотите повторить данный заказ ?', action_type:EnumActionType.Repeat})}>
                      Повторить заказ
              </button>
              <div className="expanded-order-info__back-link" onClick={onCloseForm}>вернуться назад</div>
            </div>
          }

          {isOrderNotStarted() &&
            <div className="expanded-order-info__button-container">
                <div className="expanded-order-info__buttons">
                  <button className="expanded-order-info__button red-gradient"
                          onClick={() => setShowQuestion({title:getTitleRemove(), action_type:EnumActionType.Remove})}>
                    Отменить заказ
                  </button>
                  <div>&nbsp;&nbsp;</div>
                  <button className={"expanded-order-info__button violet"}
                          onClick={() => setShowQuestion({title:getTitleChange(), action_type:EnumActionType.Change})}>
                    Изменить заказ
                  </button>
                </div>
                <div className="expanded-order-info__back-link" onClick={onCloseForm}>вернуться назад</div>
            </div>
          }
        </div>
        <Modal props={modalProps} showModal={showModal}  closeModal={closeModal} />
        {isShowQuestion &&  <QuestionWindow onYes={onOrderAction} onNo={()=>setShowQuestion(undefined)} title={isShowQuestion.title} />}
      </>
    );

};
