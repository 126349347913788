import {
    DATA_LOADING,
    IFullCategory,
    MessageType, SET_ADDITIONAL_REQUIREMENTS,
    SET_CATEGORIES,
    SET_PACKAGE_TYPES,
    SET_PALLET_TYPES, SET_SHOW_MENU,
    SET_TARIFF_TYPES, SetAdditionalRequirementsAction,
    SetBaseTariffTypesAction,
    SetCategoriesAction,
    SetDataLoadingAction,
    SetPackageTypesAction,
    SetPalletTypesAction, SetShowMenuAction,
    SetSystemMessageAction,
    SYSTEM_MESSAGE,
    SystemActions,
    SystemState
} from "./system_types";
import {IAdditionalRequirements, IPackageType, IPalletType, ITariffInfo} from "../slices/CreateOrderType";
import {RootState} from "../../store";
import {useState} from "react";

export const setDataLoading = (value:boolean) : SetDataLoadingAction => ({type: DATA_LOADING, value});
export const setSystemMessage = (msg:{msg_type: MessageType, message: string }) : SetSystemMessageAction => ({type: SYSTEM_MESSAGE, msg});
export const clearSystemMessage = () => ({type: SYSTEM_MESSAGE, msg:{msg_type: MessageType.None, message:''}});
export const setCategories = (categories:IFullCategory[]) : SetCategoriesAction => ({type: SET_CATEGORIES, categories});
export const setBaseTariffTypes = (tariff_types:ITariffInfo[]) : SetBaseTariffTypesAction => ({type: SET_TARIFF_TYPES, tariff_types});
export const setPalletTypes = (pallet_types:IPalletType[]) : SetPalletTypesAction => ({type: SET_PALLET_TYPES, pallet_types});
export const setPackageTypes = (package_types:IPackageType[]) : SetPackageTypesAction => ({type: SET_PACKAGE_TYPES, package_types});
export const setAdditionalRequirements  = (additional:IAdditionalRequirements[]) : SetAdditionalRequirementsAction => ({type: SET_ADDITIONAL_REQUIREMENTS, additional});
export const setShowMenu  = (is_show_menu:boolean) : SetShowMenuAction => ({type: SET_SHOW_MENU, is_show_menu: is_show_menu});

export const systemSelector  = (state: RootState) => state.systemReducer;



let initialState : SystemState = {
    data_loading: false,
    message:{msg_type:MessageType.None, message:''},
    categories:[],
    tariff_types: [],
    pallet_types: [],
    package_types: [],
    additional:[],
    is_show_menu:false,
};

const systemReducer = (state = initialState, action : SystemActions) => {
    switch (action.type) {


        case DATA_LOADING:
            return { ...state,  data_loading: action.value };

        case SYSTEM_MESSAGE:
            return { ...state,  message: action.msg };

        case SET_CATEGORIES:
            return { ...state,  categories: action.categories };

        case SET_TARIFF_TYPES:
            return { ...state, tariff_types: action.tariff_types };

        case SET_PALLET_TYPES:
            return { ...state, pallet_types: action.pallet_types };

        case SET_PACKAGE_TYPES:
            return { ...state, package_types: action.package_types };

        case SET_ADDITIONAL_REQUIREMENTS:
            return { ...state, additional: action.additional };

        case SET_SHOW_MENU:
            return { ...state, is_show_menu: action.is_show_menu };
        default:
            return state;
    }
};

export default systemReducer;
