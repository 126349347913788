import React, {FC,  useEffect, useState} from 'react'
import "./OrderAdditionalRequirements.css"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {DropDownListItem} from "../../../redux/reducers/system/system_types";
import {currentOrderData, getTariffInfo, setOrderAdditional} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {SendOrderInfo} from "../../../redux/reducers/slices/CreateOrderType";
import {systemSelector} from "../../../redux/reducers/system/system_reducers";
import {AutoDropDownGreyList} from "../SelectCarManual/AutoDropDownGreyList/AutoDropDownGreyList";
import {AutoGreyButton} from "../SelectCarManual/AutoGreyButton/AutoGreyButton";


export const OrderAdditionalRequirements: FC<{}> = ({}) => {

    const dispatch = useAppDispatch()
    const CargoManList : DropDownListItem[] = [
        {id:'', text:'не нужны'},
        {id:'1', text:'водитель-грузчик'},
        {id:'2', text:'2 грузчика'},
        {id:'3', text:'3 грузчика'},
        {id:'4', text:'4 грузчика'},
    ]

    const { categories, tariff_types, additional } = useAppSelector(systemSelector),
            order :SendOrderInfo = useAppSelector(currentOrderData) ;


    useEffect(() => {
        getTariffInfo(order, dispatch, categories, tariff_types )
    }, [order.additional_requirements]);

    const getCargoManValue = () => {
         let v = order.additional_requirements.find(z=> z.id == 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942');
         if (v) return v.id;
         return '-1';
    }


    const onSelectCargoMan = (item: DropDownListItem) => {

        let additional = order.additional_requirements.filter(x => x.id != 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942');
        additional = [...additional];

        console.log('item', item, additional);
        if (item.id != '') {
            if (item.id != '1') {
                additional = [...additional, {id: 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942', value:  +(+item.id - 1)}]
            }
            additional = additional.filter(x => x.id != 'aa507685-21df-11ea-a9ad-00155d8e4e05');
            additional = [...additional, {id: 'aa507685-21df-11ea-a9ad-00155d8e4e05', value: true}]
        }
        console.log('item', item, additional);
        dispatch(setOrderAdditional(additional));
    };

    const updateAdditional = (item: {id:string, value: any}) => {
        let index = order.additional_requirements.findIndex(z=> z.id == item.id);
        let additional = index!=-1 ? order.additional_requirements.filter(x=> x.id != item.id) : [... order.additional_requirements, item];
        if (item.id == 'aa507685-21df-11ea-a9ad-00155d8e4e05' && index!=-1) {
            additional =  additional.filter(x=> x.id != 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942')
        }
        dispatch(setOrderAdditional(additional));
    }

    return (
        <>
            <div className='additionalRequirements'>
                <AutoDropDownGreyList type={'blue'} onSelect={onSelectCargoMan} list={CargoManList} title={'Грузчики'}
                                       value={ getCargoManValue()}
                                        isFixed = {true}
                                       showTitleWhenSelect={false}
                                       isSelected={order.additional_requirements.some(z=> z.id == 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942')} />

                {additional.filter( ( x )=> x.id !== 'c01ac72c-fcf4-11e7-9ae7-e41f13c2b942')

                    .map( x =>
                        <AutoGreyButton key={x.id} type={'blue'}
                                       isSelected={order.additional_requirements.findIndex(z=> z.id == x.id && z.value == true) != -1}
                                        onClick={() => updateAdditional({id:x.id, value: true})} >{x.name}</AutoGreyButton>)}
            </div>
        </>
    )
}
