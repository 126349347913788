import React, {ChangeEventHandler, useEffect, useRef, useState} from "react";
import "./LabelInput.css"


export interface IValue {
    value : string | number;
}
interface IProps {
    type:"text" | "number";
    unit:string;
    title:string;
    value?:   string | number;
    onChange?:(val: string|number)=>void;
    clear?:object;
    tabindex?:number;

}
export const LabelInput: React.FC<IProps> = ({type,unit, title,value ="" , onChange, clear,tabindex}) =>{
    let refInput = useRef<HTMLInputElement>(null);

    let [localState, setLocalState] = useState<string>('' + ( +(value ) == 0 ? '' : value));
    let [hasValue, setHasValue] = useState<boolean>(false);
    const prevValue  = useRef({ clear }).current;

    let [error, setError] = useState<string>('');
    const handleChange = (event :  any) =>{
        //console.log(event, event.target.value);
        let val = event.target.value.replace(unit, '');
        if (type === "number" ){
            if(!isNaN(+(val)))
               setLocalState(val == '0' ? unit : val + unit);
            else
                setPosition()
        } else
            setLocalState(val + unit);

    };
    const  setPosition = () =>{
        if (refInput.current) {
            refInput.current.focus();
            refInput.current.selectionStart =  refInput.current.value.length - unit.length;
            refInput.current.selectionEnd = refInput.current.value.length - unit.length;
        }
    };
    useEffect(()=>{
        if ( prevValue.clear !== clear) {
            console.log('clear data ');
            setLocalState('' + ( +(value ) == 0 ? '' : value));
        }

        return () => {
            prevValue.clear = clear;
        }

    }, [clear]);

    useEffect(()=>{
        setPosition();
        setTimeout(()=> setPosition(), 100);
        if (onChange) {
            if (unit === ' кг') {
                console.log(' кг' , value, localState);
            }
            let val = localState.replace(unit,'');
            if (type === "number" )
                onChange( isNaN(+val) ? 0 : +val );
            else
                onChange(val);
        }
    }, [localState]);
    return (
        <div className={"label-input " + (hasValue ? 'white-bg' : '')}>
            <div  className={"label-input__title " + (localState   ? 'small_title' :'')}
                  onClick={() => handleChange( {target :{value:'0'}}) }>{title}</div>
            {localState && <div><input
                ref={refInput}
                type={"text"}
                onChange={handleChange}
                value={localState}
                tabIndex={tabindex}
                onBlur ={(e)=>setHasValue( !!localState.replace(unit,''))}
            /></div>}
            <div>{error}</div>
        </div>
    )
};

export default  LabelInput;
