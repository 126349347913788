import axios, { AxiosResponse } from "axios";
import axiosRetry from 'axios-retry';
import {apiOrderUrl} from "../deployment";

import {IBOCharacteristics} from "../redux/reducers/system/system_types";
import {CargoInfo, SendOrderInfo} from "../redux/reducers/slices/CreateOrderType";
import {CUserProfile} from "../redux/reducers/slices/userProfile.type";

axiosRetry(axios, {retries: 50});

const instance = axios.create({
    baseURL: apiOrderUrl,
});

/*


export const vehicleAPI = {

    count:0,
    async getBodyTypesOnce() {
        this.count++;
        return instance.get('/body-types')
            .then(response => {
                this.count--;
                if (!response || !response.data || response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--;return error;});
    },
    async getCategoriesFull() {
        this.count++;
        return instance.get('/car-types-tree')
            .then(response => {
                this.count--;
                if (!response || !response.data || response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--; console.error(error); return null;});
    },
    getBodyTypes(categoryId) {
        this.count++;
        return instance.get('/body-types' + (categoryId * -1 !== 0 ? '?car_type_id=' + categoryId : ''))
            .then(response => {
                this.count--;
                if (response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--;return error;});
    },
    getBodyOptions(bodyTypeId, categoryId = 0, data = {}) {
        this.count++;
        return instance.post('/body-options?' +
            (bodyTypeId * -1 !== 0 ? 'body_type_id=' + bodyTypeId : '1') +
            (categoryId * -1 !== 0 ? '&car_type_id=' + categoryId : '')
            , data)
            .then(response => {
                this.count--;
                if (response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--;return error;});
    },

    async getBodyOptionsOnce() {
        this.count++;
        return instance.get('/body-options')
            .then(response => {
                this.count--;
                if (!response || !response.data || response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--;return error;});
    },


    getBodyOptionChs(bodyOptionId, bodyTypeId = 0, categoryId = 0) {
        this.count++;
        return instance.get('/body-option-characteristics?params=1' +
            (bodyOptionId * -1 !== 0 ? '&body_option_id=' + bodyOptionId : '') +
            (bodyTypeId ? '&body_type_id=' + bodyTypeId : '') +
            (categoryId * -1 !== 0 ? '&car_type_id=' + categoryId : '')
        ).then(response => {
            this.count--;
            if (response.error) {
                console.error(response.error);
                return [];
            }
            if (!Array.isArray(response.data)) {
                console.error('api call is not returned array');
                return [];
            }
            return response;
        })
            .catch(error => {this.count--;return error;});
    },


    async getBodyOptionChsOnce() {
        this.count++;
        return instance.get('/body-option-characteristics').then(response => {
            this.count--;
            if (!response || !response.data || response.error) {
                console.error(response.error);
                return [];
            }
            if (!Array.isArray(response.data)) {
                console.error('api call is not returned array');
                return [];
            }
            return response;
        })
            .catch(error => {this.count--;return error;});
    },
    getBodyOptionChValues(bodyOptionChId, categoryId = 0) {
        this.count++;
        return instance.get('/body-option-characteristics-values' +
            '?body_option_characteristics_id=' + bodyOptionChId +
            (categoryId * -1 !== 0 ? '&car_type_id=' + categoryId : ''))
            .then(response => {
                this.count--;
                if (response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--;return error;});
    },
    async getBodyOptionChsValuesOnce() {
        this.count++;
        return instance.get('/body-option-characteristics-values')
            .then(response => {
                this.count--;
                if (!response || !response.data || response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => {this.count--;return error;});
    }
};

export const dopAPI = {
    getDop() {
        return instance.get('/additional-requirements')
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => error);
    }
};

export const fileAPI = {
    addFile(name, data) {
        let requestData = {name, data};
        return instance.post('/order-files', requestData)
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return {};
                }
                return response;
            })
            .catch(error => error);
    }
};

export const cargoAPI = {
    getPalletTypes() {
        return instance.get('/pallet-types')
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => error);
    },
    getPackageTypes() {
        return instance.get('/package-types')
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return [];
                }
                if (!Array.isArray(response.data)) {
                    console.error('api call is not returned array');
                    return [];
                }
                return response;
            })
            .catch(error => error);
    },
    addCargo(name, price, places, pallets, packages, body_option_id, body_option_characteristics) {
        let data = {
            cargo: {
                name: name,
                price: price,
                places: places,
                pallets: pallets,
                packages: packages
            },
            body_option_id,
            body_option_characteristics,
        };
        return instance.post('/pack', data, {timeout: 10000})
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return {};
                }
                return response;
            })
            .catch(error => error);
    }
};

export const orderAPI = {
    calc(date, body_type_id, body_option_id, body_option_characteristics, additional_requirements, routes, name, price, places, pallets, packages, tariff_type_id, full_name, phone, phone_ext, email, payment_type, car_type_id, max_dimensions, draft, cancelToken,TIN,id) {
        let data = {
            date: date,
            body_type_id: body_type_id,
            body_option_id: body_option_id,
            body_option_characteristics: body_option_characteristics,
            additional_requirements: additional_requirements,
            routes: routes,
            cargo: {
                name: name,
                price: price,
                places: places,
                pallets: pallets,
                packages: packages
            },
            tariff_type_id: tariff_type_id,
            contacts: {
                full_name: full_name,
                phone: phone,
                phone_ext: phone_ext,
                email: email
            },
            payment_type: payment_type,
            max_dimensions: max_dimensions,
            car_type_id: car_type_id,
            draft: draft,
            TIN:TIN,
            id:id
        };
        // "TIN": "7743267977",
        //  "comment": "Order comment example",



        return instance.post('/calc?all=true', data, {timeout: 50000000, cancelToken})
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return {};
                }
                return response;
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error.message);
                }
                return error;
            });
    },
    orders(date, body_type_id, body_option_id, body_option_characteristics, additional_requirements, routes, name, price, places, pallets, packages, tariff_type_id, full_name, phone, phone_ext, email, payment_type, car_type_id, INN, draft = false, id = null, max_dimensions = false) {
        let data = {
            date: date,
            body_type_id: body_type_id,
            body_option_id: body_option_id,
            body_option_characteristics: body_option_characteristics,
            additional_requirements: additional_requirements,
            routes: routes,
            cargo: {
                name: name,
                price: price,
                places: places,
                pallets: pallets,
                packages: packages
            },
            tariff_type_id: tariff_type_id,
            contacts: {
                full_name: full_name,
                phone: phone,
                phone_ext: phone_ext,
                email: email
            },
            payment_type: payment_type,
            TIN: INN,
            draft: draft,
            max_dimensions: max_dimensions
        };
        if (id)
            data.id = id;


        if (car_type_id) {
            data.car_type_id = car_type_id;
        }
        return instance.post('/orders', data)
            .then(response => response)
            .catch(error => error);
    },
    getOrder(id){
        return instance.get('/orders?id='+id)
            .then(response => {
                if (response.error) {
                    console.error(response.error);
                    return {};
                } else if (!response.data?.id) {
                    console.error('Заказ с идентификатором ' + id + ' не найден!');
                    return {};
                }
                return response.data;
            })
            .catch(error => error);
    }
};
*/
export const phoneAPI = {
    sendSms(phone:string) {
        let requestData = {phone};
        return instance.post('/send-sms-code', requestData)
    },
    checkSms(phone:string, code:string) {
        return instance.get('/check-sms-code?phone=' + phone + '&code=' + code)
            .then(response => {
                return response;
            })
            .catch(error => error);
    },
    checkTinPhone(phone:string, tin:string) {
        return instance.get('/check-tin-phone?phone=' + phone + '&TIN=' + tin)
            .then(response => {

                return response;
            })
            .catch(error => error);
    },
    checkPhone(phone:string) {
        return instance.get('/check-tin-phone?phone=' + phone)
            .then(response => {

                return response;
            })
            .catch(error => error);
    }
};


export const serverAPI = {
    _check_data_on_error_array(response:AxiosResponse){
        if (!response || !response.data || response.status !== 200) {
            console.error(response.statusText);
            return true;
        }
        if (!Array.isArray(response.data)) {
            console.error('api call is not returned array');
            return true;
        }
        return false;
    },

    async getCategoriesFull() {

        return instance.get('/car-types-tree')
            .then((response) => {
                if (this._check_data_on_error_array(response)) return [];
                return response.data;
            })
            .catch(error => console.error(error));
    },

    async getTariffTypes(){
        return instance.get('/tariff-types')
            .then((response) => {
                if (this._check_data_on_error_array(response)) return [];
                return response.data;
            })
            .catch(error => console.error(error));
    },
    async getOrderById(id:string){
        return instance.get('/orders?id='+id)
            .then((response) => {
                return response.data;
            })
            .catch(error => console.error(error));
    },
    async authMe(client_id:string){
        return instance.get(`auth/me?client_id=${client_id}`)
            .then((response) => {
                return response.data;
            })
            .catch(error => console.error(error));
    },

    async authByPhone(body:{phone_number:string, sms_code:string}){
        return instance.post(`auth`,body)
            .then((response) => {
                return response.data;
            })
            .catch(error => console.error(error));
    },

    getPalletTypes() {
        return instance.get('/pallet-types')
            .then(response => {
                if (this._check_data_on_error_array(response)) return [];
                return response.data;
            })
            .catch(error => console.error(error));
    },

    getPackageTypes() {
        return instance.get('/package-types')
            .then(response => {
                if (this._check_data_on_error_array(response)) return [];
                return response.data;
            })
            .catch(error => console.error(error));
    },
    getAdditionalTypes() {
        return instance.get('/additional-requirements')
            .then(response => {
                if (this._check_data_on_error_array(response)) return [];
                return response.data;
            })
            .catch(error => console.error(error));
    },

    getPackInfo(data:{cargo:CargoInfo, body_option_id:string, body_option_characteristics:IBOCharacteristics[]}){
        return instance.post('/pack', data, {timeout: 10000})
            .then(response => {
                return response.data;
            })
            .catch(error => console.error(error));
    },
    calc(data:SendOrderInfo){
        return instance.post('/calc?all=true', data, {timeout: 10000})
            .then(response => {
                if (this._check_data_on_error_array(response)) return [];
                return response.data;
            })
            .catch(error => console.error(error));
    },

    getFile(id:string) {
        return instance.get('/order-files?id=' + id)
            .then(response => {
                if (response.status !== 200) {
                    console.error(response.statusText);
                    return null;
                }
                if (response.data && response.data.status === "error") {
                    console.log(response.data.errormessage);
                    return null;
                }
                if (!response.data.file) {
                    console.log('wrong file format');
                    return null;
                }
                let buffer = atob(response.data.file);
                let n = buffer.length;
                let arr = new Uint8Array(n);
                while (n--) {
                    arr[n] = buffer.charCodeAt(n);
                }
                return new File([arr], 'file.jpg', {type: 'image/jpg'});
            })
            .catch(error => error);
    },

    postProfileInfo(profile: CUserProfile){
        return instance.post(`clients`, profile, {timeout: 10000})
    },

    postOrder(order: SendOrderInfo){
        return instance.post(`orders`, order, {timeout: 10000})
    },
    deleteOrder(id:string){
        return instance.post(`cancel-order`, {id}, {timeout: 10000})
    },

    getArticles(id?:string) {
        return instance.get("client-articles" + (id ? '?id='+id : ''));
    },

    getOrderList(param:{client_id: string,start_from: string,start_to: string,count: number}){
        return instance.get(`/client-order-list?client_id=${param.client_id}` +
                                 (param.start_from? "&start_from=" + param.start_from : '') +
                                 (param.start_to?   "&start_to=" + param.start_to : '') +
                                 (param.count?      "&count=" + param.count : '')
                        );
    },
    getRoutesByOrderId(id:string) {
        return instance.get(`routes?order_id=${id}`)
    }

};
