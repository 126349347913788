//.....................................localhost.................................

const host = window.location.host.replace('www.','').split('.').reverse().splice(0, 2).reverse().join('.');
const apiHost = host.indexOf('localhost')>=0 ? 'citycarrier.ru' : host;
export const apiOrderUrl = `https://api2.${apiHost}/PublicOrdersAPI`;
export const daDataToken = "4907ed3e0ba286c611e621c3db1588fe3ce7f53c";
// export const hereMapApiKey = 'Y0h-TaAfPC5vfGxnxpXZDi6Sqz5j594wIJaBxh0us40';
export const hereMapApiKey = host.indexOf("localhost") >= 0  ? "MiWaYuPKblM-f_Ih7SIJ07tcDkQJvZt13fSxOKdxy6E" : "CaK4qiOemHTkZvAJH6zv2wObp4KFkq7eEbtL4hI_T24"; // локальный ключ

export const cookiesClientId = 'clientId';
export const cookiesOrderId = 'orderId';
export const cookiesAccountId = 'selectedAccountId';
export const cookiesClientPhone = 'clientPhone';
