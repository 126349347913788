import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import "./SelectCarManual.css";

import {AutoCarSelectCard} from "./AutoCarSelectCard/AutoCarSelectCard";
import {AutoGreyButton} from "./AutoGreyButton/AutoGreyButton";
import {AutoDropDownGreyList} from "./AutoDropDownGreyList/AutoDropDownGreyList";
import {RootState} from "../../../redux/store";
import {
  CharacteristicTypeEnum,
  IBOCharacteristics,
  IBodyOptions,
  IFullCategory
} from "../../../redux/reducers/system/system_types";

import {
  filterCategory,
  getBOCharacteristics,
  getBodyOptionsList,
  getCategoryImage, getCharacteristicValueList
} from "../../../redux/reducers/system/system_functions";
import {SelectedCarData} from "../../../redux/reducers/slices/CreateOrderType";



interface IProps{
  data:SelectedCarData;
  onChange: (data:SelectedCarData) => void;
}

const SelectCarManual: React.FC<IProps> = ({data,onChange}) => {

  let [selectedCategoryID, setSelectedCategoryID] = useState<string>(data.category_id);
  let [selectedBodyID, setSelectedBodyID] = useState<string>(data.body_option_id);
  let [isMaxDimensions, setMaxDimensions] = useState<boolean>(data.max_dimensions);
  let [activeCharacteristics, setActiveCharacteristics] = useState<IBOCharacteristics[]>(data.active_characteristic);

  let [bodyOptions, setBodyOptions] = useState<IBodyOptions[]>([]);
  let [characteristics, setCharacteristics] = useState<IBOCharacteristics[]>([]);


  let [categoryList, setCategoryList] = useState<IFullCategory[]>([]);

  let {categories} = useSelector((state: RootState) => state.systemReducer);

  const changed = () =>{
    let data = new SelectedCarData();
    data.active_characteristic = activeCharacteristics;
    data.max_dimensions = isMaxDimensions;
    data.body_option_id = selectedBodyID;
    data.category_id = selectedCategoryID;
    onChange(data);
  };

  const onCategorySelect = async (cat: IFullCategory) => {
    await setSelectedCategoryID(cat.id === selectedCategoryID ? '' : cat.id );
  };

  const onBodySelect = (bo: IBodyOptions) => {
    setSelectedBodyID(bo.id === selectedBodyID ? '' : bo.id);
  };

  const onCharacteristicSelect = (ch: IBOCharacteristics, value : boolean|string) => {
    characteristics.filter(x=> x.id === ch.id).forEach(x=> {
      if (ch.type === CharacteristicTypeEnum.Boolean && typeof value === "boolean") {
          x.selected = value;
      }
      if (ch.type === CharacteristicTypeEnum.Number && typeof value === "string") {
        x.selected = +value > 0;
      }

      if (ch.type === CharacteristicTypeEnum.Ref && typeof value === "string") {
        x.selected = value !== '';
      }
      ch.value = value;
    });
    setActiveCharacteristics(characteristics.filter(x=>x.selected ));
    setCharacteristics([...characteristics]);
  };

  useEffect(()=>{
    categoryList = filterCategory(categories, selectedCategoryID, selectedBodyID, activeCharacteristics);
    setCategoryList(categoryList);
    if (categoryList.length) {
      setBodyOptions(getBodyOptionsList(categoryList));
      let chs = getBOCharacteristics(categoryList, selectedCategoryID, selectedBodyID);
      chs.forEach(x=> {
        let ch = activeCharacteristics.find(a=> a.id === x.id);
        if (!ch)  return;
        x.value = ch.value;
        x.selected = ch.selected;
      });
      setCharacteristics(chs.sort( (a,b)=> {
        if (a.name > b.name ) return  -1;
        return 1;
      }).sort( (a,b)=> {
        if (a.type > b.type ) return  1;
        return -1;
      }));
      changed();
    }
  },[categories, selectedCategoryID, selectedBodyID, activeCharacteristics, isMaxDimensions]);

  return (
    <div className="select-car">
      <div className="select-car__list">
        {categoryList.map( (x, index)=>   <AutoCarSelectCard
            carSelectProps={ x }
            key={"acsc_"+x.id}
            isSelected={ x.id === selectedCategoryID   }
            onSelect={() => onCategorySelect(x)}
            image={getCategoryImage(x.id)}
        />)}

      </div>
      <div className="select-car__btns">
        { bodyOptions.map( x=> <AutoGreyButton  key={"bo_"+x.id} type="gray" isSelected={x.id===selectedBodyID}
                                                onClick={() => {onBodySelect(x)}}>{x.name}</AutoGreyButton>)}
      </div>
      <div className="select-car__btns select-car__chrts">
        {characteristics.map(x=> {
          if ( x.type === CharacteristicTypeEnum.Boolean )
              return  <AutoGreyButton  key={"ch_"+x.id} type="gray" isSelected={x.selected}
                                       onClick={() => {onCharacteristicSelect(x, !x.selected)}}>{x.name}</AutoGreyButton>;
          if (x.type === CharacteristicTypeEnum.Ref)
             return  <AutoDropDownGreyList  type="gray" list={getCharacteristicValueList(categoryList, x, selectedBodyID, selectedCategoryID)}
                                            key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                            value={x.value}
                                            onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
          if (x.type === "number")
            return <AutoDropDownGreyList  type="gray" list={ [{id:'', text :'Любая'},{id:'1', text :'до 1 м.'},{id:'2', text :'до 2 м.'},{id:'3', text :'до 3 м.'},{id:'4', text :'до 4 м.'}]}
                                                          value={x.value}
                                                          key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                                          onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
        })}
        <AutoGreyButton  key={"ch_max"} type="gray" isSelected={isMaxDimensions}
                         onClick={() => {setMaxDimensions(!isMaxDimensions)}}>Макс. габариты кузова (ДШВ)</AutoGreyButton>
      </div>

    </div>
  );
};
export default SelectCarManual;
