
import hereMarkerIcon from "./hereMarkerIcon"
import {TypeLonLat} from "../../../redux/reducers/slices/CreateOrderType";
import {EnumMarkerType, mapMarker} from "../../../redux/reducers/slices/hereMapTypes";

const H = window.H;
let oldWaypointsString = '';

// функция создает маркер

export const hereMarker = (coordination: number[], marker: mapMarker = new mapMarker(), draggable: boolean = true) => {
    let text = `<span>${marker.title}</span><br/><span>${marker.text}</span>`
    let markerItem = new H.map.DomMarker({ lat: coordination[0], lng: coordination[1] }
      , {
        //@ts-ignore
        volatility: true,
        icon: new H.map.DomIcon(hereMarkerIcon(marker.type, text, marker.bg_color, marker.is_show_text))
      })

    markerItem.draggable = marker.type === EnumMarkerType.MainDraggable;

    markerItem.setData(marker.index);

    return markerItem
}

// функция добавляет поведение перетаскивания маркеров на карте

export const setDraggable = (map: H.Map, behavior: H.mapevents.Behavior, onMarkerClick: (index: number, point: TypeLonLat) => void ) => {

    map.addEventListener('dragstart', (ev: any) => {
        console.log('dragstart')
      const target = ev.target
      if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
        behavior.disable()
      }
    }, false)

    map.addEventListener('dragend', (ev: any) => {
        console.log('dragend')
      const target = ev.target
      if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
        behavior.enable();
        let geom :any= target.getGeometry();
        console.log('geom', geom);
        const coord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY);
        console.log('coord', coord);
        onMarkerClick(target.getData(), { lat: geom?.lat, lon : geom?.lng} )
        //@ts-ignore
        // const cords: { lat: number, lng: number, alt?: any, ctx?: any } = {...target.getGeometry()}
        // onClick(cords.lat, cords.lng)
      }
    }, false)

    map.addEventListener('drag', (ev: any) => {
        console.log('drag')
      const target = ev.target, 
        pointer = ev.currentPointer
      if (target instanceof H.map.Marker || target instanceof H.map.DomMarker) {
        target.setGeometry(map.screenToGeo(pointer.viewportX, pointer.viewportY + 20))
      }
    }, false)
}

export function afterDrag(map: H.Map, behavior: H.mapevents.Behavior, platform: any, dispatch: any) {
    let markers = map.getObjects();
    // markers.forEach((item) => {
    //     if (item instanceof H.map.Marker || item instanceof H.map.DomMarker) {
    //         let index = item.itemIndex;
    //         if (index === undefined || index < 0)
    //             return;
    //         let geo = item.getGeometry();
    //         if (points[index] && (points[index].address.latitude !== geo.lat
    //             || points[index].address.longitude !== geo.lng)) {
    //             geocode([geo.lat, geo.lng], platform, dispatch, index);
    //         }
    //     }
    // });
}

// функция отрисовывает примерную линию маршрута

export const drawWayOnMap = (points: Array<{lon: number, lat: number}>, color: string  = 'orange', width: number = 4, hMap: any) => {
    let newWaypointsString = JSON.stringify(points.map(x=> x.lat + x.lon))
    if ( oldWaypointsString === newWaypointsString) return

    oldWaypointsString = newWaypointsString

    newWaypointsString = ''
    //
    // hMap.removeObjects(hMap.getObjects().filter( (obj: any) => !(obj instanceof window.H.map.DomMarker
    //     || obj instanceof window.H.map.Marker)))

    var lineString = new H.geo.LineString()

    points.forEach( point => lineString.pushPoint({lat:point.lat, lng:point.lon}))

    let p =new H.map.Polyline( lineString, { style: { lineWidth: width, strokeColor: color }} )
    hMap.addObject(p);
    setCenterRoute(p, hMap);
}

const setCenterRoute = (p:H.map.Polyline|H.map.Group, hMap: any) => {
    hMap.getViewModel().setLookAtData({
        bounds:  p.getBoundingBox()
    });
    setTimeout(()=>{
        let p = hMap.getViewModel().getLookAtData().position;
        let zoom = hMap.getZoom();

        if (p) p.lat -= 0.005;
        hMap.getViewModel().setLookAtData({
            position:  p,
            zoom : zoom -  zoom*0.06
        });
        // map.setZoom(zoom -  zoom*0.05 );
    }, 0)
}
//  функция контейнер при создании вычисляемого средствами HERE maps маршрута
export const is_way_point_changed = (waypoints: Array<string>) =>  (oldWaypointsString !== JSON.stringify(waypoints));

export const calculateRoute = async (platform: any, waypoints: Array<string>, hMap: H.Map, color: string | undefined = "orange", width: number | undefined = 1) => {

    if (waypoints.length < 2  || !is_way_point_changed(waypoints)) return;
    oldWaypointsString = JSON.stringify(waypoints);

    var router = platform.getRoutingService(null, 8),

        routeRequestParams = {
            routingMode: 'fast',
            transportMode: 'car',
            origin: waypoints.shift() as string,
            destination: waypoints.pop() as string,
            return: 'polyline,travelSummary'
        };

    if (waypoints.length > 1) {
        //@ts-ignore
        routeRequestParams['via'] = new H.service.Url.MultiValueQueryParameter(
            waypoints
        );
    } else if (waypoints.length === 1) {
        //@ts-ignore
        routeRequestParams['via'] = waypoints.pop();
    }


    console.log('calculateRoute routeRequestParams', routeRequestParams)
    return new Promise((resolve, reject) => {
        router.calculateRoute( routeRequestParams, (result: H.service.ServiceResult) => {
              addRouteShapeToMap(result.routes[0], hMap, color, width)
              console.log('result.routes[0]', result.routes[0]);
          },
          (error: Error) => {
                console.error(error)
                reject(error)
          }
        )
    })
}

// функция добавляет вычисляемого средствами HERE maps маршрута на карту

export const addRouteShapeToMap = (route: H.service.ServiceResult, hMap: H.Map, color: string = 'orange', width: number = 1) => {
    var group = new H.map.Group()
    console.log('route.sections')
    route.sections.forEach((section: H.service.ServiceResult) => {
        group.addObject(
            new H.map.Polyline(
                // @ts-ignore
                new H.geo.LineString.fromFlexiblePolyline(section.polyline), {
                    style: {
                        lineWidth: width,
                        strokeColor: color
                    }
                }
            )
        )
    })

    hMap.addObject(group)
    setCenterRoute(group, hMap);
}

export function removeObjectFromMap( map: H.Map, nameObject:string) {

    let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];

    let objects: H.map.Object[] = listObj.filter( (obj : H.map.Object) => {

        // @ts-ignore
        if (obj.data && obj.data.name === nameObject) return true;
        return  false;
    });
    if (objects && objects.length)
        map.removeObjects(objects);
}

// Converts from degrees to radians.
function toRadians(degrees:number) {
    return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
function toDegrees(radians:number) {
    return radians * 180 / Math.PI;
}

export function getBearing(start: TypeLonLat, end: TypeLonLat){
    let startLat = toRadians(start.lat);
    let startLng = toRadians(start.lon);
    let destLat = toRadians(end.lat);
    let destLng = toRadians(end.lon);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}


export const createDomMarker = (coordination:number[], index :number|string = 1, icon:string, marker_type: string, marker_text: string = '', draggable:boolean = false) => {
    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
        , {
            // @ts-ignore
            volatility: true,
            icon: new H.map.DomIcon(  icon ),
            data: {name: marker_type, text :  marker_text}
        });
    // @ts-ignore
    marker.draggable = draggable;
    // @ts-ignore
    marker.itemIndex = +index;
    return marker;
}

