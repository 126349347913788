
export enum EnumOrderMode{ None, Auto, Manual}
export enum EnumAddressMode{ None, Append, Edit, Delete}
export enum EnumShowMapMode{ None, doAppendPoint, wasClickOnMap}

export class CargoSize {
    length: number = 0;
    width: number = 0;
    height: number = 0;
    weight: number = 0;
    quantity?:number;
}

export interface IPalletType{
    name: string,
    length: number,
    width:  number,
    manual: boolean,
    id: string
}

export interface IPackageType{
    name: string,
    id: string
}

export interface IPallet{
    pallet_type_id:string, quantity: number, size:CargoSize
}

export interface IPackage{
    package_type_id:string, quantity: number, size:CargoSize
}

export type CargoTypes =  IPallet | IPackage | {size:CargoSize, quantity?:number};

export class CPackInfo{
    car_type_id: string='';
    car_types_id: string[] = [];
    error?: boolean;
    error_description?:  string='';
    height: number = 0;
    load_by_area: number= 0;
    load_by_volume: number= 0;
    load_by_weight: number= 0;
    packed_items: IPackedItems[] = [];
    total_area: number= 0;
    total_volume: number= 0;
    total_weight: number= 0;
    width: number= 0;
}

export interface  IPackedItems {
    width: number,
    height: number,
    x: number,
    y: number,
    item: { name: string },
    bin: number,
}


export class CargoInfo{
    name:string = '';
    price:string = '';
    places: { size:CargoSize}[] = [];
    pallets: IPallet[] = [];
    packages: IPackage[] = [];
    max_dimensions:boolean = false;
}

export class WorkingHours {
    time_from:string = "";
    time_to:string = "";
    lunch_from:string = "";
    lunch_to:string = "";
    no_lunch:boolean = true;
    max_landing_time:string = "";
}

export class RouteAddress {
    id:number = -1;
    adress:string = "";
    house?:string;
    address:any = {}; // хранение адреса из геокодинга
    adress_comment:string = "";
    adress_longitude:number = 0;
    adress_latitude:number = 0;
    company:string = "";
    contact_persons: ContactPersons[] = [];
    what_to_do:string = "";
    working_hours: WorkingHours = new WorkingHours();
    arrivalDate:string = "";
    departureDate:string = "";
    action_documents:boolean = false;
    action_loading:boolean= false;
    action_unloading:boolean= false;
    action_forwarder:boolean= false;
    files_ids:string[] = [];
    constructor(id:number) {
        this.id=id;
    }
}
const getTestRoutes = (is_test = true)   =>
    is_test ?
        [
            {
                id: 0,
                adress: "г Москва, ул Лесная, д 61 к 2",
                house: "61",
                address: {},
                adress_comment: "",
                adress_longitude: 37.5932142,
                adress_latitude: 55.7826135,
                company: "",
                contact_persons: [],
                what_to_do: "",
                working_hours: {
                    time_from: "",
                    time_to: "",
                    lunch_from: "",
                    lunch_to: "",
                    no_lunch: true,
                    max_landing_time: ""
                },
                arrivalDate: "",
                departureDate: "",
                action_documents: false,
                action_loading: false,
                action_unloading: false,
                action_forwarder: false,
                files_ids: []
            },
            {
                id: 1,
                adress: "г Москва, ул Маши Порываевой, д 7 стр 2",
                house: "7",
                address: {},
                adress_comment: "",
                adress_longitude: 37.647127,
                adress_latitude: 55.77353,
                company: "",
                contact_persons: [],
                what_to_do: "",
                working_hours: {
                    time_from: "",
                    time_to: "",
                    lunch_from: "",
                    lunch_to: "",
                    no_lunch: true,
                    max_landing_time: ""
                },
                arrivalDate: "",
                departureDate: "",
                action_documents: false,
                action_loading: false,
                action_unloading: false,
                action_forwarder: false,
                files_ids: []
            }
        ] : [new RouteAddress(0),new RouteAddress(1)];

export class CContactInformation{
    phone_ext:string="";
    full_name:string="";
    phone:string="";
    email:string="";
}
export enum EnumPaymentType {
    PaymentOnAccount = 'paymentonaccount', PaymentOnline = 'paymentonline', PaymentCash = 'paymentcash'
}
export class SendOrderInfo{
    id:string = '';
    date:string = '';
    body_type_id: number = 0;
    body_option_id:string = '';
    body_option_characteristics:{id:string, value:boolean|string}[] = [];
    additional_requirements:{id:string, value:boolean|string|number}[] = [];
    routes: RouteAddress[] = getTestRoutes(false);
    cargo:CargoInfo = {...new CargoInfo()};
    tariff_type_id:string = 'bdc31826-7d68-11ea-a9c9-00155d8e4e03';
    contacts:CContactInformation = {...new CContactInformation()};
    payment_type: EnumPaymentType = EnumPaymentType.PaymentOnAccount;
    max_dimensions:boolean = false;
    car_type_id:string = '';
    TIN:string = '';
    company_name?:string = '';
    comment:string = '';
    draft:boolean = true;
    client_id:string = '';
}




export class ContactPersons {
    full_name:string ="";
    phone:string = "";
    phone_ext:string = "";
    email:string = "";
}



export interface ITariffInfo{
    name:string;
    id: string;
}

export class CTariffPoint {
    name: string = "";
    originalIndex: number  = 0;
    speed: number  = 0;
    lat: number  = 0;
    lon: number  = 0;
    departureDate: string = "";
    lengthToNextPoint: number  = 0;
    arrivalDate: string = "";
    timeToNextPoint: number  = 0;
};

export class CTariffData  {
    tariff_name: string = "";
    tariff_type_id: string = "";
    url: string = "";
    hours: number  = 0;
    min_hours: number = 0;
    rate: string = "";
    min_cost: number = 0;
    cost_by_hour: number = 0;
    cost: number = 0;
    items: { cost: number; name: string }[] = [];
    items_by_route: { cost: number; name: string }[] = [];
    service_information: string = "";
    points: CTariffPoint[] = [];
};


export class SelectedCarData{
    category_id:string = '';
    body_option_id:string = '';
    active_characteristic: IBOCharacteristics[] = [];
    max_dimensions:boolean = false;
}


export enum CharacteristicTypeEnum {
    Boolean = "Boolean", Ref = "ref", Number = "number"
}

export interface IBOCharacteristics {
    id: string,
    name: string,
    type: CharacteristicTypeEnum,
    selected:boolean,
    value:any,
    body_option_characteristics_values: IBOCharacteristicsValue[]
}

export interface IBOCharacteristicsValue {
    id: string,
    name: string,
    selected:boolean,
    body_option_characteristics_id: string
}

export interface TypeLonLat {lon:number, lat:number}
export interface IMapShowMode {mode:EnumShowMapMode, coordinate?:TypeLonLat}



export type CreateOrderState = {
    order_manual: SendOrderInfo;
    order_auto: SendOrderInfo;
    order_mode: EnumOrderMode;
    tariffs: CTariffData[];
    selected_address_index:number;
    address_mode: EnumAddressMode;
    map_mode: IMapShowMode;
    pack_info: CPackInfo  ;
    is_tariff_loading: boolean;
    is_changed: boolean;
    is_agreement: boolean;
    is_phone_checked: boolean;
    way_points_from_1c:  [number,number][];
}

export interface IPackInfo {
    car_type_id: string,
    car_types_id: string[] ,
    error: boolean,
    error_description:  string,
    height: number,
    load_by_area: number,
    load_by_volume: number,
    load_by_weight: number,
    packed_items:any[],
    total_area: number,
    total_volume: number,
    total_weight: number,
    width: number,
    status?:string
}

export interface IAdditionalRequirements {
    id: string
    name: string
    type: string
    value?: string
}
