import React from "react";
import { ReactSVG } from "react-svg";
import cn from "classnames";
import "./AutoCarSelectCard.css";
import { IFullCategory } from "../../../../redux/reducers/system/system_types";

interface AutoCarSelectCardProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  onSelect: Function;
  carSelectProps: IFullCategory;
  image: string;
}
export const AutoCarSelectCard: React.FC<AutoCarSelectCardProps> = ({
  isSelected = false,
  isDisabled = false,
  onSelect,
  carSelectProps,
  image = "",
}) => {
  const onClickCard = () => {
    if (isDisabled) {
      return;
    } else {
      onSelect();
    }
  };

  return (
    <div
      onClick={onClickCard}
      className={cn(
        "car-card",
        { "car-card__selected": isSelected },
        { "car-card__disabled": isDisabled }
      )}
    >
      <div className="car-card__description car-description">
        <div className="car-description__image">
          <ReactSVG src={image} />
        </div>
        <div className="car-description__name">{carSelectProps.name}</div>
        <div className="car-description__weight">
          {carSelectProps.weight_to} кг
        </div>
      </div>
      <div className="car-card__info car-info">
        <div className="car-info__item">
          <div className="car-info__legend">д</div>
          <div className="car-info__value">
            {carSelectProps.length_from} - {carSelectProps.length_to}м
          </div>
        </div>
        <div className="car-info__item">
          <div className="car-info__legend">ш</div>
          <div className="car-info__value">
            {carSelectProps.width_from} - {carSelectProps.width_to}м
          </div>
        </div>
        <div className="car-info__item">
          <div className="car-info__legend">в</div>
          <div className="car-info__value">
            {carSelectProps.height_from} - {carSelectProps.height_to}м
          </div>
        </div>
        <div className="car-info__item">
          <div className="car-info__legend">о</div>
          <div className="car-info__value">
            {carSelectProps.volume_from} - {carSelectProps.volume_to}м³
          </div>
        </div>
      </div>
    </div>
  );
};
