
import {IIconProps} from "./SvgIconsType";


export const BackRoundIcon = ({ onClick,className }: IIconProps) => {
    return (
        <div className={className} onClick={() => onClick && onClick()}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4L7.5 10L13.5 16" stroke="black" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>)
}
