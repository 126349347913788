import React, {useState} from "react";
import { ReactSVG } from "react-svg";
import {IPackInfo} from "../../../../redux/reducers/slices/CreateOrderType";
import {
    filterCategory,
    getBOCharacteristics,
    getBodyOptionsList,
    getCategoryImage
} from "../../../../redux/reducers/system/system_functions";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {CharacteristicTypeEnum, IBOCharacteristics, IBodyOptions} from "../../../../redux/reducers/system/system_types";
import {AutoGreyButton} from "../../SelectCarManual/AutoGreyButton/AutoGreyButton";
import BlockSelectBodyOption from "../BlockSelectBodyOption/BlockSelectBodyOption";
import BlockSelectCharacteristics from "../BlockSelectCharacteristics/BlockSelectCharacteristics";
import "./PackCargo.css";

interface IProps {
    pack: IPackInfo,
    selectedBodyID: string,
    onBodySelect:(id:string) => void;
    activeCharacteristics: IBOCharacteristics[];
    setActiveCharacteristics: (ch: IBOCharacteristics[]) => void;
    isMaxDimensions:boolean;
    setMaxDimensions:(val:boolean) => void;
}

const PackCargo: React.FC<IProps> = ({
                                         pack,
                                         selectedBodyID,
                                         onBodySelect,
                                         activeCharacteristics,
                                         setActiveCharacteristics,
                                         isMaxDimensions,
                                         setMaxDimensions
                                     }) =>{

    let {categories} = useSelector((state :RootState) => state.systemReducer);

    let category = categories.find(x=> {
        if (pack.car_types_id.length > 0) return x.id === pack.car_types_id[0];
        return false;
    });
    let active_categories = categories.filter(x=> pack.car_types_id.some(id=> x.id === id));
    active_categories = filterCategory(active_categories, "", selectedBodyID, activeCharacteristics);
    let bodies: IBodyOptions[] = getBodyOptionsList(active_categories);
    let characteristics = getBOCharacteristics(active_categories, category ? category.id : '', selectedBodyID) ;

    const onCharacteristicSelect = (ch: IBOCharacteristics, value : boolean|string) => {
        characteristics.filter(x=> x.id === ch.id).forEach(x=> {
            if (ch.type === CharacteristicTypeEnum.Boolean && typeof value === "boolean") {
                x.selected = value;
            }
            if (ch.type === CharacteristicTypeEnum.Number && typeof value === "string") {
                x.selected = +value > 0;
            }

            if (ch.type === CharacteristicTypeEnum.Ref && typeof value === "string") {
                x.selected = value !== '';
            }
            ch.value = value;
        });
        setActiveCharacteristics(characteristics.filter(x=>x.selected ));
    };

    let ratio = 94 / (parseFloat(''+pack.height));
    if (ratio > 78) {
        ratio = 78;
    } else if (ratio < 42) {
        ratio = 42;
    }
    let cargoAdaptiveWidth = parseFloat(''+pack.height) * ratio + 22 + 'px';
    let cargoAdaptiveHeight = parseFloat(''+pack.width) * ratio + 22 + 'px';
    console.log('ratio', ratio)
    return (
        <>
        {category &&
            <div className="pack-cargo">
                <div className="pack-cargo__category_and_schema">
                    <div className="pack-cargo__category car-description__image">
                        <ReactSVG src={getCategoryImage(pack.car_types_id.length ? pack.car_types_id[0] : '')} />
                        <div className="pack-cargo__category-title">{category.name}</div>
                        <div className="pack-cargo__category-weight">{category.weight_to} кг</div>
                    </div>
                    <div className="pack-cargo__schema">
                            <div className={"cargo__sizes" } style={{
                                width: cargoAdaptiveWidth
                            }}>
                                 <div className="sizes-block">
                                        {pack.packed_items ? pack.packed_items.map((block, index) => {
                                            return (
                                                <div key={index} className="cargo__size-block" style={{
                                                    width: parseFloat(''+block.height) * ratio + 'px',
                                                    height: parseFloat(''+block.width) * ratio + 'px',
                                                    left: parseFloat(''+block.y) * ratio + 'px',
                                                    top: parseFloat(''+block.x) * ratio + 'px'
                                                }}>
                                                    <div className="size-block">
                                                        {/*<div className="size-block__value">{block.width}</div>*/}
                                                        {/*<div className="size-block__value">{block.height}</div>*/}
                                                        {/*<div className="size-block__value">{block.width}</div>*/}
                                                        {/*<div className="size-block__value">{block.height}</div>*/}
                                                    </div>
                                                </div>
                                            );
                                        }) : ''}

                            </div>
                            </div>
                    </div>
                </div>
                <div className="pack-cargo__category-info">
                    <div className="pack-cargo__category-info-item">
                        <div>Длина</div>
                        <div>{category.length_from +' - ' + category.length_to} м</div>
                    </div>
                    <div className="pack-cargo__category-info-item">
                        <div>Ширина</div>
                        <div>{category.width_from +' - ' + category.weight_to} м</div>
                    </div>
                    <div className="pack-cargo__category-info-item">
                        <div>Высота</div>
                        <div>{category.height_from +' - ' + category.height_to} м</div>
                    </div>
                    <div className="pack-cargo__category-info-item">
                        <div>Объем</div>
                        <div>{category.volume_from +' - ' + category.volume_to} м³</div>
                    </div>
                </div>
                <div className="select-car__btns">
                    <BlockSelectBodyOption bodyOptions={bodies} selectedBodyID={selectedBodyID} onBodySelect={(bo)=>onBodySelect(bo.id)} />
                </div>
                <div className="select-car__chrts">
                    <BlockSelectCharacteristics selectedBodyID={selectedBodyID} categories={active_categories}
                                                active_characteristics={activeCharacteristics} onCharacteristicSelect={onCharacteristicSelect} />
                    <AutoGreyButton  key={"ch_max"} type="gray" isSelected={isMaxDimensions}
                                     onClick={() => {setMaxDimensions(!isMaxDimensions)}}>Макс. габариты кузова (ДШВ)</AutoGreyButton>
                </div>
            </div>
        }
        </>
    )
};

export default  PackCargo;
