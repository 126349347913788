import React, {useEffect, useRef, useState} from "react";
import LabelInput from "../LabelInput/LabelInput";
import {CargoSize, CargoTypes, IPackage, IPallet} from "../../../../redux/reducers/slices/CreateOrderType";
import {AutoGreyButton} from "../../SelectCarManual/AutoGreyButton/AutoGreyButton";
import "./InputCargo.css"


interface IProps {
    value : any;
    onAppend: (val:CargoTypes) => void
}

const InputCargo:React.FC<IProps> = ({value, onAppend}) =>{
    let [localValue, setLocalValue] = useState<any>( value);
    let [clearValue, setClear] = useState<any>( {});
    useEffect(()=>{
    }, [ localValue  ]);

    const setNewValue = (val:any) => {
        setLocalValue( val );
    };
    return (
        <>
        {localValue &&
            <>
                <div className="input-cargo__size">
                    <LabelInput type={"number"} value={value.size.length} title={'Длина'} unit={" м"} clear={clearValue}
                                tabindex={1}
                                onChange={(val) => setNewValue({...localValue, size: {...localValue.size, length: +val}})}/>
                    <LabelInput type={"number"} value={value.size.width} title={'Ширина'} unit={" м"} clear={clearValue}
                                tabindex={2}
                                onChange={(val) => setNewValue({...localValue, size: {...localValue.size, width: +val}})}/>
                    <LabelInput type={"number"} value={value.size.height} title={'Высота'} unit={" м"} clear={clearValue}
                                tabindex={3}
                                onChange={(val) => setNewValue({...localValue, size: {...localValue.size, height: +val}})}/>
                    <LabelInput type={"number"} value={value.size.weight} title={'Вес'} unit={" кг"} clear={clearValue}
                                tabindex={4}
                                onChange={(val) => { setNewValue({...localValue, size: {...localValue.size, weight: +val}})
                                }}/>
                    {value.hasOwnProperty('quantity') &&
                    <LabelInput type={"number"} value={value.quantity} title={'Кол-во'} unit={" шт"} clear={clearValue}
                                tabindex={5}
                                onChange={(val) => setNewValue({...localValue, quantity: +val})}/>}
                </div>
                <div className="input-cargo__button">
                    <AutoGreyButton type={"green"} size={"large"} onClick={()=>{ onAppend( localValue ); setClear({})  }}
                                    isDisabled={   !localValue.size.length || !localValue.size.width ||
                                                    !localValue.size.height || !localValue.size.weight ||
                                                (localValue.hasOwnProperty('quantity') && !localValue.quantity)
                                    }>Добавить</AutoGreyButton>
                </div>
            </>
        }
        </>
    )
};

export default InputCargo;
