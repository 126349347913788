import React, { useEffect, useState } from "react";

import {phoneAPI, serverAPI} from "../../../api/server";
import cn from "classnames";
import  { Puff } from "react-loader-spinner";
import "./RegistrationForm.css";
import { ValidateInput } from "../../Elements/UserInputs/ValidateInput/ValidateInput";
import {CustomCheckBox} from "../../Elements/CustonCheckBox/CustomCheckBox";
import {useAppDispatch} from "../../../redux/hooks";
import {useCookies} from "react-cookie";
import {cookiesAccountId, cookiesClientId, cookiesClientPhone} from "../../../deployment";
import {CCompany, CUser, CUserProfile} from "../../../redux/reducers/slices/userProfile.type";
import {showErrorMessage, showInfoMessage} from "../../../redux/reducers/system/system_functions";
import {setClientId, setEnteredPhone, setIsAuth, setUserProfile} from "../../../redux/reducers/slices/userProfileSlice";

interface RegistrationWindowProps {
  onRegistrationComplete: () => void;
  onClose: () => void;
  timeWait: number;
}

export interface IRegistrationDataObj {
  full_name: string;
  email: string;
  phone: string;
  tin?: string;
  company_name?: string;
}

interface codeValidation {
  valid: null | boolean;
}

var timerInterval : any = undefined;

const RegistrationForm: React.FC<RegistrationWindowProps> = ({
  onRegistrationComplete,
  onClose,
  timeWait,
}) => {

  const dispatch = useAppDispatch();
  const [cookies, setCookie] = useCookies([cookiesClientId, cookiesAccountId, cookiesClientPhone]);
  const [isLoading, setLoading] = useState<boolean>(false);


  const registerData: IRegistrationDataObj = {
    full_name: "",
    email: "",
    phone: "",
    tin: "",
  };
  const [formData, setFormData] = useState(registerData);

  const [enterCode, setEnterCode] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState({  valid: null, } as codeValidation);
  const [time, setTime] = useState(timeWait);
  const [isAgreement, setAgreement] = useState<boolean>(false);

  useEffect(()=>{
    let isMounted = true;
    if (timerInterval) {
      clearInterval(timerInterval);
      timerInterval = undefined;
    }
    if (!enterCode || isCodeValid.valid) return;

    timerInterval = timerInterval ? timerInterval : setInterval(() => {
        if (!isMounted) {
          clearInterval(timerInterval);
          timerInterval = undefined;
          return;
        }
        if (time>0) setTime(time - 1);
      }, 1000 );

    return () => { isMounted = false };

  },[enterCode])


  const isFieldsValid = () => {
    return formData.full_name !== "" && isCodeValid.valid && isAgreement;
  }

  const onRegistrationStart = async (data:IRegistrationDataObj) => {
    console.log('onRegistration data', data)
    setLoading(true);
    let profile = new CUserProfile();
    let user = new CUser();
    user.phone = data.phone;
    user.full_name = data.full_name;
    user.email = data.email;
    profile.users = [user];
    if (data.tin) {
      let company = new CCompany();
      company.tin = data.tin;
      company.name = data.company_name;
      profile.companies = [company]
    }

    let res = await serverAPI.postProfileInfo(profile); //Тест Тест Тестович
    setLoading(false);

    const { status, client_id, error_message, full_name, users } = res.data;
    if (!status || status === 'error')  return showErrorMessage(error_message, dispatch);

    user = users.length ? users[0] : null;
    const fullName = full_name || (user && user.full_name) || '';
    const clientId = client_id || (user && user.id) || '';

    dispatch(setClientId(clientId));
    setCookie(cookiesClientId, clientId, { path: '/' });
    setCookie(cookiesClientPhone, data.phone, { path: '/' });
    dispatch(setIsAuth(true));
    dispatch(setUserProfile(res.data));
    dispatch(setEnteredPhone(data.phone));
    showInfoMessage(`Добро пожаловать 1 ${fullName}`, dispatch);

    onRegistrationComplete();
  }

  return (
    <div className="register-window">
      <h2 className="register-window__title">Регистрация</h2>
      <div
        className="register-window__form registration-form"

      >
        <div className="registration-form__item">
          <ValidateInput
            inputClass="registration-form__input"
            value=""
            type="fio"
            errorMessage="ФИО введено некоректно"
            placeholder="Ваши ФИО (Иванов Иван Иванович)"
            inputTabIndex={1}
            onError={(error) => {
              console.log("error: ", error);
            }}
            onChangeInput={(inputValue) => {
              setFormData((prevState) => ({
                ...prevState,
                full_name: inputValue as string,
              }));
            }}
          />
        </div>
        <div
          className={cn(
            "registration-form__item",
            { "registration-form__item--show-code": enterCode },
            {
              "registration-form__item--code-error":
                enterCode && isCodeValid.valid === false,
            }
          )}
        >
          <ValidateInput
            inputClass="registration-form__input registration-form__input--phone"
            value=""
            type="phone"
            errorMessage="Телефонный номер введен некоректно"
            placeholder="Ваш телефон (+7 900 123 4567)"
            inputTabIndex={2}
            onError={(error) => {  console.log("error: ", error); }}
            onChangeInput={(inputValue) => {
              setEnterCode(true);
              phoneAPI.sendSms(inputValue as string);
              setFormData((prevState) => ({...prevState, phone: inputValue as string,}));
            }}
            isValid={isCodeValid.valid}
          />
          {enterCode &&
            <ValidateInput
                inputClass={"registration-form__input registration-form__input--code "}
                value=""
                type="code"
                errorMessage="Неверный код"
                placeholder="Код из SMS"
                inputTabIndex={3}
                onError={(error) => { console.log("error: ", error);}}
                onChangeInput={async (inputValue) => {
                  console.log("onChangeInput", inputValue)
                  if (String(inputValue).length === 4) {
                    const validationResponse = await phoneAPI.checkSms(
                        formData.phone,
                        inputValue as string
                    );
                    setIsCodeValid(validationResponse.data);
                    if (validationResponse.data.valid)
                      setEnterCode(false)
                  }
                }}
                isValid={isCodeValid.valid}
            />
          }
          {enterCode &&
            <div className="registration-form__code-note">
              {time > 0 && `Отправить новый код можно через ${time} сек...`}
              {time === 0 && (
                <span
                  onClick={() => {
                    phoneAPI.sendSms(formData.phone);
                    setTime(timeWait);
                  }}
                >
                  Отправить новый код
                </span>
              )}
            </div>
          }
        </div>
        <div className="registration-form__item">
          <ValidateInput
            inputClass="registration-form__input"
            value=""
            type="email"
            errorMessage="Email введен некоректно"
            placeholder="Ваш Email (не обязательно)"
            inputTabIndex={4}
            isRequired={false}
            onError={(error) => { console.log("error: ", error); }}
            onChangeInput={(inputValue) => {  setFormData((prevState) => ({ ...prevState, email: inputValue as string,  })); }}
          />
        </div>
        <div className="registration-form__item registration-form__item--inn">
          <ValidateInput
            inputClass="registration-form__input"
            value=""
            type="inn"
            errorMessage="Укажите ИНН для оформления оплаты по счету"
            placeholder="ИНН компании (не обязательно)"
            inputTabIndex={5}
            onError={(error) => {
              console.log("error: ", error);
            }}
            onChangeInput={(inputValue) => {
              console.log("inputValue: ", inputValue);
              let tmp = inputValue as string;
              if (tmp.indexOf(':')>0)
                setFormData((prevState) => ({...prevState,tin: tmp.split(':')[0],company_name: tmp.split(':')[1] }));
              else
                setFormData((prevState) => ({...prevState,tin: '',company_name: ''}));
            }}
          />
          <div className="registration-form__note">
            Если вы являетесь представителем компании
          </div>
        </div>
        <div className="registration-form__agreements">
          <CustomCheckBox onChange={(val:boolean)=>setAgreement(val)}>
            Я принимаю{" "}
            <a href="./terms-of-use.html" target="_blank">
              пользовательское соглашение
            </a>{" "}
            и{" "}
            <a href="./privacy-policy.html" target="_blank">
              политику конфиденциальности
            </a>
          </CustomCheckBox>
        </div>
        <div className="registration-form__button--wrapper">
          <button
            className={cn("registration-form__button", {  "registration-form__button--active": isFieldsValid(),})}
            type="submit"
            onClick={()=>{ if (!isFieldsValid() || isLoading) return;  onRegistrationStart(formData); }}
          >
            {isLoading ?
                  <div className="registration-form__button--content">
                    <Puff color="#fff" width={"24px"} height={"24px"} />{" "}
                    Обработка данных
                  </div>
                :
                  "Зарегистрироваться"
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
