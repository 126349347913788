import {FC} from "react";
import './BurgerMenu.css';
import {useAppSelector} from "../../../redux/hooks";
import {userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";

interface IProps {
    onClick:()=>void;
    onRegistrationClick:()=>void;
}

export const BurgerMenu:FC<IProps> =({onClick, onRegistrationClick})=>{
    const { isAuth } = useAppSelector(userProfileSelector);
    return(
        <>

            <div className="burger-menu" onClick={onClick}>
                <svg width="22" height="16" viewBox="0 0 22 16" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="1" x2="21" y2="1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <line x1="1" y1="8" x2="21" y2="8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <line x1="1" y1="15" x2="21" y2="15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            {!isAuth && <div className="burger-menu-registration" onClick={onRegistrationClick}>регистрация</div>}
        </>
    )
}
