import { ReactSVG } from "react-svg";
import cn from "classnames";
import closeIcon from "../../App/svg/close-modal.svg";
import "./Modal.css";

interface ModalProps {
  props: {
    title: string;
    images: string[];
  };
  showModal: boolean;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({ props, showModal, closeModal }) => {
  return (
    <div className={cn("modal-wrapper", { "show-modal": showModal })}>
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">{props.title}</div>
          <ReactSVG
            src={closeIcon}
            className="close-modal"
            onClick={() => closeModal()}
          />
        </div>
        <div className="modal-content"></div>
      </div>
    </div>
  );
};

export default Modal;
