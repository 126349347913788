import React from "react";
import {CContract} from "../../../redux/reducers/slices/userProfile.type";
import { CloseIcon } from "../../Elements/CloseIcon/CloseIcon";

interface IProps{
    contract: CContract,
    onClose : ()=> void
}
const  ProfileCompanyInfo:React.FC<IProps> = ({contract,onClose}) =>{
    return  (
            <div className="profile_edit_one_record">
                <div className="profile_edit_one_record_window">
                    <div className="title">
                        <div className="inner-text">
                            <div>Договор с компанией</div>
                            <CloseIcon onClick={onClose}  className={"menu-form-close"}/>
                        </div>
                    </div>
                    <div className="content">
                        <div className="text_row">Номер договора: <span>{contract.number}</span></div>
                        <div className="text_row">Дата договора: <span>{contract.date}</span></div>
                        <div className="text_row">Дополнительная информация:</div>
                        <div className="text_details">{contract.details}</div>
                        <div className="content-subtitle">


                        </div>
                        <div className="button-area">
                            <button onClick={onClose}  >Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
    )
};

export default ProfileCompanyInfo;
