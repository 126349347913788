import { ReactSVG } from "react-svg";
import {useEffect, useRef, useState} from "react";
import optionDown from "../../../App/svg/option.svg";
import optionUp from "../../../App/svg/option-up.svg";
import cn from "classnames";
import "react-dadata/dist/react-dadata.css";
import "./Address.css";
import HereAddressSuggestions from "../HereAddressSuggestions/HereAddressSuggestions";
import { ChooseTime } from "../ChooseTime";
import {ContactPersons, RouteAddress} from "../../../../redux/reducers/slices/CreateOrderType";
import WhiteIcon from '../../../../images/white-round.svg'
import ToIcon from '../../../../images/to-round.svg'

import FromIcon from '../../../../images/from-round.svg'
import AdvancedInputElement, {elementsEnum} from "./AdvancedInputElement";
import {PointIcon} from "../../SvgIcons/PointIcon";
import moment from "moment";

enum EnumAddressType{from= "from", to= "to", info= "info"};

interface AddressProps {
  type: "from" | "to" | "info";
  value: RouteAddress;
  disableinput?: boolean;
  onFocus?: (val:boolean) => void;
  onChange:(value:RouteAddress) => void;
  onDelete?:()=>void;
  onExpand?:(val:boolean) => void;
  isSelected?:boolean

}

export const Address: React.FC<AddressProps> = ({
  value,
  type,
  disableinput = false,
  onFocus,
  onChange,
  onDelete,
  isSelected,
}) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const [isError, setIsError] = useState(false);
  const [addressValue, setAddressValue] = useState(value.adress);
  const [newBg, setNewBg] = useState(false);
  const [isExpanded, setExpandState] = useState(isSelected);
  let sugRef = useRef<HTMLInputElement|null>(null);

  useEffect(() => {
    addressValue ? setIsEmpty(false) : setIsEmpty(true);
  }, [addressValue]);

  useEffect(() => {
    setExpandState(isSelected);
    if (sugRef && sugRef.current && isSelected ) sugRef.current.focus()
  }, [isSelected]);


  const toggleExpand = () => {
    if (onFocus ) onFocus(!isExpanded)
    if (type=="info") setExpandState(!isExpanded);
  }

  const setInputFocus = () => {
    setIsEmpty(false);
    // setNewBg(true);
    if (onFocus) onFocus(true);
  };
  const setInputBlur = (addressValue: any) => {
    if (!addressValue) {
      setIsEmpty(true);
      setNewBg(false);
    }

  };


  useEffect(() => {
    if (sugRef && sugRef.current && isSelected ) sugRef.current.focus()
  }, [sugRef])

  useEffect(() => {
    setAddressValue(value.adress)
  }, [value]);

  let currentAddress = {...value};
  currentAddress.contact_persons = [...currentAddress.contact_persons];


  const getPersonName = () => currentAddress.contact_persons.length? currentAddress.contact_persons[0].full_name : '';
  const getPhone = () => currentAddress.contact_persons.length? currentAddress.contact_persons[0].phone : '';
  const getWorkingHours = () : {from: string, to: string} | string =>   {
    if (!currentAddress.working_hours.time_from && !currentAddress.working_hours.time_to) return '';
    return {from: currentAddress.working_hours.time_from, to: currentAddress.working_hours.time_to};
  }
  const getLunchHours = () : {from: string, to: string} | string =>   {
    if (!currentAddress.working_hours.lunch_from && !currentAddress.working_hours.lunch_to) return '';
    return {from: currentAddress.working_hours.lunch_from, to: currentAddress.working_hours.lunch_to}
  };


  const setPersonName = (value: string) => {
    console.log('setPersonName ', value)
    if (!currentAddress.contact_persons.length)
      currentAddress.contact_persons = [ new ContactPersons()];
    currentAddress.contact_persons[0].full_name = value;
    onChange({...currentAddress})
  }
  const setPhone = (value: string) => {
    if (!currentAddress.contact_persons.length)
      currentAddress.contact_persons = [ new ContactPersons()];
    currentAddress.contact_persons[0].phone = value;
    onChange({...currentAddress})
  };
  const setWorkingHours = (value: {from: string, to: string}) => {
    currentAddress.working_hours = {...currentAddress.working_hours}
    currentAddress.working_hours.time_from = value.from;
    currentAddress.working_hours.time_to = value.to;
    onChange({...currentAddress})
  };
  const setLunchHours = (value: {from: string, to: string}) => {
    currentAddress.working_hours = {...currentAddress.working_hours}
    currentAddress.working_hours.lunch_from = value.from;
    currentAddress.working_hours.lunch_to = value.to;
    onChange({...currentAddress})
  };
  const getFromToLabel= (   ) =>{
    if (!value.arrivalDate || !value.departureDate) return '';
     let from = moment(value.arrivalDate).format('HH:mm');
     let to = moment(value.departureDate).format('HH:mm');
    return `с ${from} до ${to}`;
  };

  const setWhatToDo= (  value : any) =>{
    onChange({...currentAddress, what_to_do: value})
  }

  //console.log('addressValue', addressValue);
  //console.log('value', value);
  return (
    <div
      className={cn(
        "address-point__item address-item",
        `address-item--${value.adress ? 'full' : type}`,
        `${isSelected || (type === EnumAddressType.info && isExpanded)? 'focused' : ''}`,
        { "address-item--empty": isEmpty },
        { "address-item--error": isError },
        { "address-item--newbg": newBg },
        { "dropdown-show": isExpanded }
      )}
    >
      <div className="address-item__icon">
        {type === 'from' && <ReactSVG src={ value.adress ?  FromIcon : WhiteIcon} />}
        {type === 'to' && <ReactSVG src={ value.adress ?  ToIcon : WhiteIcon} />}
        {type === 'info' && <PointIcon width={'12'} height={'12'} color={'#999'} className={"address-item__icon-point"} />}

      </div>
      <div className="address-item__content">
        <div className="address-item__input">

            <HereAddressSuggestions
              onElementCreated={(el:any)=>sugRef = el}
              onFocus={setInputFocus}
              onBlur={setInputBlur}
              value={addressValue}
              onChange={(addres: any) => {
                setAddressValue(addres.title);
                if (addres.address && addres.address.house ) {
                  setIsError(false);
                  onChange({...value, adress : addres.title, adress_latitude: addres.position.lat, adress_longitude: addres.position.lng, house: addres.address.house})
                } else setIsError(true);
                console.log("addres: ", addres);
              }} // (e) =>  dispatch(setAddressThunk(e, index))
              count={10}
              placeholder={type === "from" ? "Укажите откуда" : "Куда везти"}
              containerClassName="add-form__address input-wrap_address"
              inputClassName="input-wrap__input"
              listClassName="list-wrap_address_just"
              disabled={disableinput}
            />

        </div>
        {value.arrivalDate && <div className="address-item__time">{getFromToLabel()}</div>}
        <div className="address-item__error">
          Введите адрес с указанием дома или выберите его на карте
        </div>
      </div>

        <ReactSVG
            src={isExpanded ? optionDown : optionUp}
            className="address-item__expand-icon"
            onClick={toggleExpand}
        />

      <div className="address-item__tooltip"/>
      <div className="address-item__dropdown address-info">
        {type === EnumAddressType.info && <>
            <div className={"address-info-label"}>{value.contact_persons.length && value.contact_persons[0].full_name ? value.contact_persons[0].full_name : 'Контактное лицо не указано'}</div>
            <div className={"address-info-label"}>{value.contact_persons.length && value.contact_persons[0].phone ? value.contact_persons[0].phone : 'Номер телефона не указан'}</div>
          </>
        }
        {type !== EnumAddressType.info && <>
          <AdvancedInputElement text='Добавить контактное лицо'
                                altText='Контактное лицо'
                                values={getPersonName()}
                                setValues={setPersonName}
                                name={elementsEnum.person}
                                placeholder="ФИО" type="text"
                                />
          <AdvancedInputElement text='Указать номер телефона' altText='Контактный номер'
                                values={getPhone()}
                                setValues={setPhone}
                                name={elementsEnum.phone} placeholder="Укажите номер телефона" type="phone" />
          </>}
        <div className="address-info__row address-info__time">
          {type === EnumAddressType.info && <>
            {value.working_hours.time_from && <div className={"address-info-label"}>{value.working_hours.time_from + '-' + value.working_hours.time_to}</div>}
            {value.working_hours.lunch_from && <div className={"address-info-label"}>{value.working_hours.lunch_from + '-' + value.working_hours.lunch_to}</div>}
          </>
          }
            {type !== EnumAddressType.info &&
              <>
                  <div className="address-info__working-hours">
                    <ChooseTime
                      label="Время работы"
                      timeFromValue={value.working_hours.time_from}
                      timeToValue={value.working_hours.time_to}
                      onChange={setWorkingHours}
                    />
                  </div>
                  <div className="address-info__lunch">
                    <ChooseTime
                      label="Указать перерыв"
                      timeFromValue={value.working_hours.lunch_from}
                      timeToValue={value.working_hours.lunch_to}
                      onChange={setLunchHours}
                    />

                  </div>
              </>
            }
        </div>
        {type === EnumAddressType.info && <>
          {value.company && <div className={"address-info-label"}>Кому: {value.company}</div>}
        </>
        }
        {type !== EnumAddressType.info &&
          <div className="address-info__from-to">
          <div className="address-info__sender">

            <AdvancedInputElement text='От кого' altText='От кого' values={''}
                                  setValues={(value) => {
                                  }}
                                  name={elementsEnum.toWhom} placeholder="От кого" type="text"/>
          </div>
          <div className="address-info__addressee">
            <AdvancedInputElement text='Кому' altText='Кому' values={currentAddress.company}
                                  setValues={(value) => {
                                    onChange({...currentAddress, company: value})
                                  }}
                                  name={elementsEnum.toWhom} placeholder="Кому" type="text"/>
          </div>
        </div>
        }
        {type === EnumAddressType.info && value.what_to_do  &&
            <div className="address-info__what-to-do">
              <div className={"address-info-label"}>{value.what_to_do}</div>
            </div>
        }
        {type !== EnumAddressType.info &&
          <div className="address-info__what-to-do">
            <AdvancedInputElement text='Что нужно сделать на точке' altText='Задание на точке'
                                  values={currentAddress.what_to_do}
                                  setValues={(value) => setWhatToDo(value)}
                                  name={elementsEnum.whatToDo} placeholder="Что нужно сделать на точке" type="text"/>
          </div>
        }
        {type !== EnumAddressType.info &&
          <div className="address-info__delete">
            <button
              className="address-info__delete-button"
              type="button"
              onClick={()=> { if (onDelete) onDelete() } }
            >
              Удалить адрес
            </button>
          </div>
        }

      </div>
    </div>
  );
};
