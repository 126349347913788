import {AutoGreyButton} from "../../SelectCarManual/AutoGreyButton/AutoGreyButton";
import React from "react";
import {IBodyOptions} from "../../../../redux/reducers/system/system_types";

interface IProps {
    bodyOptions: IBodyOptions[],
    selectedBodyID:string,
    onBodySelect : (x:IBodyOptions)=>void;
}
const BlockSelectBodyOption:React.FC<IProps> = ({bodyOptions,selectedBodyID,onBodySelect}) =>{
    return (
        <>
            { bodyOptions.map( x=> <AutoGreyButton  key={"bo_"+x.id} type="gray" isSelected={x.id===selectedBodyID}
                                                    onClick={() => {onBodySelect(x)}}>{x.name}</AutoGreyButton>)}
        </>
    )
};

export default BlockSelectBodyOption;
