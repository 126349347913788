export interface IOrderProps {
    order: IOrderFull
}

export interface IOrderFull {
    id: string
    draft: false
    max_dimensions: false
    date: string
    contacts: {
        full_name: string
        phone: string
        email: string
    }
    comment: string
    body_option_id: string
    body_type_id: number
    tariff_type_id: string
    car_type_id: string
    additional_requirements: []
    body_option_characteristics: []
    routes: IRoute[]
    cargo: {
        places: [
            {
                size: {
                    weight: number
                    height: number
                    length: number
                    width: number
                }
            }
        ]
        pallets: []
        packages: []
    }
    vehicle: IVehicle
    driver: IDriver
    number: string
    status: string
    status_id: number
    amount: number
    distance: number
    payment_type:string
}

export interface IVehicle {
    model?: string
    number?: string
    car_type_id?: string
    body_option_id?: string
    length: number
    width: number
    height: number
    volume?: number
    max_weight: number
}

export interface IDriver {
    full_name: string
    phone: string
    rating?: number
    passport: {
        series: string
        number: string
        issued_by: string
        issue_date: string
        files: []
    }
    license: {
        series: string
        number: string
        issue_date: string
        issued_by: string
        files: []
    }
}

export interface IRoute {
    id: number
    adress: string
    adress_comment: string
    adress_longitude: number
    adress_latitude: number
    company: string
    what_to_do: string
    working_hours: {
        time_from: string
        time_to: string
        lunch_from: string
        lunch_to: string
        max_landing_time: string
        no_lunch: boolean
    }
    action_forwarder: boolean
    action_unloading: boolean
    action_loading: boolean
    action_documents: boolean
    contact_persons: [
        {
            full_name: ''
            phone: '+7 (977) 622-05-35'
            phone_ext: ''
        }
    ]
    files_ids: []
}

export interface ICoordinate {
    lon: number
    lat: number
}

export interface IStyledOrderRowCarProps {
    isGrey?: boolean
    isNeedBorderBottom?: boolean
    isFullHeightAmount?: boolean
}

export interface IRouteByOrderId {
    status: number
    error_code: number
    error_message?: string
    base_time: number
    traffic_time: number
    distance: number
    shapes: [string[]]
    points: IPointRoute[]
    points_to_draw: {
        base_time: number
        distance: number
        lat: number
        lng: number
        mkad_finish: boolean
        order_id: string
        region: string
        shape: [string]
        time_waiting: number
        traffic_time: number
    }[]
}
export interface IPointRoute {
    order_id: string
    lng: number
    lat: number
    time_waiting: number
    distance: number
    base_time: number
    traffic_time: number
    shape: string[]
    region: string
    arrival_base_time: string
    departure_base_time: string
    arrival_traffic_time: string
    departure_traffic_time: string
}

export interface IErorrResult {
    error_message: string
    status: string
}

export interface IOrder {
    adress:string[]
    amount: number
    car_body_option_id: string
    car_type_id: string
    date_from: string
    date_to:string
    id: string
    status: string
}

export enum EnumOrderListFilterType{
    Today ="Сегодня",
    Future = "Будущие",
    Past = "Прошлые"
}
