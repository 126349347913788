import {
    BodyOptionCharacteristicsResponse,
    CharacteristicTypeEnum,
    DropDownListItem,
    IBOCharacteristics,
    IBodyOptions,
    IFullCategory, MessageType
} from "./system_types";
import category1 from "../../../components/App/svg/carCategories/Category-1.svg";
import category1plus from "../../../components/App/svg/carCategories/Category-1-Plus.svg";
import category2 from "../../../components/App/svg/carCategories/Category-2.svg";
import category2plus from "../../../components/App/svg/carCategories/Category-2-Plus.svg";
import category3 from "../../../components/App/svg/carCategories/Category-3.svg";
import category4 from "../../../components/App/svg/carCategories/Category-4.svg";
import category5 from "../../../components/App/svg/carCategories/Category-5.svg";
import category6 from "../../../components/App/svg/carCategories/Category-6.svg";
import category7 from "../../../components/App/svg/carCategories/Category-7.svg";
import {serverAPI} from "../../../api/server";
import {IAdditionalRequirements, IPackageType, IPalletType, ITariffInfo} from "../slices/CreateOrderType";
import {
    setAdditionalRequirements,
    setBaseTariffTypes,
    setCategories,
    setDataLoading,
    setPackageTypes,
    setPalletTypes,
    setSystemMessage
} from "./system_reducers";
import {loadProfileByIdThunk, setClientId, setEnteredPhone, setIsAuth} from "../slices/userProfileSlice";
import {setOrderDataAuto, setOrderDataManual} from "../slices/CreateOrderSlicer";
import {cookiesAccountId, cookiesClientPhone} from "../../../deployment";

export const  getBodyOptionsList  = (categories_full : IFullCategory[]) : IBodyOptions[] => {
    let body_options : IBodyOptions[] = [];
    categories_full.forEach( category=> {
        body_options = body_options.concat([...category.body_options.filter( x => !body_options.find(z=> z.id === x.id) )])
    });

    body_options.forEach(x=> {
        x.name = x.name !== "ЦМФ" ? x.name.toUpperCase()[0] + x.name.toLowerCase().substring(1,x.name.length) :x.name;
    });
    return  body_options;
};


export const  getBOCharacteristics  = (categories_full : IFullCategory[], category_id: string, body_id: string) : BodyOptionCharacteristicsResponse[] => {


    let body_option_characteristics: BodyOptionCharacteristicsResponse[] = [];
    // body_options.forEach( body=> {
    //     body_option_characteristics = body_option_characteristics.concat(
    //         [...body.body_option_characteristics.filter( x => !body_option_characteristics.find(z=> z.id === x.id) )])
    // });
    categories_full.filter( category=> category.id == category_id || category_id == '')
        .forEach( category=> {

            category.body_options.filter( x => x.id==body_id || body_id == '' )
                .forEach( body=> {
                    body_option_characteristics = body_option_characteristics.concat(
                        [...body.body_option_characteristics.filter( x => !body_option_characteristics.find(z=> z.id === x.id) )])
                });

        });
    return body_option_characteristics;
};


export const  getCharacteristicValueList = (categories : IFullCategory[], characteristic:IBOCharacteristics, bodyID:string, categoryID:string) : DropDownListItem[] => {
    let dd : DropDownListItem[] = [{id:'', text :'Любой'}];
    categories.filter(x=> x.id === categoryID || categoryID === '')
        .forEach(category => {
            category.body_options.filter( bo=> bo.id === bodyID || bodyID ==='')
                .forEach( bo=> {
                    bo.body_option_characteristics.filter(ch => ch.id === characteristic.id)
                        .forEach(ch=> {
                            ch.body_option_characteristics_values.forEach(v=> {
                                if (!dd.some(lv=>lv.id === v.id)) dd.push({id:v.id, text:v.name});
                            })
                        })
                })
        });
    return dd;
};


export const getCategoryImage = (id:string) => {
    switch (id) {
        case "52b30be1-49d5-11e7-9696-e41f13c2b942" : return category1;
        case "bb0df94d-7dde-11e8-81b0-e41f13c2b942" : return category1plus;
        case "52b30bdd-49d5-11e7-9696-e41f13c2b942" : return category2;
        case "52b30bde-49d5-11e7-9696-e41f13c2b942" : return category2plus;
        case "52b30bdc-49d5-11e7-9696-e41f13c2b942" : return category3;
        case "52b30bdf-49d5-11e7-9696-e41f13c2b942" : return category4;
        case "52b30be0-49d5-11e7-9696-e41f13c2b942" : return category5;
        case "52b30be2-49d5-11e7-9696-e41f13c2b942" : return category6;
        case "52b30be4-49d5-11e7-9696-e41f13c2b942" : return category7;
        default: return category1;
    }
};

export const filterCategory = (categories: IFullCategory[], category_id:string, body_id:string, activeCharacteristics:IBOCharacteristics[] ) => {

    let m_categories : IFullCategory[] = JSON.parse(JSON.stringify(categories));
    m_categories = m_categories.filter(category => !category_id || category.id === category_id)
        .filter(category => {

            category.body_options = category.body_options.filter(bo=> !body_id || bo.id === body_id)
                .filter(car_body => {
                    if (activeCharacteristics.length === 0) return true;
                    let tmp = car_body.body_option_characteristics.filter(character => {
                        let index = activeCharacteristics.filter(active_ch => active_ch.id === character.id)
                            .findIndex(active_ch => {
                                    if (active_ch.type !== CharacteristicTypeEnum.Ref) return true;
                                    let selectedValue = character.body_option_characteristics_values.find(val => val.id === active_ch.value);
                                    return !!selectedValue;

                                }
                            );
                        return index >= 0;
                    });
                    return tmp.length >= activeCharacteristics.length;
                });
            return category.body_options.length
        });

    return m_categories;
};

export const loadInitDataThunk = async (dispatch: any, cookies: any, setCookie:any) => {

    dispatch(setDataLoading(true));

    let categories_full : IFullCategory[]  = await serverAPI.getCategoriesFull().catch( () => dispatch(setSystemMessage({msg_type: MessageType.Error, message : 'Ошибка загрузки категорий технических средств!'})));
    if (!Array.isArray(categories_full)) categories_full = [];
    dispatch(setCategories(categories_full));

    let tariff_list :ITariffInfo[] =  await serverAPI.getTariffTypes().catch( () => dispatch(setSystemMessage({msg_type: MessageType.Error, message : 'Ошибка загрузки перечня доступных тарифов!'})));
    if (!Array.isArray(tariff_list)) tariff_list = [];
    dispatch(setBaseTariffTypes(tariff_list));

    let pallet_types : IPalletType[] = await serverAPI.getPalletTypes().catch( () => dispatch(setSystemMessage({msg_type: MessageType.Error, message : 'Ошибка загрузки перечня видов паллетов!'})));
    if (!Array.isArray(tariff_list)) pallet_types = [];
    pallet_types=[{name:'Любой', id:'',  length: 0, width:  0, manual: false}].concat(pallet_types);
    dispatch(setPalletTypes(pallet_types));

    let package_types : IPackageType[] = await serverAPI.getPackageTypes().catch( () => dispatch(setSystemMessage({msg_type: MessageType.Error, message : 'Ошибка загрузки перечня видов упаковок!'})));
    if (!Array.isArray(tariff_list)) package_types = [];
    package_types=[{name:'Любой', id:'' }].concat(package_types );
    dispatch(setPackageTypes(package_types));

    let additional : IAdditionalRequirements[] = await serverAPI.getAdditionalTypes().catch( () => dispatch(setSystemMessage({msg_type: MessageType.Error, message : 'Ошибка загрузки перечня дополнительных услуг!'})));
    if (!Array.isArray(additional)) additional = [];
    dispatch(setAdditionalRequirements(additional ));

    console.log('cookies', cookies);
    if (cookies.clientId) {
        dispatch(setClientId(cookies.clientId));
        let res = await serverAPI.authMe(cookies.clientId);
        if (res && res.status && res.status !== 'error') {
            let selected_account =  await loadProfileByIdThunk(cookies.clientId, dispatch, cookies.selectedUserId, cookies.clientPhone);
            dispatch(setIsAuth(true));
            setCookie(cookiesAccountId,selected_account ? selected_account : '', { path: '/' });
        }
    }


    if (cookies.orderId) {
        let data = await  serverAPI.getOrderById(cookies.orderId);
        if (data && typeof data == 'object' ) {
            console.log('data = ', data);
            if (data.draft) {
                if (data.cargo.places.length || data.cargo.packages.length || data.cargo.pallets.length)
                    dispatch(setOrderDataAuto(data))
                else
                    dispatch(setOrderDataManual(data))
            }
        }
    }

    dispatch(setDataLoading(false));
};

export const showErrorMessage = (message:string, dispatch:any) => { dispatch(setSystemMessage({ msg_type: MessageType.Error, message}))};
export const showInfoMessage = (message:string, dispatch:any) => { dispatch(setSystemMessage({ msg_type: MessageType.Info, message}))};


export const groupBy = <T, K extends keyof any>(  list: T[],   getKey: (item: T) => K ) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem)
        if (!previous[group]) previous[group] = []
        previous[group].push(currentItem)
        return previous
    }, {} as Record<K, T[]>)


export const getActiveCharacteristics = (chs : {id:string, value:boolean|string}[], categories_full : IFullCategory[]) : BodyOptionCharacteristicsResponse[] => {
    let arr : BodyOptionCharacteristicsResponse[] = [];
    let all = getBOCharacteristics(categories_full, '', '' );
    chs.filter(x=> x.value != 'false' && x.value != false && +x.value != 0)
        .forEach(x=> {
            let v = all.find(f=> f.id == x.id  );
            if (v)
                arr.push({...v, value : x.value, selected:true})
        })
    return arr;
}
