import { ReactSVG } from "react-svg";
import checkIcon from "../../App/svg/check-box-check.svg";
import "./CustomCheckBox.css";

interface IProps {
    onChange:(val:boolean)=>void
}

export const CustomCheckBox: React.FC<IProps> = (props) => {
  return (
    <div className="custon-checkbox">
      <label className="custon-checkbox__label">
        <input className="custon-checkbox__input" type="checkbox" onChange={(e)=>props.onChange(e.target.checked)} />
        <span className="custon-checkbox__check">
          <ReactSVG src={checkIcon} />
        </span>
        <div className="custon-checkbox__text">{props.children}</div>
      </label>
    </div>
  );
};
