import {CargoInfo, CargoTypes, IPackage, IPackInfo, IPallet} from "./CreateOrderType";
import {IBOCharacteristics, IFullCategory} from "../system/system_types";
import {serverAPI} from "../../../api/server";
import {filterCategory} from "../system/system_functions";

export const getCargoList = (cargo:CargoInfo) =>{
    let list :CargoTypes[] = [];
    cargo.places.forEach(x=> list.push(x));
    cargo.pallets.forEach(x=> list.push(x));
    cargo.packages.forEach(x=> list.push(x));
    return list;
};

export const getCargoObject = (list:CargoTypes[], max_dimensions :boolean) =>{
    let cargo = new CargoInfo();
    cargo.max_dimensions = max_dimensions;
    list.forEach( c=> {
        let x = {...c, size:{...c.size}};
        if (x.hasOwnProperty('package_type_id')) cargo.packages.push((x as IPackage)); else
        if (x.hasOwnProperty('pallet_type_id')) cargo.pallets.push((x as IPallet)); else
            cargo.places.push(x)
    });
    return cargo;
};


export const getPackInfo = async (list:CargoTypes[],  body_option_id:string = "",
                                  body_option_characteristics:IBOCharacteristics[],
                                  categories: IFullCategory[], max_dimensions :boolean) => {
    if (list.length === 0) {
        return new Promise<IPackInfo>( resolve => {
            resolve({ car_type_id: '',
                car_types_id: [] ,
                error: false,
                error_description:  '',
                height: 0,
                load_by_area: 0,
                load_by_volume: 0,
                load_by_weight: 0,
                packed_items:[],
                total_area: 0,
                total_volume: 0,
                total_weight: 0,
                width: 0})
        })
    }
    let cargo = getCargoObject(list, max_dimensions);

    if (!body_option_id && categories.length > 0) {
        categories = filterCategory(categories, "","",body_option_characteristics);
        let cat = categories.find((x)=> x.body_options.length > 0);
        if (cat) body_option_id = cat.body_options[0].id;
    }
    return  serverAPI.getPackInfo({cargo, body_option_id, body_option_characteristics})
};
