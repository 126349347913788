import React, {ChangeEvent, FC, useCallback, useEffect} from 'react'
import './OrderPaymentMethod.css'
import AddCardPayment from '../../../images/AddCardPayment.svg'
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {CCompany} from "../../../redux/reducers/slices/userProfile.type";
import {currentOrderData, setOrderINN, setOrderPaymentType} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {EnumPaymentType} from "../../../redux/reducers/slices/CreateOrderType";
import SelectCompany from "./SelectCompany/SelectCompany";


export const OrderPaymentMethod: FC<{ }> = ({ }) => {

    const dispatch = useAppDispatch()
    const userProfile  = useAppSelector(userProfileSelector);
    const { isAuth } = useAppSelector(userProfileSelector);

    const company : CCompany | undefined = userProfile.profile.companies.find(x=> x.id == userProfile.selected_company_id);

    const currentOrder = useAppSelector(currentOrderData)



    const onAddCardClick = useCallback(() => {
        alert('данная опция пока не доступна')
    }, [])

    useEffect(()=>{
        if (isAuth && company)
            dispatch(setOrderINN(company.tin))
    }, [])


    return (
        <div className='paymentMethod'>
            <div className='paymentButtons'>
                <div className={currentOrder.payment_type === EnumPaymentType.PaymentOnline ? 'selected' : 'unselected'} onClick={() => dispatch(setOrderPaymentType(EnumPaymentType.PaymentOnline))}>
                    Оплата онлайн
                </div>
                <div className={currentOrder.payment_type  === EnumPaymentType.PaymentCash ? 'selected' : 'unselected'} onClick={() => dispatch(setOrderPaymentType(EnumPaymentType.PaymentCash))}>
                    Наличными
                </div>
                <div className={currentOrder.payment_type  === EnumPaymentType.PaymentOnAccount ? 'selected' : 'unselected'} onClick={() => dispatch(setOrderPaymentType(EnumPaymentType.PaymentOnAccount))}>
                    По счету
                </div>
            </div>
            <div className='paymentData'>
                <div className='card' hidden={currentOrder.payment_type  !== EnumPaymentType.PaymentOnline } onClick={onAddCardClick}>
                    <span>Добавить карту</span>
                    <img alt="Добавить карту" src={AddCardPayment} />
                </div>
                <div className='cash' hidden={currentOrder.payment_type  !== EnumPaymentType.PaymentCash }>
                    Отдайте оплату за заказ наличными в руки исполнителю (грузчику)
                </div>
                <div className='INN' hidden={currentOrder.payment_type  !== EnumPaymentType.PaymentOnAccount }>

                    <SelectCompany/>

                </div>
            </div>
        </div>
    )
}
