import {useEffect, useRef, useState} from 'react';
import "./HereAddressSuggestions.css"
import { hereComAPI } from '../../../../api/here-api';
import { daDataAPI } from '../../../../api/dadata-api';


// interface HereAddressSuggestionsProps {
// 	value: string;
// 	onChange: (arg0: any)=>void;
// 	onFocus:()=>void;
// 	count: number;
// 	placeholder: string;
// 	containerClassName: string;
// 	inputClassName: string;
// 	listClassName?: string;
// }

const HereAddressSuggestions  = ({value, onChange, onFocus, onBlur, count,  placeholder, containerClassName, inputClassName, listClassName = "", disabled = false, onElementCreated})  => {

	const [addressList, setAddressList] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);	
	const refInput = useRef();
	
	useEffect(()=>{if(value==="") {refInput.current.value = ""} else {refInput.current.value = value}}, [value])
	useEffect(()=>{if(refInput && refInput.current && onElementCreated) onElementCreated(refInput) }, [refInput])

	const [localAddress, setLocalAddress] = useState({
		position: '',
		title: '',
		is_here:false
	});
	
	const isCorrectAddress = async (q, is_here) => {
		let value = false;
		if (is_here) {
			let response = await hereComAPI.getAddressByName(q, 1);
			value = response && response.data && response.data.items && response.data.items.length;

		} else {
			let response = await daDataAPI.getAddressByName(q, 1);
			value = response && response.data && response.data.suggestions && response.data.suggestions.length;
		}
		return new Promise(resolve => resolve(value))
	};

	const selectAddress = async (address) =>{
		if (!address) return ;
		setLocalAddress(address);
		setAddressList([]);
		if (!address.position) {
			address.error = true;
			refInput.current.value = '';
		} else {
			let isCorrect = await isCorrectAddress(address.title, address.is_here);
			address.error = !isCorrect;
			refInput.current.value = address.title;
		}
		if (onChange)
			onChange(address);
		refInput.current.focus();
	};

	const loadAddressList = async (q ) =>{
		let listDaData = [];
		if (!q.trim()) return ;

		let responseDaData = await daDataAPI.getAddressByName(q, 10);
		if (responseDaData && responseDaData.data && responseDaData.data.suggestions && responseDaData.data.suggestions.length) {
			listDaData = responseDaData.data.suggestions.map( (x) => ({position: {lat: x.data.geo_lat, lng: x.data.geo_lon}, title:x.value, address: x.data, is_here:false}));
		}

		if (listDaData.length) {
			if (listDaData.length === 1) {
				let correct = await isCorrectAddress(q, false);
				if (correct) return setAddressList(listDaData);
			} else return setAddressList(listDaData);
		}
		
		let listHERE = [];
		let responseHere = await hereComAPI.getAddressByName(q);
		if (responseHere && responseHere.data && responseHere.data.items && responseHere.data.items.length)
			listHERE = responseHere.data.items.map( (x) => ({position:x.position, title:x.title, address: x.address, is_here:true}));

		if (listHERE.length) {
			if (listHERE.length === 1) {
				let correct = await isCorrectAddress(q, true);
				if (correct) return  setAddressList(listHERE);
			} else return  setAddressList(listHERE);
		}
		setAddressList([{position:null, title:"Адрес не найден - пожалуйста, укажите его на карте!", address: null}]);
	};

	return (
			<>
				<div className={containerClassName ? containerClassName : "autoSuggestValue"}>
					<input ref={refInput}
						onChange={(e)=>{loadAddressList(e.target.value)}}
						onFocus={onFocus}
						onBlur={(e)=>{onBlur(e.target.value)}}
						onKeyUp={(e)=> {
							if (e.key === "ArrowDown" && selectedRow < (addressList.length-1) )
								setSelectedRow(selectedRow+1);
							if (e.key === "ArrowUp" && selectedRow >0) setSelectedRow(selectedRow-1);
							if (e.key === "Enter" ) selectAddress(addressList[selectedRow]);
						} }
						disabled={disabled}
						placeholder={placeholder ? placeholder : 'Введите адрес ...'}
						className={inputClassName ? inputClassName : ''}						   
					/>
					<div className={addressList.length? 'listAutoSuggest ' + listClassName : 'listAutoSuggestHide'}
						 onMouseLeave={ (e) => {
							 setAddressList([])
							 selectAddress(localAddress)
						 }}
					>
						{
							addressList.length &&
							addressList.map( (x,index)=>
								<div key={'rowItem' + index} className={"rowItem" + (selectedRow === index ? ' selectedAddress' : '') }
									 onMouseOver={ (e) => setSelectedRow(index)}
									 onClick={(e)=>{ selectAddress(x) }}>{x.title}</div>
							)
						}
					</div>
				</div>


			</>
		)
};

export default  HereAddressSuggestions
