import cn from "classnames";

import { ReactSVG } from "react-svg";
import optionToggler from "../../../App/svg/option.svg";
import checked from "../../../App/svg/check.svg";
import "./Tariff.css";
import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import {useAppSelector} from "../../../../redux/hooks";
import {systemSelector} from "../../../../redux/reducers/system/system_reducers";
import {CTariffData} from "../../../../redux/reducers/slices/CreateOrderType";

interface TariffProps {
  loading: boolean;
  data:  CTariffData;
  onSelect: (arg0: string) => void;
  selected?: boolean;
}

export const Tariff: React.FC<TariffProps> = ({
  loading,
  data,
  onSelect,
  selected = false,
}) => {
  const [isSelected, setIsSelected] = useState(selected);
  const [showDropdown, setShowDropdown] = useState(false);
  const {  tariff_types } = useAppSelector(systemSelector);

  const tariff_name = data ?  tariff_types.find(x => x.id === data.tariff_type_id) : undefined;
  useEffect(() => {
    if (isSelected && data) {
      onSelect(data.tariff_type_id);
    }
  }, [isSelected]);

    return (
      <div
        className={cn(
          "tariff-item",
          {
            "tariff-item--show-dropdown": showDropdown,
          },
          {
            "tariff-item--selected": isSelected,
          }
        )}
      >
        <div className="tariff-item__header tariff-header"  onClick={() => {if (!loading)  setShowDropdown((prevState) => !prevState)}}>
          <label className="tariff-header__check">
            <input
              type="checkbox"
              className="tariff-header__check-box"
              checked={isSelected}
              onChange={() => {
                setIsSelected((prevState) => !prevState);
                setShowDropdown(false);
              }}
            />
            <span className="tariff-header__check-icon">
              <ReactSVG src={checked} />
            </span>
          </label>
          <div className="tariff-header__description">
            <div className="tariff-header__name">{tariff_name ? tariff_name.name : loading ? 'загрузка данных' : ''}</div>
            <div className="tariff-header__url">
              <a href={"/"} target="_blank" rel="noreferrer">
                Подробные условия
              </a>
            </div>
          </div>
          {loading &&  <Puff color={ isSelected ? "#fff" : "#5364ff"} width={"3rem"} height={"3rem"} />}
          {!loading && <div className="tariff-header__price">
            <span>{data ? data.cost : ''} ₽</span>
            <div
              className="tariff-header__dropdown"
              // onClick={() => setShowDropdown((prevState) => !prevState)}
            >
              Детализация
              <ReactSVG
                src={optionToggler}
                className="tariff-header__dropdown-toggler"
              />
            </div>
          </div> }
        </div>
        <div className="tariff-item__dropdown tariff-content">
          <div className="tariff-content__item">
            <div className="tariff-content__header">Тариф</div>
            <ul className="tariff-content__list tariff-content__list--extended">
              <li className="tariff-content__list-item">
                <div className="tariff-content__list-item--description">
                  <div className="tariff-content__list-item--legend">
                    Тариф "{tariff_name ? tariff_name.name : ''}""
                  </div>
                  {data ? data.rate : ''}, стоим. часа
                </div>
                <div className="tariff-content__list-item--price">
                  {data ? data.cost_by_hour : ''} ₽ с ндс
                </div>
              </li>
              <li className="tariff-content__list-item">
                <div className="tariff-content__list-item--description">
                  <div className="tariff-content__list-item--legend">
                    Мин., время
                  </div>
                  {data ? data.min_hours : ''} часов | стоимость
                </div>
                <div className="tariff-content__list-item--price">
                  {data ? data.min_cost : ''} ₽ с ндс
                </div>
              </li>
              <li className="tariff-content__list-item">
                <div className="tariff-content__list-item--description">
                  <div className="tariff-content__list-item--legend">
                    Планируемое время
                  </div>
                  {data ? data.hours : ''} | стоимость
                </div>
                <div className="tariff-content__list-item--price">
                  {data ? data.cost : ''} ₽ с ндс
                </div>
              </li>
            </ul>
          </div>
          {data && data.items  && data.items.length >0 && (
            <div className="tariff-content__item">
              <div className="tariff-content__header">
                Дополнительные услуги
              </div>
              <ul className="tariff-content__list">
                {data && data.items.map((item, index) => (
                  <li className="tariff-content__list-item" key={'tariff-content__list-item_'+index}>
                    <div className="tariff-content__list-item--description">
                      {item.name}
                    </div>
                    <div className="tariff-content__list-item--price">
                      {item.cost} ₽
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {data && data.items_by_route &&  data.items_by_route.length>0 &&
            <div className="tariff-content__item">
              <div className="tariff-content__header">По маршруту</div>
              <ul className="tariff-content__list">
                {data.items_by_route.map((item, index) => (
                  <li className="tariff-content__list-item" key={'tariff-content__list-item2_'+index}>
                    <div className="tariff-content__list-item--description">
                      {item.name}
                    </div>
                    <div className="tariff-content__list-item--price">
                      {item.cost} ₽
                    </div>
                  </li>
                ))}
              </ul>
            </div>
           }
        </div>
      </div>
    );

};
