import React, {FC, useState} from 'react'
import s from './hereMap.module.scss'
import { useEffect } from 'react'
import {
  calculateRoute, createDomMarker,
  drawWayOnMap, getBearing,
  hereMarker,
  is_way_point_changed,
  removeObjectFromMap,
  setDraggable
} from '../HereMap/hereFunctions'
import {hereMapApiKey} from "../../../deployment";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {TypeLonLat} from "../../../redux/reducers/slices/CreateOrderType";
import {hereMapSelector} from "../../../redux/reducers/slices/hereMapSlice";
import {HereMarkerIconCar} from "./hereMarkerIcon";
import {EnumMarkerType, mapMarker, mapRoute} from "../../../redux/reducers/slices/hereMapTypes";
import {daDataAPI} from "../../../api/dadata-api";
import {currentOrderData, setAddressFromDaData} from "../../../redux/reducers/slices/CreateOrderSlicer";




let hereMap: H.Map,
    platform: H.service.Platform;

type propTypes = {
  onClick: (lon: number, lat: number) => void
  onMarkerClick: (index: number) => void
  onMarkerMove: (index: number, point: TypeLonLat) => void
  markers: Array<mapMarker>
  routs: mapRoute[]
}

const HereMap: FC<propTypes> = ({ onClick, onMarkerClick, markers, routs, onMarkerMove }) => {
  const dispatch = useAppDispatch();
  console.log('markers ', markers, routs)
  const mapRef = React.useRef<HTMLHeadingElement>(null);
  const [lastCarPosition, setLastCarPosition] = useState<TypeLonLat|undefined>(undefined);
  const {car_position} = useAppSelector(hereMapSelector);
  const order = useAppSelector(currentOrderData);

  useEffect(() => {

    const H = window.H;
    platform = new H.service.Platform({
      "apikey": hereMapApiKey
    })
    const defaultLayers = platform.createDefaultLayers({
      lg: 'ru_RU'
    })

    if (!mapRef.current) return
    
    hereMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 55.754638, lng: 37.621633 },
      zoom: 12,
      pixelRatio: window.devicePixelRatio || 1
    })
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hereMap))
    const ui = H.ui.UI.createDefault(hereMap, defaultLayers, 'ru-RU')

    const zoom = ui.getControl('zoom')
    const mapSettings = ui.getControl('mapsettings')
    ui.removeControl('scalebar')

    // mapSettings.setAlignment('right-middle')
    // zoom.setAlignment('right-middle')

    window.addEventListener('resize', () => hereMap.getViewPort().resize())

    // добавляем обработчик нажатия на карту

    hereMap.addEventListener('tap', (evt: any) => {
      const target = evt.target;
      const coord = hereMap.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY)
      if (target instanceof H.map.DomMarker) {
        // возвращаем индекс маркера
        onMarkerClick(target.getData())
      } else {
        // возвращаем координаты         
        onClick(coord.lng, coord.lat)
      }
    }, true);

    setDraggable(hereMap, behavior, async (index, point) => {
      // let address = await daDataAPI.getAddressByCoords(point);
      onMarkerMove(index, point);
      // setAddressFromDaData(order.routes, point, index, dispatch);
      // afterDrag(map, window.points, platform, dispatch);
      // console.log(`index=${index}, point=`,point, address)
      // setAddressFromDaData(order.routes, )
    });

    // добавляем поведение перетаскивания маркеров

    // setDraggable(hereMap, behavior, onMarkerClick, onClick)
      
    // проход отрисовывает массив маршрутов

  }, [mapRef])

  // эфект отрисовывает маркеры

  useEffect(() => {

    console.log('HEREMAP markers ', markers);
    hereMap.removeObjects(hereMap.getObjects().filter((obj: H.map.Object) => {
      // return !(obj instanceof window.H.map.Group)
     return  (obj instanceof window.H.map.DomMarker || obj instanceof window.H.map.Marker)
    }))

    markers.forEach((marker: mapMarker) => {
      if (!marker.lat) {
        return
      }
      const markerItem = hereMarker([marker.lat, marker.lon], marker)
      hereMap.addObject(markerItem)
    })
    let mrks= markers.filter( (obj )=> obj.lat);
    if (mrks.length === 1)
      hereMap.setCenter({lat:mrks[0].lat, lng:mrks[0].lon });
  }, [markers])

  // эфект отрисовывает маршруты

  useEffect(() => {
    let remove_old_way = () =>
        hereMap.removeObjects(hereMap.getObjects().filter( (obj: H.map.Object) => !(obj instanceof window.H.map.DomMarker  || obj instanceof window.H.map.Marker)));
    if (routs.length ===0 ) remove_old_way();
    console.log('HEREMAP routs removeObjects');

    routs.forEach((element,index) => {
      console.log('HEREMAP routs element', element);
      if (element.type === 0) {
        let cc = element.coordinate.map(el => `${el.lat},${el.lon}`);
        if (is_way_point_changed(cc)) {
          if (index===0) remove_old_way();
          calculateRoute(platform, cc, hereMap, element.color, element.width)
        }
      }
      else {
        if (index===0) remove_old_way();
        drawWayOnMap(element.coordinate, element.color, element.width, hereMap)
      }
    })
    
  }, [routs]);

  useEffect(() => {
    removeObjectFromMap(hereMap, EnumMarkerType.CarPosition);
    if (!car_position) return;
    let bearing = 0 ;
    if (car_position.lat != lastCarPosition?.lat || car_position.lon != lastCarPosition?.lon){
      if (lastCarPosition)
        bearing = getBearing(lastCarPosition, car_position);
      setLastCarPosition(car_position)
    }

    hereMap.addObject(createDomMarker([car_position.lat, car_position.lon], 0, HereMarkerIconCar(bearing),  EnumMarkerType.CarPosition));
  }, [car_position]);

  return <div className={s.map} ref={mapRef} />
}




export default HereMap;
