import React, {useRef, useState} from "react";
import InputMask from "react-input-mask";
import {AddressSuggestions, BankSuggestions} from "react-dadata";
import './UserProfile.css'
import {
    CEditOneRecordData,
    EnumEditOneRecordType,
    validateEmail
} from "../../../redux/reducers/slices/userProfile.type";
import { CloseIcon } from "../../Elements/CloseIcon/CloseIcon";
import { Inn } from "../../Elements/OrderPaymentMethod/Inn/Inn";
import { ProfileAddress } from "./ProfileAddress";



interface IProps {
    value:CEditOneRecordData
}
const ProfileEditOneRecord : React.FC<IProps> = ({value}) =>{

    const addressRef = useRef<AddressSuggestions>(null);
    const [currentValue,setCurrentValue] = useState<string>(value.value);
    const [isNotAddressEditMode,setNotAddressEditMode] = useState<boolean>(true);
    const [currentValueINN,setCurrentValueINN] = useState<{inn:string, name:string}>({inn:value.inn ? value.inn : '', name: value.value});

    const checkIsInnValid = () => {
        return currentValueINN.inn && currentValueINN.name && (currentValueINN.inn.length == 10 || currentValueINN.inn.length==12)
    }

    const checkIsPhoneValid = (phone: string) => {
        const PHONE_PATTERN = /[+]*[7-8]{1}\s?[(]*9[0-9]{2}[)]*\s?\d{3}[-]*\d{2}[-]*\d{2}/
        if (!phone) return undefined
        return PHONE_PATTERN.test(phone)
    };

    const checkIsNameValid = (name: string) => {
        if (name.length < 5) return false;
        // let words = name.trim().split(' ');
        // if (words.length < 3) return false;
        // for (let i = 0; i < words.length; i++) {
        //     if (words[i] === '') return false;
        //     if (i < 3 && !/[А-Я-]/.test(words[i][0])) return false;
        //     if (i >= 3 && !/[а-яА-Я]/.test(words[i][0])) return false;
        //     if (/[^а-я-]/.test(words[i].slice(1))) return false;
        // }
        return true;
    };




    const canSave = () => {
        if ([EnumEditOneRecordType.name,  EnumEditOneRecordType.position, EnumEditOneRecordType.company_site].some(x=>x==value.type)) return checkIsNameValid(currentValue);
        if ([EnumEditOneRecordType.phone, EnumEditOneRecordType.work_phone, EnumEditOneRecordType.company_phone].some(x=>x==value.type)) return checkIsPhoneValid(currentValue);
        if (value.type == EnumEditOneRecordType.add_company) return checkIsInnValid();
        if ([EnumEditOneRecordType.email, EnumEditOneRecordType.company_email].some(x=>x==value.type)) return validateEmail(currentValue);
        if ([EnumEditOneRecordType.company_address].some(x=>x==value.type)) return  currentValue.length > 10;
    };
    console.log(value.type, )
    return (
        <>
            <div className="profile_edit_one_record">
                <div className="profile_edit_one_record_window">
                    <div className="title">
                        <div className="inner-text">
                            <div>{value.window_header}</div>
                            <CloseIcon onClick={(e)=>{e.preventDefault(); value.callback(undefined, EnumEditOneRecordType.none)}} className={"menu-form-close"}/>
                        </div>
                    </div>
                    <div className="content">
                        {([ EnumEditOneRecordType.name,  EnumEditOneRecordType.email,
                             EnumEditOneRecordType.position, EnumEditOneRecordType.company_site,
                            EnumEditOneRecordType.company_email
                        ].some(x=> x==value.type)) &&
                            <input  placeholder={value.placeholder} value={currentValue} onChange={(e)=> setCurrentValue(e.target.value)}/>
                        }

                        {[EnumEditOneRecordType.phone, EnumEditOneRecordType.work_phone, EnumEditOneRecordType.company_phone].some(x=>x==value.type) &&
                            <InputMask className={''} mask='+7\9999999999' value={currentValue} onChange={(e)=> setCurrentValue(e.target.value)} placeholder={value.placeholder} name='phone' />
                        }

                        {value.type == EnumEditOneRecordType.add_company &&
                            <Inn onChange={(inn, companyName) => setCurrentValueINN({inn:inn ? inn :'' , name: companyName ? companyName :''})}/>
                        }

                        {
                            value.type == EnumEditOneRecordType.company_address &&
                            <>
                                <ProfileAddress onChange={(value)=> setCurrentValue(value!=undefined ? value : '') }  placeholder={'Почтовый адрес компании'} defaultValue={value.value}/>
                                <div className="address_edit_mode">{currentValue ? currentValue: ''}</div>
                            </>

                        }

                    </div>
                    {value.type == EnumEditOneRecordType.add_company &&
                        <div className="content-subtitle">

                            Создавая компанию, вы становитесь её представителем
                        </div>
                    }
                    <div className="button-area">
                        <button onClick={()=>{
                                                console.log('click', value.type, value.type == EnumEditOneRecordType.add_company, currentValueINN )
                                                if (!value.type || (
                                                    [EnumEditOneRecordType.name, EnumEditOneRecordType.email, EnumEditOneRecordType.phone,
                                                        EnumEditOneRecordType.work_phone, EnumEditOneRecordType.position, EnumEditOneRecordType.company_address,
                                                        EnumEditOneRecordType.company_site, EnumEditOneRecordType.company_email, EnumEditOneRecordType.company_phone
                                                    ].some(x=> x==value.type)))
                                                    value.callback(currentValue, value.type);
                                                else
                                                if (value.type === EnumEditOneRecordType.add_company){
                                                    console.log('click', value, currentValueINN);
                                                    value.callback(currentValueINN, value.type);
                                                }


                                              }
                                        }
                                disabled={!canSave()}
                        >Сохранить</button>
                    </div>
                </div>
            </div>
        </>
    )
}




export default ProfileEditOneRecord;
