import {IPointOnMap} from "../system/system_types";

export enum EnumMarkerType{ None="NONE", MainDraggable="MARKER_1", MainNotDraggable="MARKER_2", OrderNotDraggable="MARKER_3", CarPosition="MARKER_CAR" }
export class mapMarker   {
    lon: number = 0;
    lat: number = 0;
    type: EnumMarkerType = EnumMarkerType.None;
    index: number = 0;
    title: string = '';
    text: string = '';
    is_show_text: boolean = false;
    bg_color: string= '';
}
  
export class mapRoute   {
    coordinate: IPointOnMap[] = [];
    polylineArray: string[] =[];
    color : string = 'rgba(66, 170, 255, 0.8)';
    width : number = 5;
    type: number = 1;
}
  
export type routeRequestParamsType = {
    routingMode: string
    transportMode: string
    origin: string | undefined
    destination: string | undefined
    return: string
    via: string | undefined
}




export const  MARKER_4 = 'MARKER_4';
