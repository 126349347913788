export class CUserProfile {
    users: CUser[] = [];
    companies: CCompany[] = [];
}

export class CUser {
    id: string = "";
    phone: string = "";
    full_name: string = "";
    email: string = "";
    position: string = "";
    workPhone: string = "";
    company_id: string = "";
    constructor(full_name: string = "", phone: string = "", email: string = ""){
        this.phone = phone;
        this.email = email;
        this.full_name = full_name;
    }
}

export class CCompany {
    id: string = "";
    name: string | undefined = undefined;
    tin: string = "";

    address: string = "";
    email: string = "";
    phone: string = "";
    site: string = "";
    postal_address: string = "";
    bank: BankingInfo = new BankingInfo();
    contract = new CContract();
    constructor(tin:string="", name="") {
        this.tin = tin;
        this.name = name;
    }
}

export class CContract {
    id: string = "";
    number: string = "";
    date: string = "";
    details: string = "";
}

export enum EnumEditOneRecordType {
    none='none', name='name', phone = 'phone', email = 'email', add_company = 'add_company', address = 'address',
    position = 'position', work_phone = 'work_phone', add_person = 'add_person', add_information = 'add_information',

    company_phone = 'company_phone',
    company_site = 'company_site',
    company_email = 'company_email',
    company_address = 'company_address'
}
export class CEditOneRecordData {
    window_header = "";
    placeholder = "";
    value = "";
    inn? = "";
    type : EnumEditOneRecordType = EnumEditOneRecordType.none;
    callback:(value:string|object|undefined, type: EnumEditOneRecordType)=>void ;

    constructor (window_header:string, placeholder:string, value:string, callback:(value:string|object|undefined, type: EnumEditOneRecordType)=>void, type:EnumEditOneRecordType, inn:string = "") {
        this.window_header = window_header;
        this.placeholder = placeholder;
        this.value = value;
        this.callback = callback;
        this.inn = inn;
        this.type = type;
    }
}

export interface userProfileState {
    profile: CUserProfile
    selected_company_id: string | undefined
    selected_user_id: string
    is_visible: boolean
    data_login: {id: string, is_user: boolean} | undefined
    isAuth: boolean
    clientId: string
    phone_entered: string
}

export class BankingInfo {
    account_number = "";
    BIK = "";
    correction_number = "";
    bank = {  BIK:"", name : "", correction_number : "", TIN : "", KPP : "", OGRN : "", adress : ""};
}

export const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End', 'Control', 'Shift'];

export const validateEmail = (email: string): boolean => {
    if (email === '') return false;
    let parts = email.split('@');
    if (parts.length !== 2) return false;
    if (parts[0].length === 0) return false;
    if (parts[1].length === 0 || parts[1].indexOf('.') <= 0 ||
        parts[1].indexOf('.') === parts[1].length - 1) return false;
    return true;
};
