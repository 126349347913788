import {AutoGreyButton} from "../../SelectCarManual/AutoGreyButton/AutoGreyButton";
import React from "react";
import {
    CharacteristicTypeEnum,
    IBOCharacteristics,
    IBodyOptions,
    IFullCategory
} from "../../../../redux/reducers/system/system_types";
import {AutoDropDownGreyList} from "../../SelectCarManual/AutoDropDownGreyList/AutoDropDownGreyList";
import {getBOCharacteristics, getCharacteristicValueList} from "../../../../redux/reducers/system/system_functions";

interface IProps {
    active_characteristics: IBOCharacteristics[],
    onCharacteristicSelect : (ch: IBOCharacteristics, value : boolean|string)=>void;
    categories: IFullCategory[];
    selectedBodyID:string;
}
const BlockSelectCharacteristics:React.FC<IProps> = ({active_characteristics, onCharacteristicSelect, categories, selectedBodyID}) =>{
    let selectedCategoryID = categories.length ? categories[0].id : '';
    let characteristics = getBOCharacteristics(categories,selectedCategoryID,selectedBodyID);
    characteristics.forEach(x=> {
        let ch = active_characteristics.find(a=> a.id === x.id);
        if (!ch)  return;
        x.value = ch.value;
        x.selected = ch.selected;
    });

    return (
        <>
            {characteristics.map(x=> {
                if ( x.type === CharacteristicTypeEnum.Boolean )
                    return  <AutoGreyButton  key={"ch_"+x.id} type="gray" isSelected={x.selected}
                                             onClick={() => {onCharacteristicSelect(x, !x.selected)}}>{x.name}</AutoGreyButton>;
                if (x.type === CharacteristicTypeEnum.Ref)
                    return  <AutoDropDownGreyList  type="gray" list={getCharacteristicValueList(categories, x, selectedBodyID, "")}
                                                   key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                                   value={x.value}
                                                   onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
                if (x.type === "number")
                    return <AutoDropDownGreyList  type="gray" list={ [{id:'', text :'Любая'},{id:'1', text :'до 1 м.'},{id:'2', text :'до 2 м.'},{id:'3', text :'до 3 м.'},{id:'4', text :'до 4 м.'}]}
                                                  value={x.value}
                                                  key={"ch_"+x.id} title={x.name} isSelected={x.selected}
                                                  onSelect={(item) => onCharacteristicSelect(x, item.id)} />;
            })}
        </>
    )
};

export default BlockSelectCharacteristics;
