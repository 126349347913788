import React  from "react";
import "./Overlay.css";

interface IProps {
  z_index?: number;
  zIndex?:number;
  onClick?:Function;
  is_visible?:boolean;
}

const Overlay: React.FunctionComponent<IProps> = ({z_index,zIndex,onClick, is_visible=true}) => {
  return (
    <>
        <div style={{ zIndex: zIndex ? zIndex : z_index ? z_index : 35  }}
             className={  is_visible? "overlay " : 'hidden_overlay'}
             onClick={()=> {  if (onClick ) onClick();}}
        />
    </>
  )
};

export default Overlay;
