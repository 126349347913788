import React, { FC, memo, useState, useCallback, ChangeEvent, useEffect } from 'react'
import  './OrderUserRegistration.css'
import uncheckedUserTerm from '../../../images/uncheckedUserTerm.svg'
import checkedUserTerm from '../../../images/checkedUserTerm.svg'
import AcceptedPhoneNumber from '../../../images/AcceptedPhoneNumber.svg'


import InputMask from "react-input-mask"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {
    createOrderSelector,
    currentOrderData,
    setAgreement,
    setContactInformation,
    setPhoneChecked
} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {SendOrderInfo} from "../../../redux/reducers/slices/CreateOrderType";
import {CUser} from "../../../redux/reducers/slices/userProfile.type";
import {phoneAPI} from "../../../api/server";


interface IProps {
}
export const OrderUserRegistration: FC<IProps> =  ({ }) => {

    const dispatch = useAppDispatch()
    const userProfile  = useAppSelector(userProfileSelector);
    const currentOrder : SendOrderInfo = useAppSelector(currentOrderData);
    const contacts = currentOrder.contacts;
    const { isAuth } = useAppSelector(userProfileSelector);
    const { is_agreement, is_phone_checked } = useAppSelector(createOrderSelector);

    const user : CUser = userProfile.profile.users.length ? userProfile.profile.users[0] : new CUser();

    // const [isPhoneChecked, setPhoneChecked] = useState<boolean>(false);
    const [isPhoneSended, setPhoneSended] = useState<boolean>(false);


    useEffect(()=>{
        if (!isAuth) return;
        let cont = {...contacts};
        cont.full_name = user.full_name;
        cont.phone = user.phone;
        cont.email = user.email;
        if (cont.phone) dispatch(setPhoneChecked(true));
        dispatch(setContactInformation(cont))
    }, [])


    useEffect(()=>{

    }, [isAuth])

    // const [
    //     sendSmsCode,
    //     sendSMSData
    //  ] = useSendSmsCodeMutation(),
    //  [
    //     checkSms,
    //     checkSMSData
    //  ] = useCheckSmsMutation()

    const [showCheckCode, setShowCheckCode] = useState<boolean>(false),
          [checkCode, setCheckCode] = useState<string>('')
    
    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setContactInformation(  {...contacts, email: e.currentTarget.value}))
    }

    const onFIOChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setContactInformation(   {...contacts,full_name : e.currentTarget.value}))
    }

    const onChangePhoneInput =  (e: ChangeEvent<HTMLInputElement>) => {

        dispatch(setContactInformation(  {...contacts,phone : e.currentTarget.value}))
        dispatch(setPhoneChecked(false))
    }

    const onCheckboxClick = () => {
        dispatch(setAgreement(!is_agreement))
    }



    const onPhoneClick = useCallback(() => {
        setShowCheckCode(true)
    }, [])

    const onBlurPhoneInput = useCallback(() => {
        if(!contacts.phone.length) {
            setShowCheckCode(false)
        } else {
            setShowCheckCode(true)
            if(!isPhoneSended)
                phoneAPI.checkPhone(contacts.phone).then( res=>{
                    if (res.status && res.status == 'success') {
                        setPhoneSended(true);
                        dispatch(setPhoneChecked(false));
                    }
                })

        }
    }, [contacts.phone, isPhoneSended])

    const onCheckCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        setCheckCode(value)
    }, [])

    useEffect(() => {
        if (checkCode.length === 4)
            phoneAPI.checkSms( contacts.phone, checkCode).then((res)=>{

                dispatch(setPhoneChecked(res.data?.valid && true));
            })
    }, [checkCode])

    useEffect(() => {
        contacts.phone.length !== 15 && setPhoneSended(false)
    }, [ contacts.phone])



    return (
        <div className={'userRegistration'}>
            <div className={'FIOWrapper '}>
                <input placeholder="Ваши ФИО" value={ contacts.full_name} onChange={onFIOChange} onBlur={onFIOChange} />
            </div>
            <div className='phoneWrapper '>
                <div className={'phone '}>
                    <InputMask mask="+7 999 999 9999" value={ contacts.phone}
                        onChange={onChangePhoneInput} onClick={onPhoneClick} onBlur={onBlurPhoneInput}>
                        {(inputProps: any) => <input {...inputProps} placeholder="Моб. телефон" type="tel" />}
                    </InputMask>
                    {is_phone_checked && <img alt='Номер подтвержден' src={AcceptedPhoneNumber} />}
                </div>
                <div className={showCheckCode ? 'checkCode' : 'hideCode'}>
                    <input placeholder="Код из SMS" value={checkCode} onChange={onCheckCodeChange} maxLength={4} />
                </div>
            </div>
            <div className={'emailWrapper'}>
                <input placeholder="Ваш Email"  value={ contacts.email} onChange={onEmailChange}  onBlur={onEmailChange} />
            </div>
            <div className={'acceptWrapper'}>
                <img alt="" src={is_agreement ? checkedUserTerm : uncheckedUserTerm} onClick={onCheckboxClick} />
                <div >
                    Я принимаю <a href="#">пользовательское соглашение</a> и <a href="#">политику конфиденциальности</a>
                </div>
            </div>
        </div>
    )
}
