import {FC, useCallback, useEffect, useRef, useState} from "react";
import InputMask from "react-input-mask"

type propTypes = {
    text: string
    altText: string
    values: string | {from: string, to: string}
    setValues: (value: any) => void
    name: string
    placeholder: string
    type: string
    onChange?:()=>void
}
export const AdvancedInputElement: FC<propTypes> = ({ text, altText, values, setValues, name, placeholder, type, onChange }) => {

    let inputValue = (typeof values === 'object') ? `с ${values.from} до ${values.to}` : values;
    let refInput = useRef<HTMLInputElement|null>(null);
    const [hideInput, setHideInput] = useState<boolean>(true),
            [focus, setFocus] = useState<boolean>(false),
            [showDateModal, setShowDateModal] = useState<boolean>(false)

    const onInputClick = useCallback(() => {
        setHideInput(false)
        setFocus(false)
        type === 'date' && setShowDateModal(true)
    }, [type])

    const onBlurInput = useCallback(() => {
        (!values || values === "+7 ___ ___ ____") && setHideInput(true)
        setFocus(true)
    }, [values])


    useEffect(() => { if (!hideInput&& refInput && refInput.current) refInput.current.focus() ; console.log('refInput',refInput)}, [hideInput])
    useEffect(() => {
        !(typeof values === 'object') && document.getElementById(name)?.focus()
    }, [hideInput])

    useEffect(() => {
        if (typeof values === 'object') {
            (values.from !== ':00' && values.to !== ':00') && setHideInput(false)
        } else {
            (values === "+7 ___ ___ ____" || values) && setHideInput(false)
        }
    })

    return (
        <>
            <div className='address-info__row' onClick={onInputClick}>
                <div  className={hideInput ? 'unchanged' : 'changed'}>
                    {hideInput ? text : altText}
                </div>
                {focus
                    ? <div className='text'>{(typeof values === 'object') ? `с ${values.from} до ${values.to}` : values}</div>
                    : type === 'phone'
                        ? <InputMask mask="+7 999 999 9999" value={values.toString()}

                                     onChange={(e)=>setValues(e.target.value)} hidden={hideInput} id={name} onBlur={onBlurInput}>
                            {(inputProps: any) => <input {...inputProps} className="input-wrap__input" ref={refInput}
                                                         placeholder="Номер телефона" type="tel" />}
                        </InputMask>
                        : <input ref={refInput} hidden={hideInput} value={(typeof values === 'object') ? `с ${values.from} до ${values.to}` : values } id={name} onChange={(e)=>setValues(e.target.value)} onBlur={onBlurInput} placeholder={placeholder} />}
            </div>

        </>
    )
}
export default AdvancedInputElement;

export enum elementsEnum {
    person = 'person',
    phone = 'phone',
    workTime = 'workTime',
    lunchTime = 'lunchTime',
    addressComment = 'addressComment',
    fromWho = 'fromWho',
    toWhom = 'toWhom',
    whatToDo = 'whatToDo'
}
