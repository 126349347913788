import axios, { AxiosResponse } from "axios";
import {daDataToken} from "../deployment";



const instance = axios.create({
  baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
  headers: {
    Authorization: "Token " + daDataToken,
  },
});

export const daDataAPI = {
  async getAddressByCoords( point :{ lat:number, lon:number }) {

    return instance
      .post(
        "/geolocate/address",
          {"lat": point.lat, "lon": point.lon, "count": 1}
      )
      .then((response) => response)
      .catch((error) => error);
  },

  async getAddressByName(query:string, count = 1) {
    let requestData = {
      count: count,
      // locations: [{region: "Москва"}, {region: "Московская"}, {region: "Россия"}],
      locations_boost: [{ kladr_id: 77 }],
      query: query,
    };
    return instance
      .post(
        "/suggest/address",
        requestData
      )
      .then((response) => response)
      .catch((error) => error);
  },
  async getCompanyByTIN(query:string, count = 1) {

    return instance.get(`/suggest/party?query=${query}&count=${count}`).then((response) => response) ;

  },
};
