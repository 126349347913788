import React, {FC, memo, useCallback, useEffect, useState} from 'react'
import  './OrderSelectDate.css'
import TimeSelectionIcon from '../../../images/TimeSelectionIcon.svg'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import 'moment/locale/ru'
import {DatePickerWindow} from "./DatePickerWindow/DatePickerWindow";

import {currentOrderData, getTariffInfo, setOrderDate} from '../../../redux/reducers/slices/CreateOrderSlicer'
import {useAppSelector} from "../../../redux/hooks";
import {SendOrderInfo} from "../../../redux/reducers/slices/CreateOrderType";
import {systemSelector} from "../../../redux/reducers/system/system_reducers";

const short_months = [
    'Янв',
    'Фев',
    'Мaр',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
];
interface IProps {

}
const OrderSelectDate: FC<IProps> = memo(({  }) => {

    const dispatch = useDispatch()

    const [isShowCalendar, setShowCalendar] = useState<boolean>(false);
    const [urgency, setUrgency] = useState<string>('today');
    const order : SendOrderInfo = useAppSelector(currentOrderData);
    const [dateFromCalendar, setDateFromCalendar] = useState<{fromDate: string,toDate: string, date: Date}>({fromDate: '',toDate: '', date: new Date()});


    const { categories, tariff_types  } = useAppSelector(systemSelector) ;


    useEffect(() => {
        let cnt = order.routes.filter(x=> x.adress != "").length;
        if (cnt > 1)
            getTariffInfo(order, dispatch, categories, tariff_types )
    }, [order.date]);

    const onTodayButtonClick = useCallback(() => {
        setUrgency('today') ;
        dispatch(setOrderDate(moment((new Date()).setHours((new Date()).getHours() + 3) ).format("YYYY-MM-DDTHH:mm:00")))

    }, [])

    const onUrgentButtonClick = useCallback(() => {
        setUrgency('urgent') ;
        dispatch(setOrderDate(moment((new Date())).format("YYYY-MM-DDTHH:mm:00")))

    }, [])

    const getDateString = () => {
        let arr = order.date.split('T');
        let val = '';
        if (arr.length) {
            val = (new Date(arr[0])).getDate() +' ' + short_months[(new Date(arr[0])).getMonth()];
        }
        if (arr.length > 1) {
            val += ' c '+ arr[1].substring(0,5);
            if (arr.length > 2)
                val += ' до ' + arr[2].substring(0,5);
            else
                val += ' до ' + arr[1].substring(0,5);
        }
        return val;

    };


    const onSetDataButtonClick = useCallback(() => {
        setUrgency('active') ;
        setShowCalendar(true)
    }, [])
    console.log('isShowCalendar', isShowCalendar, order.date)
    return (
        <>
            <div className={ 'newOrderHeaderWrapper  new_order_header'}>
                <img alt='Выберите срочность доставки' src={TimeSelectionIcon} />
                {(dateFromCalendar.fromDate || dateFromCalendar.toDate) && <button onClick={onSetDataButtonClick} className={`${urgency !== 'today' && urgency !== 'urgent' &&  'activeDate'}`}>{`${moment(dateFromCalendar.date).locale("ru").format("DD MMM")} c ${dateFromCalendar.fromDate} до ${dateFromCalendar.toDate}`}</button>}
                <button onClick={onTodayButtonClick} className={`${ urgency === 'today' && 'active'}`}>Сегодня</button>
                <button onClick={onUrgentButtonClick} className={`${ urgency === 'urgent' && 'active'}`}>Срочно</button>
                {!(dateFromCalendar.fromDate || dateFromCalendar.toDate) &&
                <button onClick={onSetDataButtonClick} className={` ${urgency !== 'today' && urgency !== 'urgent' && 'active'}  nowrap`}>
                    {order.date ? getDateString() : 'Настроить дату' }
                </button>}
            </div>
            {isShowCalendar &&
                <DatePickerWindow
                    currentValue={order.date ? order.date : moment(new Date()).format("YYYY-MM-DDTHH:mm:00")}
                    onSave={(value)=>{ dispatch(setOrderDate(value)); setShowCalendar(false); } }
                    onCancel={()=>setShowCalendar(false)}
                />}
       </>
    )
});

export default OrderSelectDate;
