import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import "./HelpForm.css";
import  { Puff } from "react-loader-spinner";
import Overlay from "../../Elements/Overlay/Overlay";
import {serverAPI} from "../../../api/server";
import {ArrowRightIcon} from "../../Elements/SvgIcons/ArrowRightIcon";

interface IProps {
    zIndex?:number;
}
const HelpForm: React.FC<IProps> = ({  zIndex}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [articleList, setArticlesList] = useState<TArticle[]>([]);
    const [selectedItem, setSelectedItem] = useState<TArticle|undefined>(undefined);
    const [selectedFolder, setSelectedFolder] = useState<TArticle|undefined>(undefined);

    const dispatch = useDispatch();

    const openArticle = (id: string) => {
        let item = articleList.find((item) => item.id === id);
        if (!item) return setSelectedItem(undefined);
        if (item.is_folder) {
            return setSelectedFolder(item)
        }

        setLoading(true);
        serverAPI.getArticles(id).then((res) => {
            console.log('getArticles()', res)
            setLoading(false);
            if (res.data && Array.isArray(res.data) && item?.is_folder) {
                let data = res.data.sort((a,b)=>  a.is_folder && !b.is_folder ? -1 : 1 )
                setArticlesList(data)
                setSelectedItem(item)
            }
            if (res.data && Array.isArray(res.data) && !item?.is_folder) {
                setSelectedItem(res.data[0])
            }
        });

    }

    const closeArticle = () => {
        setSelectedItem(undefined)
    }

    const getArticles = () => {
        setLoading(true);

        serverAPI.getArticles().then((res) => {
            console.log('getArticles()', res)
            setLoading(false);
            if (res.data && Array.isArray(res.data)) {
                let data = res.data.sort((a,b)=>  a.is_folder && !b.is_folder ? -1 : 1 )
                setArticlesList(data)
            }
        });
    }

    useEffect(() => {
        getArticles();
    }, []);

    const toMainScreen = () => {
        if (selectedFolder) {
            setSelectedFolder(undefined);
            return;
        }
    }

    return (
        <div className="screen_help">
            <div className="help-window-title">Помощь {selectedFolder && <div className="help-window-back" onClick={toMainScreen}>← назад</div>}</div>
            <div className="screen_help_list">
                {articleList.filter(x=> (!selectedItem && !selectedFolder) ? x.is_folder : x.parent == (selectedFolder ? selectedFolder.id :''))
                    .map((item) => {
                        return (
                            <div key={item.id} className="screen_help_article" onClick={() => openArticle(item.id)}>
                                <div className="screen_help_article_title">{item.title}</div>
                                <div className="screen_help_article_description" dangerouslySetInnerHTML={{__html:item.description.replace('\n','<br/><br/>')}}/>
                                <div className="screen_help_article_footer"><span className="screen_help_article_btn">
                {item.is_folder ? 'Перейти в категорию' : 'Подробнее'}</span>&nbsp;&nbsp;<ArrowRightIcon/></div>
                            </div>
                        );
                    })}
            </div>
            {selectedItem && !selectedItem.is_folder &&
                <>
                    <div className="article_window">
                        <div className="window_close_btn" onClick={closeArticle}><ReactSVG src={CloseSVG} /></div>
                        <div className="article_window_header">{selectedItem.title}</div>
                        <div className="article_window_content" dangerouslySetInnerHTML={{__html : selectedItem && selectedItem.article? selectedItem.article : ''}}/>
                    </div>
                    <Overlay/>
                </>
            }

            {isLoading  &&
                <div>
                    <div className="loader">
                        <Puff  color="#00BB40" width={'8rem'} height={'8rem'}/>
                        <div className="loader-text">Идет загрузка данных</div>
                    </div>
                    <Overlay is_visible={true} />
                </div>
            }
        </div>
    );
}

export default HelpForm ;

export type TArticle = {
    id: string;
    title: string;
    description: string;
    is_folder: boolean
    parent: string;
    article? : string
    files?:{name:string,data:string}[]

}
