import {IIconProps} from "./SvgIconsType";


export const AddIcon = ({ onClick,className }: IIconProps) => {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => onClick && onClick()}
      className={className}
    >
      <line
        x1='3'
        y1='10'
        x2='17'
        y2='10'
        stroke='#888E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 17V3'
        stroke='#888E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
