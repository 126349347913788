import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import optionToggler from "../../../App/svg/option.svg";
import "./AutoDropDownGreyList.css";
import { ReactSVG } from "react-svg";
import {DropDownListItem} from "../../../../redux/reducers/system/system_types";





interface AutoDropDownGreyListProps {
  type: "gray" | "blue" | "transparent" | "transparent-green";
  size?: "normal" | "lg" | "xl";
  align?: "center" | "left" | "right";
  onSelect: ({ id, text }: DropDownListItem) => void;
  onClick?:Function;
  list: DropDownListItem[];
  selectedId?: number;
  title: string;
  isSelected: boolean;
  showTitleWhenSelect?: boolean;
  value?:string;
  isFixed?:boolean;
}
export const AutoDropDownGreyList: React.FC<AutoDropDownGreyListProps> = ({
  type,
  size = "normal",
  align = "left",
  onSelect,
  onClick,
  list,
  selectedId = 0,
  title= '',
  isSelected = false,
  showTitleWhenSelect = true,
  value = '',
  isFixed=false,

}) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropDownListItem|undefined>(value ? list.find(x=> x.id === value) : undefined);
  const clickDropDown = () => {

    if (list.length)
      setOpenDropDown((prevState) => !prevState);
    if (onClick && !openDropDown) onClick();
  };
  const clickDropDownItem = (item: DropDownListItem) => {
    setSelectedItem(item);
    onSelect(item);
  };

  let refHeader = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    if (!isSelected)
      setOpenDropDown(false);
  }, [isSelected]);

  const getTop = () => {
    let top = refHeader && refHeader.current ? refHeader.current.getClientRects()[0].y : 0;
      top += refHeader && refHeader.current ? refHeader.current.clientHeight : 0;
    return (top ? top -  5 : 0) + 'px';
  }

  const getLeft = () => {
    let left =  refHeader && refHeader.current ? refHeader.current.getClientRects()[0].x : 0;
    return left + 'px';
  }
  const getWidth = () => {
    let w =  refHeader && refHeader.current ? refHeader.current.clientWidth : 0;
    return w + 'px';
  }
  if (refHeader && refHeader.current) {
    //@ts-ignore
    window.ref = refHeader.current;
    console.log('refHeader', refHeader.current.clientTop, refHeader.current.offsetHeight, refHeader.current.scrollTop)
  }
  return (
      <>

    <div
      className={cn(
        "drop-down",
        `drop-down--${type}`,
        `drop-down--${size}`,
        `drop-down--${align}`,
        {
          "drop-down--show": openDropDown,
          "drop-down-selected": isSelected
        },

      )}
      onClick={() => clickDropDown()}
      ref={refHeader}
    >
      <div className="drop-down__header" >
        { title  && selectedItem && selectedItem.id ? (showTitleWhenSelect ? title: '') +  (selectedItem.id ?  (showTitleWhenSelect ? ': ':'') + selectedItem.text : '') : title}
        { !title && selectedItem ? selectedItem.text : '' }
        { list.length > 0 && <ReactSVG src={optionToggler} className="drop-down__toggler" />}
      </div>
      {openDropDown && (
        <div className={"drop-down__container " + (isFixed ? 'fixed' : '')} style={ isFixed ? {top:getTop(), left:getLeft(), width:getWidth()} : {}}  >
          <ul className="drop-down__list">
            {list.map((item) => (
              <li
                key={item.id}
                onClick={() => clickDropDownItem(item)}
                className="drop-down__item"
              >
                {item.text}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
        </>
  );
};
