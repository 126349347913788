import { Address } from "./Address/Address";
import './Address/Address.css'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  createOrderSelector,
  currentOrderData,
  setOrderRoutes,
  setSelectedAddress
} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {useAppSelector} from "../../../redux/hooks";
import {RouteAddress} from "../../../redux/reducers/slices/CreateOrderType";
import GrayIcon from "../../../images/gray-round.svg";
import {ReactSVG} from "react-svg";




interface IProps {

}
const AddressPoints: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();

  const order = useAppSelector(currentOrderData);
  const {selected_address_index} = useAppSelector(createOrderSelector);
  useEffect(()=>{
    console.log('order.routes', order.routes)
  }, [order.routes])

  const deletePoint = (index:number) => {
    let routes = [...order.routes];
    if (index < 2) {
      routes[index] = new RouteAddress(index)
    } else
      routes = order.routes.filter((v,i)=> i!=index);
    dispatch(setOrderRoutes(routes))
  }
  const setPointData =(value: RouteAddress, index:number) => {
    let routes = [...order.routes];
    if (index< routes.length)
      routes[index] = value;
    else
      routes.push(value);
    dispatch(setOrderRoutes(routes))
  }

  const onAddNewPoint = async () => {
    let index = order.routes.length;
    await dispatch(setOrderRoutes([...order.routes, new RouteAddress(index)]));
    dispatch(setSelectedAddress(index))
  };
  const onSelectPoint = (value:boolean, index:number) => {
    console.log('onSelectPoint', value, index);
    dispatch(setSelectedAddress(value ? index : -1));

    if ((!value && index > 1 && order.routes.length > index && !order.routes[index].adress) ||
        (value && selected_address_index > 1 && !order.routes[selected_address_index].adress && index!=selected_address_index)) {
      dispatch(setOrderRoutes([...order.routes.filter((x,i)=>i!=selected_address_index)]));
    }
  };

  return (
    <div className="create-order__points adress-points">
      {/*{order.routes.length == 0 &&*/}
      {/*    <>*/}
      {/*      <Address type={"from" } value={null}*/}
      {/*               isSelected={false}*/}
      {/*               onFocus={(value)=>{onSelectPoint(value, 0) }}*/}
      {/*               onExpand={(value)=>{onSelectPoint(value, 0) }}*/}
      {/*               onDelete={()=>deletePoint(0)}*/}
      {/*               onChange ={(value)=>setPointData(value, 0)}*/}

      {/*      />*/}
      {/*    </>}*/}

      {order.routes.map((x,index)=>
            <Address type={index == 0 ? "from" : "to"} value={x}
                     isSelected={index == selected_address_index}
                     onFocus={(value)=>{onSelectPoint(value, index) }}
                     onExpand={(value)=>{onSelectPoint(value, index) }}
                     onDelete={()=>deletePoint(index)}
                     onChange ={(value)=>setPointData(value, index)}

      /> )}
      {order.routes.length > 1 && !order.routes.some(x=> !x.adress) &&

        <div className="empty-address-point" onClick={onAddNewPoint}>
          <div className="address-item__icon"><ReactSVG src={GrayIcon} /></div>
          <div>Пункт {order.routes.length + 1}</div>
        </div>
      }
      {/*<Address type="to" value={orderRoutePoint}  onFocus={()=> setIndex(1)} />*/}
    </div>
  );
};

export default AddressPoints;
