import { Tariff } from "./Tariff/Tariff";
import "./SelectTariff.css";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {createOrderSelector, currentOrderData, setOrderData, setOrderTariff} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {useEffect, useState} from "react";

const SelectTariff: React.FC = () => {
    const dispatch = useAppDispatch();
  // const data = {
  //   tariff_name: "Часовая аренда",
  //   tariff_type_id: "aa6d8cc1-e0f1-11ea-8dfb-000c298a28ba",
  //   url: "http://yandex.ru",
  //   hours: 2.32,
  //   min_hours: 5,
  //   rate: "02:19 (4+1)",
  //   min_cost: 4920,
  //   cost_by_hour: 584,
  //   cost: 5728.92,
  //   items: [{ cost: 2000, name: "Водитель - грузчик" }],
  //   items_by_route: [{ cost: 808.92, name: "Стоимость километра (МКАД)" }],
  // };
    const {tariffs, is_tariff_loading} = useAppSelector(createOrderSelector);
    const order = useAppSelector(currentOrderData);
    const [isSetAM, setAM] = useState( tariffs.length && tariffs.some(x=> x.tariff_type_id === order.tariff_type_id && x.tariff_name.indexOf('PM') < 0));
    useEffect(()=>{
        setAM(tariffs.length && tariffs.some(x=> x.tariff_type_id === order.tariff_type_id && x.tariff_name.indexOf('РМ') < 0));
    }, [tariffs]);
    return (
        <>
            <div className="select-tariff__header">
              <div className={"select-tariff__header-item " + (isSetAM ? 'selected':'')} onClick={()=>setAM(true)}>Обычная цена (AM)</div>
              <div className={"select-tariff__header-item " + (!isSetAM ? 'selected':'')} onClick={()=>setAM(false)}>с 13:00 до 17:00 (PM)</div>
            </div>
            <div className="select-tariff">
                {tariffs.length > 0 &&
                    tariffs.filter(x=> (isSetAM && x.tariff_name.indexOf('РМ') < 0) || (!isSetAM && x.tariff_name.indexOf('РМ') > 0))
                    .map((x, index) =>
                    <Tariff
                        loading={is_tariff_loading}
                        data={x}
                        onSelect={(tariff_type_id) => {
                           dispatch(setOrderTariff(x))
                        }}
                        key={x.tariff_type_id + index}
                        selected={x.tariff_type_id == order.tariff_type_id}
                    />
                 )

                }


            </div>
        </>
    );
};

export default SelectTariff;
