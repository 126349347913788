import cn from "classnames";
import "./AutoGreyButton.css";

interface AutoGreyButtonProps {
  type: "gray" | "blue" | "transparent" | "green";
  size?: "normal" | "xl" | "large";
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?:boolean;
}

export const AutoGreyButton: React.FC<AutoGreyButtonProps> = ({
  type,
  size = "normal",
  onClick,
  isSelected= false,
  isDisabled = false,
  children,

}) => {

  const onClickBtn = () => {
    onClick();
  };
  return (
    <button
      disabled={isDisabled}
      onClick={onClickBtn}
      className={cn("auto-btn", `auto-btn--${type}`, `auto-btn--${size}`, {
        "btn-selected": isSelected,
      })}
    >
      {children}
    </button>
  );
};
