import './UserProfile.css'

import {ThreeDots} from "react-loader-spinner";
import React from "react";
import { ArrowRightIcon } from '../../Elements/SvgIcons/ArrowRightIcon';
import { AddIcon } from '../../Elements/SvgIcons/AddIcon';

interface IProps {
    name?:string;
    value?:string;
    is_arrow:boolean|undefined;
    onClick: ()=>void;
    append_class?:string
    is_loading?:boolean
}
const ProfileMenuItem : React.FC<IProps> = ({name, value,is_arrow,onClick, append_class, is_loading}) =>{

    return (
        <div className={"profile_menu_item " + (append_class ? append_class : '')} onClick={()=>{console.log('click'); onClick()}}>
            <div>
                {name && <div className="top-title">{name}</div> }
                {value && !is_loading && <div className="value" >{value}</div>}
                {is_loading && <div className="value loading" >Обновление данных <ThreeDots  color={ "#333" } width={20} height={20} /></div>}
            </div>
            <div>
                {is_arrow!=undefined && <div>{is_arrow ? <ArrowRightIcon /> : <AddIcon />} </div>}
            </div>
        </div>
    )
}





export default ProfileMenuItem;
