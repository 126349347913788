import React, { useEffect } from 'react'
import { useState } from 'react'
import { PartySuggestions } from 'react-dadata'
import { DaDataSuggestion, DaDataParty } from 'react-dadata'
import 'react-dadata/dist/react-dadata.css'
import './Inn.css'
import './react-suggestion.css'
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import { ClearIcon } from '../../icons/ClearIcon'
import {daDataToken} from "../../../../deployment";
 


 interface IProps {
  onChange: (inn: string, companyName: string) => void
}

export const Inn = ({ onChange }: IProps) => {
  const [value, setValue] = useState<DaDataSuggestion<DaDataParty> | undefined>(
    undefined
  ) 
  const [ currentValue, setCurrentValue ] = useState<{inn:string, companyName: string}>({inn:'', companyName: ''});


  const dispatch = useAppDispatch()
  let ref = React.createRef<PartySuggestions>()

  useEffect(() => {
    ref.current?.setInputValue(currentValue.inn)
  }, [ ref])

  const onChangeInn = (selected: DaDataSuggestion<DaDataParty> | undefined) => {
    const inn = selected?.data.inn;
    const companyName = selected?.data.name.short_with_opf;
    onChange(inn ? inn :'', companyName ?  companyName : '');
    setValue(selected)
    setCurrentValue({inn:inn ? inn:'', companyName: companyName ? companyName : '' })
    ref.current?.setInputValue(inn)
  }

  const clear = () => {
    onChangeInn(undefined)
  }

  const render = (item: DaDataSuggestion<DaDataParty> | undefined) => {
    return (
      <div className="inn-list" key={item?.data.ogrn}>
        <span style={{minWidth:'130px'}}>{item?.data.inn}</span>
        <span>{item?.data.name.short_with_opf}</span>
      </div>
    )
  }

  return (
    <div className="inn-container">
      <PartySuggestions
        ref={ref}
        token={daDataToken}
        value={value}
        defaultQuery={currentValue.inn}
        onChange={onChangeInn}
        count={5}
        inputProps={{ placeholder: 'ИНН компании' }}

        renderOption={render}
      />
      <span className='name' onClick={()=>ref && ref.current && ref.current.focus()}>{currentValue.companyName}</span>
      <ClearIcon onClick={() => clear()} />
    </div>
  )
}




