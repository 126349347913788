import React, { FC, useState } from 'react'
import {useCookies} from "react-cookie";
import   './OrderCreateButton.css'
import {ThreeDots} from "react-loader-spinner";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {EnumPaymentType, SendOrderInfo} from "../../../redux/reducers/slices/CreateOrderType";
import {
    createOrderSelector,
    currentOrderData,
    getTariffLoading
} from "../../../redux/reducers/slices/CreateOrderSlicer";
import {setClientId, setIsAuth, setUserProfile, userProfileSelector} from "../../../redux/reducers/slices/userProfileSlice";
import {CCompany, CUser, CUserProfile} from "../../../redux/reducers/slices/userProfile.type";
import {showErrorMessage, showInfoMessage} from "../../../redux/reducers/system/system_functions";
import {serverAPI} from "../../../api/server";
import {cookiesClientId, cookiesOrderId} from "../../../deployment";




interface IProps {
    onCreateOrder:()=>void
}
const OrderCreateButton: FC<IProps> =  ({ onCreateOrder}) => {



    const dispatch = useAppDispatch();
    const currentOrder : SendOrderInfo= useAppSelector(currentOrderData);
    const isTariffLoading= useAppSelector(getTariffLoading);
    const   [errorVisible, setErrorVisible] = useState<boolean>(false);
    const   [isLoading, setLoading] = useState<boolean>(false);
    const { isAuth, clientId } = useAppSelector(userProfileSelector)
    const orderReducer  = useAppSelector(createOrderSelector);
    const [cookies, setCookie] = useCookies([cookiesOrderId, cookiesClientId]);


    const OnOrderClick = async () => {

        if (isTariffLoading || isLoading  ) {
            return;
        }
        if (!getOrderStatus().value) {
            setErrorVisible(true);
            return;
        }
        setLoading(true);
        let data : SendOrderInfo = {...currentOrder};
        data.draft = false;
        if (isAuth && clientId)
            data.client_id = clientId;
        else {
            let profile = new CUserProfile();
            profile.users.push({...new CUser(currentOrder.contacts.full_name, currentOrder.contacts.phone, currentOrder.contacts.email )});
            profile.companies.push({...new CCompany(currentOrder.TIN, currentOrder.company_name)});

            let res :any =  await serverAPI.postProfileInfo({...profile});
            if (!res)  { setLoading(true); return showErrorMessage('Ошибка регистрации аккаунта ', dispatch);}
            if (res.status === 'error')  { setLoading(true); return showErrorMessage(res.error_message, dispatch);}
            dispatch(setUserProfile(res));

            // let res =  await postAuth(profile).unwrap();
            // dispatch(setUserProfile(res));
            const { users  } = res;
            let clientId = users.length ? users[0].client_id : '';
            dispatch(setClientId(clientId));
            // dispatch(setFullName(currentOrder.contacts.full_name));

            setCookie(cookiesClientId, clientId, { path: '/' });

            dispatch( clientId ? setIsAuth(true) : setIsAuth(false))
            data.client_id = clientId ? clientId : data.client_id;
        }

        serverAPI.postOrder(data)
        .then((res : any) => {

            if (!res || res.status === 'error' || res.error) {
                showErrorMessage(res.errormessage? res.errormessage : 'Ошибка создания заказа ', dispatch)
            } else {
                setCookie(cookiesOrderId, '', { path: '/' });
                onCreateOrder();
                showInfoMessage( 'Заказ успешно создан вы можете найти его в разделе «Заказы» ! ', dispatch)
            }
            setLoading(false);
        });

    };

    const getOrderStatus = () : {value:boolean, error_message: string} => {
        if (currentOrder.date == '') return {value:false, error_message:"Для продолжения необходимо выбрать дату и время заказа"};
        if (currentOrder.car_type_id == '') return {value:false, error_message:"Для продолжения необходимо выбрать категорию ТС"};
        if (currentOrder.body_option_id == '') return {value:false, error_message:"Для продолжения необходимо выбрать тип кузова"};
        if (currentOrder.payment_type == EnumPaymentType.PaymentOnAccount && currentOrder.TIN == "") return {value:false, error_message:"Для продолжения необходимо заполнить ИНН компании"};
        if (currentOrder.payment_type == EnumPaymentType.PaymentOnline) return {value:false, error_message:"Данный вид оплаты временно недоступен"};
        if (!orderReducer.is_agreement) return {value:false, error_message:"Для продолжения необходимо принять пользовательское соглашение и политику конфиденциальности"};
        if (currentOrder.contacts.full_name == "" ) return {value:false, error_message:"Для продолжения необходимо указать ФИО"};
        if (currentOrder.contacts.phone == "" || !orderReducer.is_phone_checked) return {value:false, error_message:"Для продолжения необходимо подтвердить номер телефона"};

        return {value:true, error_message:''};
    }


    return (
        <div className={'createOrder'}>
            {!getOrderStatus().value && errorVisible && <div className={'errorMessage'}>{getOrderStatus().error_message}</div>}
            <button onClick={()=>OnOrderClick()} className={!getOrderStatus().value || isTariffLoading || isLoading? 'disabledOrderButton' : ''}>
                {isLoading ? <div className={'creatingOrder'}>
                                 <div style={{marginRight:10}}>Оформление заказа</div><ThreeDots  color={  "#999" } width={40} height={40}  />
                            </div>
                    : 'Заказать'}
            </button>
        </div>
    )
}

export default OrderCreateButton;
