import './MenuForm.css'
import React, {FC, useEffect, useState} from "react";
import RegistrationForm, {IRegistrationDataObj} from "../RegistrationForm/RegistrationForm";
import MenuLogoImg from "../../App/img/menu-logo.jpg"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
    clearUserProfile, setClientId, setIsAuth, setSelectedCompanyId, setSelectedUserId,
    userProfileSelector
} from "../../../redux/reducers/slices/userProfileSlice";
import {ArrowRightIcon} from "../../Elements/SvgIcons/ArrowRightIcon";
import { CloseIcon } from '../../Elements/CloseIcon/CloseIcon';
import LoginForm from '../LoginForm/LoginForm';
import {cookiesAccountId, cookiesClientId, cookiesOrderId} from "../../../deployment";
import {useCookies} from "react-cookie";
import {setSystemMessage} from "../../../redux/reducers/system/system_reducers";
import {MessageType} from "../../../redux/reducers/system/system_types";
import {BalanceForm} from "../BalanceForm/BalanceForm";
import HelpForm from "../HelpForm/HelpForm";
import UserProfile from "../UserProfileForm/UserProfile";
import {JournalOrdersForm} from "../JournalOrdersForm/JournalOrdersForm";

interface IProps {
    showRegistrationForm?:boolean
    onClose:()=>void;
}

export const MenuForm:FC<IProps>=({onClose, showRegistrationForm=false})=>{
    const dispatch= useAppDispatch();
    const [isRegistrationForm, setRegistrationForm] = useState<boolean>(false);
    const [isLoginForm, setLoginForm] = useState<boolean>(false);
    const [isBalanceForm, setBalanceForm] = useState<boolean>(false);
    const [isHelpForm, setHelpForm] = useState<boolean>(false);
    const [isJournalForm, setJournalForm] = useState<boolean>(false);
    const [isProfileForm, setProfileForm] = useState<boolean>(false);

    const { isAuth , profile, phone_entered} = useAppSelector(userProfileSelector);
    const [cookies, setCookie] = useCookies([cookiesClientId, cookiesAccountId, cookiesOrderId]);

    const selectedUserIndex = 0;
    useEffect(()=>{
        setRegistrationForm(showRegistrationForm);
    },[showRegistrationForm]);


    const  logout = async () => {
        dispatch(setSystemMessage({msg_type: MessageType.Info, message: "Дождитесь завершения выхода из приложения"}));
        dispatch(clearUserProfile());
        dispatch(setSelectedCompanyId(''));
        dispatch(setSelectedUserId(''));
        dispatch(setIsAuth(true));
        dispatch(setClientId(''));
        setCookie(cookiesClientId, '', { path: '/' });
        setCookie(cookiesOrderId, '', { path: '/' });
        setCookie(cookiesAccountId, '', { path: '/' });
        window.location.href = "/";
    };
    const getProfileName = () => {
        if (!cookies.selectedAccountId) {
            if (phone_entered && profile.users.some(x=>x.phone == phone_entered))
                return profile.users.find(x=>x.phone === phone_entered)?.full_name;
            if (phone_entered && profile.companies.some(x=>x.phone == phone_entered))
                return profile.companies.find(x=>x.phone === phone_entered)?.name;
            return "Ошибка идентификации аккаунта";
        }
        if ( profile.users.some(x=>x.id == cookies.selectedAccountId))
            return profile.users.find(x=>x.id === cookies.selectedAccountId)?.full_name;
        if (phone_entered && profile.companies.some(x=>x.id == cookies.selectedAccountId))
            return profile.companies.find(x=>x.id === cookies.selectedAccountId)?.name;
        return "Ошибка идентификации аккаунта";
    };

    if (isRegistrationForm) return (
        <div className="menu-form" >
            <CloseIcon  onClick={onClose} className="menu-form-close"/>
            <RegistrationForm timeWait={60}  onRegistrationComplete={()=>setRegistrationForm(false)} onClose={()=>setRegistrationForm(false)} />
        </div>
    );

    if (isLoginForm) return (
        <div className="menu-form" >
            <CloseIcon  onClick={onClose} className="menu-form-close"/>
            <LoginForm timeWait={60}  onLoginComplete={()=>setLoginForm(false)} onClose={()=>setLoginForm(false)} />
        </div>
    );

    if (isBalanceForm) return (
        <div className="menu-form" >
            <CloseIcon  onClick={()=>setBalanceForm(false)} className="menu-form-close"/>
            <BalanceForm  />
        </div>
    );
    if (isHelpForm) return (
        <div className="menu-form" >
            <CloseIcon  onClick={()=>setHelpForm(false)} className="menu-form-close"/>
            <HelpForm  />
        </div>
    );

    if (isJournalForm) return (
        <div className="menu-form" >
            <CloseIcon  onClick={()=>setJournalForm(false)} className="menu-form-close"/>
            <JournalOrdersForm  />
        </div>
    );
    if (isProfileForm) return (
        <div className="menu-form" >

            <UserProfile onClose={()=>setProfileForm(false)} />
        </div>
    );

    return (
        <div className="menu-form" >
            <div className="menu-form-item menu-form-logo" onClick={onClose}><img src={MenuLogoImg}/></div>
            <CloseIcon  onClick={onClose} className="menu-form-close"/>
            {isAuth &&
                <>
                    <div className="menu-form-item menu-form-item__border_bottom"  onClick={()=>setProfileForm(true)}>

                            <div className="menu-form-user-info">
                                <div>{getProfileName()}</div>
                                <div className="menu-form-user-info__subtitle">Ваш профиль</div>
                            </div>

                    </div>

                    <div className="menu-form-item menu-form-item__border_bottom"  onClick={()=>setJournalForm(true)}>
                        <div className="menu-form-user-info menu-form-item-info">Журнал заказов <ArrowRightIcon className="menu-form-arrow-right"/></div>
                    </div>

                    <div className="menu-form-item menu-form-item__border_bottom"  onClick={()=>setBalanceForm(true)}>
                        <div className="menu-form-user-info menu-form-item-info">Баланс <ArrowRightIcon className="menu-form-arrow-right"/></div>
                    </div>

                    <div className="menu-form-item menu-form-item__border_bottom"  onClick={()=>setHelpForm(true)}>
                        <div className="menu-form-user-info menu-form-item-info">Помощь <ArrowRightIcon className="menu-form-arrow-right"/></div>
                    </div>

                    <div className="menu-form-item" onClick={logout}>
                        <div className="menu-form-user-info menu-form-item-info">Выход  </div>
                    </div>
                </>
            }
            {!isAuth &&
                <>
                    <div className="menu-form-item-button__container" onClick={()=>setRegistrationForm(true)}>
                        <div className="menu-form-user-info menu-form-item-button">Регистрация</div>
                    </div>
                    <div className="menu-form-item-button__container">
                        <div className="menu-form-user-info menu-form-item-button" onClick={()=>setLoginForm(true)}>Вход</div>
                    </div>
                </>
            }
        </div>
    )
}
