import React, { useEffect, useState } from "react";

import {phoneAPI, serverAPI} from "../../../api/server";
import cn from "classnames";
import  { Puff } from "react-loader-spinner";
import "./LoginForm.css";
import { ValidateInput } from "../../Elements/UserInputs/ValidateInput/ValidateInput";
import {useAppDispatch} from "../../../redux/hooks";
import {useCookies} from "react-cookie";
import {cookiesAccountId, cookiesClientId, cookiesClientPhone} from "../../../deployment";
import {showErrorMessage, showInfoMessage} from "../../../redux/reducers/system/system_functions";
import {
  loadProfileByIdThunk,
  setClientId,
  setEnteredPhone,
  setIsAuth,
  setUserProfile
} from "../../../redux/reducers/slices/userProfileSlice";

interface RegistrationWindowProps {
  onLoginComplete: () => void;
  onClose: () => void;
  timeWait: number;
}

var timerInterval : any = undefined;

const LoginForm: React.FC<RegistrationWindowProps> = ({   onLoginComplete,  onClose,  timeWait, }) => {

  const dispatch = useAppDispatch();
  const [cookies, setCookie] = useCookies([cookiesClientId, cookiesAccountId, cookiesClientPhone]);
  const [isCodeValid, setCodeValid] =  useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [enterCode, setEnterCode] = useState(false);
  const [time, setTime] = useState(timeWait);

  useEffect(()=>{
    let isMounted = true;
    if (timerInterval) {
      clearInterval(timerInterval);
      timerInterval = undefined;
    }
    if (!enterCode || isCodeValid) return;

    timerInterval = timerInterval ? timerInterval : setInterval(() => {
        if (!isMounted) {
          clearInterval(timerInterval);
          timerInterval = undefined;
          return;
        }
        if (time>0) setTime(time - 1);
      }, 1000 );

    return () => { isMounted = false };

  },[enterCode])

  const onCodeEntered = async (code: string) => {
    if (code.length !== 4) return;
    setLoading(true);
    const response = await serverAPI.authByPhone({phone_number: phone, sms_code: code});
    setLoading(false);
    if (!response || !response.status) return showErrorMessage('Ошибка авторизации, повторите попытку позденее.', dispatch);
    if (response.status === "error") return showErrorMessage('Ошибка авторизации, ' + response.error_message, dispatch);
    if (!response.client_id) return showErrorMessage('Ошибка авторизации, невозможно идентифицировать пользователя', dispatch);
    setLoading(true);

    let selected_account =  await loadProfileByIdThunk(response.client_id, dispatch, cookies.selectedAccountId, phone);
    setCookie(cookiesAccountId,selected_account ? selected_account : '', { path: '/' });

    dispatch(setIsAuth(true));
    dispatch(setClientId(response.client_id));
    dispatch(setEnteredPhone(phone));
    setCookie(cookiesClientId, response.client_id, { path: '/' });
    setCookie(cookiesClientPhone, phone, { path: '/' });
    setLoading(false);
    onLoginComplete();
  }

  return (
    <div className="login-window register-window">
      <h2 className="register-window__title">Вход в приложение</h2>
      <div className="register-window__form registration-form" >

        <div
          className={cn( "registration-form__item",  { "registration-form__item--show-code": enterCode },
                             { "registration-form__item--code-error":  enterCode && !isCodeValid})}
        >
          <ValidateInput
            inputClass="registration-form__input registration-form__input--phone"
            value=""
            type="phone"
            errorMessage="Телефонный номер введен некоректно"
            placeholder="Ваш телефон (+7 900 123 4567)"
            inputTabIndex={2}
            onError={(error) => {  console.log("error: ", error); }}
            onChangeInput={(inputValue) => {
              setEnterCode(true);
              phoneAPI.sendSms(inputValue as string);
              setPhone(inputValue as string);
            }}
            isValid={isCodeValid}
          />
          {enterCode &&
            <ValidateInput
                inputClass={"registration-form__input registration-form__input--code "}
                value=""
                type="code"
                errorMessage="Неверный код"
                placeholder="Код из SMS"
                inputTabIndex={3}
                onError={(error) => { console.log("error: ", error);}}
                onChangeInput={ (code) => onCodeEntered(code as string)}
                isValid={isCodeValid}
            />
          }
          {enterCode &&
            <div className="registration-form__code-note">
              {time > 0 && `Отправить новый код можно через ${time} сек...`}
              {time === 0 && (
                <span
                  onClick={() => {
                    phoneAPI.sendSms(phone);
                    setTime(timeWait);
                  }}
                >
                  Отправить новый код
                </span>
              )}
            </div>
          }
        </div>


        <div className="registration-form__button--wrapper">
          <button
            className={cn("registration-form__button", {  "registration-form__button--active": isCodeValid || isLoading,})}
            type="submit"
            onClick={()=>{   }}
          >
            {isLoading ?
                  <div className="registration-form__button--content">
                    <Puff color="#fff" width={"24px"} height={"24px"} />{" "}
                    Обработка данных
                  </div>
                :
                  "Авторизоваться"
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
